import firebase from "../DbConn"
//
//import  geofire from 'geofire';
import * as geofire from 'geofire';
import Swal from 'sweetalert2'
import swal from 'sweetalert'; 
 import $ from "jquery"; 
// const google = window.google; 
 
// const oneDriver =window.oneDriver={};      // = {};

// const alldrivers = window.alldrivers=[]; // [];



// //  oneDriver = {};

// //  alldrivers = [];


var geoQuery;
var oneDriver = {};

var alldrivers = [];




const MakeRequest = async  () => {


    document.getElementById("hidediv").style.display = 'inline';
  // vehicleinfowithdrivernew() ;
 
// /*
   // const profile_pics = document.querySelector("#profile_pics").files[0];

   var name = document.getElementById("BookingCost").value;
   //var password = document.getElementById("driver_list_id").value;
   var cusmoter = document.getElementById('userID').value;
   var email = document.getElementById("lat").value;
   var website = document.getElementById("long2").value;

   var email2 = document.getElementById("txtSource").value;
   var website2 = document.getElementById("txtDestination").value;
   //Check input Fields Should not be blanks.
   if (cusmoter == '' || name == '' || email == '' || website == '' || email2 == '' || website2 == '') {
       // if ( k == '1235'  ) {


        //console.log("now file" + key);
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: 'Something went wrong! ',
            // text: 'Something went wrong!',
            footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
        })
        
        document.getElementById("hidediv").style.display = 'none';

    } else {

        document.getElementById("hidediv").style.display = 'none';
         swal({
            title: "Are you sure of booking the ride ?",
            text: "once submitted, you can never go back!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                   // submitdriverwithacar();
                 //  vehicleinfowithdrivernew1();
                // alert(123456098)
                SenttheRequest();
                }
                else {

                    document.getElementById("hidediv").style.display = 'none';
                    swal("Cancelled");
                }
            });

 

    }


}
 
function SenttheRequest(){


    // $("#booknow").on("click", function(e) {

    //     e.preventDefault();


        // var k="123";
        var name = document.getElementById("BookingCost").value;
        //var password = document.getElementById("driver_list_id").value;
        var cusmoter = document.getElementById('userID').value;
        var email = document.getElementById("lat").value;
        var website = document.getElementById("long2").value;

        var email2 = document.getElementById("txtSource").value;
        var website2 = document.getElementById("txtDestination").value;
        //Check input Fields Should not be blanks.
        if (cusmoter == '' || name == '' || email == '' || website == '' || email2 == '' || website2 == '') {
            // if ( k == '1235'  ) {


            const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                iconColor: 'white',
                customClass: {
                popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 5500,
                timerProgressBar: true
              })
              Toast.fire({
                icon: 'error',
                title: 'Something went wrong!',
               // text: 'Something went wrong!',
                footer: '<a href="#" style="color:white;font-weight:bold">Error : Empty Field !“.</a>'
              })

        } else {

            getthisbook();
                     //alert("okay")

        }

    //});

}



// function getthisbook() {


// const getthisbook = async (e) => {
//     e.preventDefault();

    

const getthisbook = async () => {
   // e.preventDefault();


    document.getElementById("hidediv").style.display = 'inline';
  // vehicleinfowithdrivernew() ;

    //get email and password
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
        popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true
      })
      await Toast.fire({
        icon: "warning",
        heading: 'Sending......',
        text: 'Please wait.',
       // text: 'Something went wrong!',
       // footer: '<a href="#" style="color:white;font-weight:bold">Error “Booking not complete at this time  !“.</a>'
      })



    var status = "pending";

    //  var BookingStatus = "pending";
    const tripStatus = "pending";
 
    const userID = document.getElementById('userID').value;
    //End Customer


    //const carType = document.getElementById('arType').value = profilePhoto;
    const carType = document.getElementById("carType").value;
 
    var paymenttypechkcars = document.getElementsByName("paymenttypechkcars");
    var paymentType = Array.from(paymenttypechkcars).find(radio => radio.checked);
    //  paymenttypechkcar.value 


    const DriverMessage = document.getElementById("DriverMessage").value;
    //End Driver

    const Bookingduration = document.getElementById("Bookingduration").value;
    const BookingDistance = document.getElementById("BookingDistance").value;

    const scheduledTrip = document.getElementById("scheduledTrip").value;



    //  const  BookingState = document.getElementById("BookingState").value;




    var dispatchmodes = document.getElementsByName("dispatchmodechkcar");
    var dispatchmodes = Array.from(dispatchmodes).find(radio => radio.checked);
    //dispatchmodes.value
    // var dispatchmodes = document.getElementsByName("dispatchmodechkcar");
    // var BookingState = Array.from(dispatchmodes).find(radio => radio.checked);


    const scheduledTripDate = document.getElementById("scheduledTripDate").value;
    const scheduledTripTime = document.getElementById("scheduledTripTime").value;




    const BookingCost = document.getElementById("BookingCost").value;
    //   const BookingDate = document.getElementById("BookingDates").value;
   // const BookingStatusonline = document.getElementById("BookingStatusonline").value;

    const BookingStatusonline =  "Yes";


    //created_at
    const created_at = document.getElementById("datecreatedd").value;

    const currentAddress = document.getElementById("txtSource").value;

    const dropAddress = document.getElementById("txtDestination").value;
    const tripDistance = 0.00;
    const tripFare = 0.00;
    var isDelivery = "false"
    const Cancelled = "pending";

    const pickupLat = document.getElementById("lat").value;
    const pickupLng = document.getElementById("long").value;


    const dropLat = document.getElementById("lat2").value;
    const dropLng = document.getElementById("long2").value;

    var vehicleInfo = document.getElementById("vehicledetails").value;
    var isDelivery = "false";
    var carColor = document.getElementById('carColor').value;
    // var   carColor = "Green";
 var   acceptedTime ="NA"
    //var riderProfileimage
    var abc = firebase.database().ref('akwaaba/bookings/').push({

        carColor: carColor,
        acceptedTime:acceptedTime,
        
        pickup_location: { latitude: parseFloat(pickupLat), longitude: parseFloat(pickupLng) },


        drop_off_location: { latitude: parseFloat(dropLat), longitude: parseFloat(dropLng) },



        isDelivery: isDelivery,

        tripStatus: Cancelled,
        tripFare: tripFare,
        tripDistance: tripDistance,
        tripStatus: tripStatus,
        status: status,

        destination_address: dropAddress,

        pickup_address: currentAddress,

        rider_id: userID,


        driverMessage: DriverMessage,

        carType: carType,
        paymentMethod: paymentType.value,
        //  bookingState:BookingState.value,

        //  scheduledTrip: scheduledTrip.value,


        bookingState: dispatchmodes.value,

        scheduledTrip: scheduledTrip,


        scheduledTripDate: scheduledTripDate,
        scheduledTripTime: scheduledTripTime,

        estimated_duration: Bookingduration,
        estimated_distance: BookingDistance,

        //    BookingDate: BookingDate,
        estimated_fare: parseFloat(BookingCost),
        //   BookingStatusonline: BookingStatusonline,

        bookingStatusonline: BookingStatusonline,

        isDelivery: isDelivery,
        //  bookingStatus: BookingStatus,  
        //  bookingStatus
        created_at: created_at,

        vehicleInfo: vehicleInfo,

    
    })
    .then( async  snap => { 

    
        const key = snap.key 
        console.log("The Key Driver booking = " + key)
    updateCustomers(key);

    updateBooking(key);

    var dispatchmodes = document.getElementsByName("dispatchmodechkcar");
    var dispatchmode = Array.from(dispatchmodes).find(radio => radio.checked);
    //dispatchmode.value

    ////////console.log("This is 11111111111111111 " + dispatchmode.value)
    //var  BookingStates = document.getElementById("BookingState").value;


    if (dispatchmode.value == "Auto" || dispatchmode.value == "auto") {
        $(this).find(':submit').attr('disabled', 'disabled');

        document.getElementById("arraybookedkey").value = "";
        document.getElementById("arraybookedkey_arraytotal").value = "0";
        //  document.getElementById("getnewdriverIdwithjob").value = "";
        ////alert1);

        //  /*


        var get_new_autodriver_array = getalldrivers();

        var abcd = get_new_autodriver_array[0]['id'];
        document.getElementById("getnewdriverfirstolddata").value = abcd;


        console.log("The lenght= get_new_autodriver_array[0]['id'] " + abcd);
        var get_new_autodriver_arrays = 1;
        get_new_autodriver_arrays += get_new_autodriver_array.length;




        document.getElementById("thearray").value = "";
        document.getElementById("thearray").value = get_new_autodriver_array;


        window.setTimeout(function() {

            getdrivertokening(key, get_new_autodriver_array);
        }, 3000);


        document.getElementById("arraybookedkey").value = key;
        document.getElementById("arraybookedkey_arraytotal").value = get_new_autodriver_arrays;

        



    } else {


        //


        ////
        //alert(2);
        // 
       // 
       sendPush(key);





    }


    })
    .catch(async function ( error) {

        console.log(error.message)
        
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
            popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
          })
          await Toast.fire({
            icon: 'error',
            title: 'Something went wrong!',
           // text: 'Something went wrong!',
            footer: '<a href="#" style="color:white;font-weight:bold">Error “Booking !.</a>'
          })


          document.getElementById("hidediv").style.display = 'none';
 
    })
    // 
    .then(async success => {


        const Toast = Swal.mixin({
            // ld(),
             toast: true,
             position: 'top-right',
             iconColor: 'white',
             customClass: {
                 popup: 'colored-toast'
             },
             showConfirmButton: false,
             timer: 8500,
             timerProgressBar: true
         })
         await Toast.fire({
             icon: 'success',
             title: ' Vehicle registration',
             text: 'Successful!',
             //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
             
         })

           document.getElementById("hidediv").style.display = 'none';

    })

 
}
















function updateCustomers(key) {

    const rider_name = document.getElementById("fname").value;
    const rider_lname = document.getElementById("lname2").value;
    const rider_phone = document.getElementById("phone").value;
    const rider_email = document.getElementById("email").value;

    const profilePhoto = document.getElementById("profilePhoto").value;

    var rider_names = rider_name + " " + rider_lname;
    //gvcgd4322 112+ 

    firebase
        .database()
        .ref()
        .child("akwaaba/bookings/" + key)
        .update({
            rider_name: rider_names,
            rider_phone: rider_phone,
            rider_email: rider_email,
            riderProfileImage: profilePhoto,

            // bookingID  : key,

        });



}



function getdrivertokening(key, get_new_autodriver_array) {
    var chk = document.getElementById('getnewdriverfirstolddata').value;
    ////console.log("Thec jhecks  " + chk);




    if (chk == "10") {
        ////console.log("off " + chk);

    } else {

        var getautodriverIdsdriverId = get_new_autodriver_array[0]['id'];
        ////console.log("This FIRST Auto id was in  2 " + getautodriverIdsdriverId);
        var olddriverId2 = document.getElementById('getnewdriverfirstolddata').value;

        ////console.log("This FIRST Auto id was in 1111111 2 " + olddriverId2);

        var queryRef = firebase.database().ref("akwaaba/drivers/");
        queryRef.on('value', function(snapshot) {


            if (snapshot.exists()) {

                //$("#userlist-table tr:gt(1)").remove();
                //  $('#userlist-table').find('tbody').empty();


                snapshot.forEach(function(data) {
                    var val = data.val();
                    //     ////console.log("print all data.keydata.key" + data.key);
                    if (data.key == olddriverId2) {

                        ////console.log("This FIRST Auto id was in " + getautodriverIdsdriverId);
                        //////console.log("print all" + get_new_autodriver_array);
                        document.getElementById('olddriverkey').value = "";
                        document.getElementById('olddriverkey').value = getautodriverIdsdriverId;



                        document.getElementById('getnewdriverIdwithjobauto').value = '';

                        document.getElementById('getnewdriverIdwithjoboldid').value = '';

                        ////console.log('new All auto token Seen is coming from auto booking' + val.token);
                        //  

                        // document.getElementById('autotoken').value = sht.val().token;
                        document.getElementById('autotoken').value = val.token;
                        var token = val.token;
                        // document.getElementById('checkautotoken').value = sht.val().token;
                        document.getElementById('getnewdriverIdwithjobauto').value = getautodriverIdsdriverId;
                        // //alert'The token' + token);
                        //var newautotoken= sht.val().token;

                        var checkauto = document.getElementById('checkautotoken').value;

                        document.getElementById('getnewdriverIdwithjoboldid').value = key;

                        document.getElementById('getnewdriverfirstolddata').value = "10";

                        if (checkauto == "10") {

                            myautosendPush(key);
                            //  window.setTimeout(function() {


                            vehicleinformation(getautodriverIdsdriverId);
                            document.getElementById('checkautotoken').value = "TUG";


                            setTimeout(myautosendPush_newtrips_update(key, getautodriverIdsdriverId), 2000);

                        } else {

                            ////console.log('Booking done 4 this driver ');

                        }

                    }
                })
            }
        })




    }
}




function getdrivertokens(key, get_new_autodriver_array) {


    var checktheautoid = document.getElementById('checktheautotoken').value;
    if (checktheautoid == "10") {

        var getautodriverIdsdriverId = get_new_autodriver_array[0]['id'];
        ////console.log("This FIRST Auto id was in " + getautodriverIdsdriverId);
        //////console.log("print all" + get_new_autodriver_array);
        document.getElementById('olddriverkey').value = "";
        document.getElementById('olddriverkey').value = getautodriverIdsdriverId;

        firebase.database().ref("akwaaba/drivers/")
            .on("value", function(sht) {


                // //alert sht.val().token);
                ///olddriverkey

                if (getautodriverIdsdriverId == sht.key) {

                    document.getElementById('getnewdriverIdwithjobauto').value = '';

                    document.getElementById('getnewdriverIdwithjoboldid').value = '';

                    ////console.log('new All auto token Seen is coming from auto booking' + sht.val().token);
                    //  

                    // document.getElementById('autotoken').value = sht.val().token;
                    document.getElementById('autotoken').value = sht.val().token;
                    var token = sht.val().token;
                    // document.getElementById('checkautotoken').value = sht.val().token;
                    document.getElementById('getnewdriverIdwithjobauto').value = getautodriverIdsdriverId;
                    // //alert'The token' + token);
                    //var newautotoken= sht.val().token;

                    var checkauto = document.getElementById('checkautotoken').value;

                    document.getElementById('getnewdriverIdwithjoboldid').value = key;



                    if (checkauto == "10") {

                        //  setTimeout(myautosendPush(key), 2000);
                        //   setTimeout(function() { window.location = "bookinglinks"; }, 2000);
                        //sendPushsing(key);



                        myautosendPush(key);
                        //  window.setTimeout(function() {


                        vehicleinformation(getautodriverIdsdriverId);
                        document.getElementById('checkautotoken').value = "TUG";


                        setTimeout(myautosendPush_newtrips_update(key, getautodriverIdsdriverId), 2000);

                    } else {

                        ////console.log('Booking done 4 this driver ');

                    }

                }

                // }, 2000);
                /*
  window.setTimeout(function () {
    sendPush(key,getautodriverIdsdriverId)
    ;
  }, 3000);
// */


            })

        document.getElementById('checktheautotoken').value = "TUG";

    } else {

        ////console.log('main Booking done 4 this driver now ');

    }

    //setTimeout(function() { getthisbook(); }, 2000);

}

// Get Vehicle Information
//
function vehicleinformation(getautodriverIdsdriverId) {

    firebase.database().ref('akwaaba/assignDrivers/').on("value", function(snapshot) {

        snapshot.forEach(function(data) {
            var val = data.val();
            //////console.log("Get the Status key " + data.key) ;


            if (val.status == "approved" && val.driverId == getautodriverIdsdriverId) {

                var carcolor = val.carColor;
                var carname = val.vehiclemodel;
                var carmodel = val.vehiclemodel;
                var carregnumber = val.licensePlate;
                var cardetails = carcolor + " " + carname + " " + carmodel + " " + carregnumber;


                //   document.getElementById('carColor').value = ""; 

                //    document.getElementById('carColor').value = carColor; 

                document.getElementById('vehicledetails').value = "";
                document.getElementById('vehicledetails').value = cardetails;

            }
        })
    }, function(error) {
        //alert"Error: " + error.code);
    });
}

///*
// const sendPush = (key) => {




function updateBooking(key) {

    firebase
        .database()
        .ref()
        .child("akwaaba/bookings/" + key)
        .update({
            booking_id: key,

        });



}



/* Auto Booking Booking */
// var getdriver_list_id_auto;


// Auto push 2


function myautosendPush(key) {

    //  var getautodriverIdsdriverId="0";

    //  var driver_list_ids;


    const getautodriverIdsdriverId = document.getElementById('getnewdriverIdwithjobauto').value;
    //   ////console.log("The nnnnnnnn id aut0=======" + getautodriverIdsdriverId);
    const token = document.getElementById('autotoken').value;

    // ////console.log("The auto token id autids=======" + token);

    const userid = document.getElementById('userID').value;

    const Bookingduration = document.getElementById("Bookingduration").value;
    const BookingDistance = document.getElementById("BookingDistance").value;

    const pickupLat = document.getElementById("lat").value;
    const pickupLng = document.getElementById("long").value;
    const currentAddress = document.getElementById("txtSource").value;

    const dropAddress = document.getElementById("txtDestination").value;
    const dropLat = document.getElementById("lat2").value;
    const dropLng = document.getElementById("long2").value;

    const rider_name = document.getElementById("fname").value;
    const rider_lname = document.getElementById("lname2").value;
    const rider_phone = document.getElementById("phone").value;

    //        const  rider_email = document.getElementById("email").value;

    var rider_names = rider_name + " " + rider_lname;

    const estimated_fare = document.getElementById("BookingCost").value;

    const riderProfileimage = document.getElementById("profilePhoto").value;

    var scheduledTrip = document.getElementById("scheduledTrip").value;
    // var   isDelivery = document.getElementById("isdelivery").value;
    var isDelivery = "false";
    // const BookingStatusonline = document.getElementById("BookingStatusonline").value;


    //////console.log("send this new id as auto :" + driver_list_ids);
    var newTrip = "newTrip";

    //    var  paymentMethod="cash";

    //   var paymenttype = document.getElementsByName("paymenttypechkNo");
    //   var paymentMethod = Array.from(paymenttype).find(radio => radio.checked);

    var paymenttypechkcars = document.getElementsByName("paymenttypechkcars");
    var paymenttypechkcar = Array.from(paymenttypechkcars).find(radio => radio.checked);

    const vehicleInfo = document.getElementById('vehicledetails').value;
    //  paymenttypechkcar.value 

    var statuss = "pending";

       const BookingStatusonline = "Yes";

    var datakey = {
        //booo
        BookingStatusonline: BookingStatusonline,

        // Job : "system",
        booking_id: key,
        status: statuss,
        rider_id: userid,


        rider_name: rider_names,
        rider_phone: rider_phone,
        riderProfileImage: riderProfileimage,



        estimated_duration: Bookingduration,
        estimated_distance: BookingDistance,


        estimated_fare: parseFloat(estimated_fare),


        pickup_address: currentAddress,
        destination_address: dropAddress,


        pickup_location: { latitude: parseFloat(pickupLat), longitude: parseFloat(pickupLng) },

        drop_off_location: { latitude: parseFloat(dropLat), longitude: parseFloat(dropLng) },

        scheduledTrip: scheduledTrip,
        //   paymentMethod : paymentMethod.value,
        paymentMethod: paymenttypechkcar.value,
        isDelivery: isDelivery,

        vehicleInfo: vehicleInfo,
    }

    var data = {

        id: "1",
        status: "done",
        rideId: key,
        type: "newTrip",
        rideInfo: JSON.stringify(datakey),
    }

    let body = {

        to: token,
        data: data,
        priority: "high",

    };



    // Setting options for push notification
    const options = {
        method: "POST",
        headers: new Headers({
            // Add your server key after key=
            Authorization: "key=AAAAZTuVihE:APA91bF5zWvD3peaAyJrAAlxc6KgUkFbYAhitrRYNx0WFZ4cPcVPgd9U6KtKlAyWtVmav8VF0kxp4CRIm867fTxK3_wHVqMzIekZclKecUMAsqNS3bdmYytsua3kW-rgWmQTsQk9XaVH",
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(body)
    };

    // Sending Push notifications to user using fetch api
    var sending = document.getElementById('sending').value;
    if (sending == "0") {
        fetch("https://fcm.googleapis.com/fcm/send", options)
            .then(res => res.json())
            .then(data => {
                if (data.failure == 1) {
                    ////console.log("Token Expire" + data.failure);
                } else {
                    document.getElementById('checkautotoken').value = "checkautotoken";
                    document.getElementById('checktheautotoken').value = "TUG";
                    document.getElementById('getnewdriverfirstolddata').value = "10";
                    document.getElementById('sending').value = "10";
                    ////console.log("Send Success my push done.............. ");
                    window.setTimeout(function() {
                        checkifdriveracceptjob();
                    }, 16000);


                }
            })
            .catch(err => {
                //////console.log(err);
            });
    }
    //push end    
};


// function updatedrivernewtrips_auto(key, getdriver_list_id_auto)
function myautosendPush_newtrips_update(key, getautodriverIdsdriverId) {
    //

    // var driverIds = document.getElementById('driver_list_id').value;
    // ////console.log(" driver_list_i is  =" + getdriver_list_id);
    firebase
        .database()
        .ref()
        .child("akwaaba/drivers/" + getautodriverIdsdriverId)
        .update({
            newTrip: key,

        });



}





// send cond auto push


// Auto push 2


function myautosendPush2(key) {


    //   ////console.log("The nnnnnnnn id aut0=======" + getautodriverIdsdriverId);
    const token = document.getElementById('autotoken').value;

    // ////console.log("The auto token id autids=======" + token);

    const userid = document.getElementById('userID').value;

    const Bookingduration = document.getElementById("Bookingduration").value;
    const BookingDistance = document.getElementById("BookingDistance").value;

    const pickupLat = document.getElementById("lat").value;
    const pickupLng = document.getElementById("long").value;
    const currentAddress = document.getElementById("txtSource").value;

    const dropAddress = document.getElementById("txtDestination").value;
    const dropLat = document.getElementById("lat2").value;
    const dropLng = document.getElementById("long2").value;

    const rider_name = document.getElementById("fname").value;
    const rider_lname = document.getElementById("lname2").value;
    const rider_phone = document.getElementById("phone").value;

    //        const  rider_email = document.getElementById("email").value;

    var rider_names = rider_name + " " + rider_lname;

    const estimated_fare = document.getElementById("BookingCost").value;

    const riderProfileimage = document.getElementById("profilePhoto").value;

    var scheduledTrip = document.getElementById("scheduledTrip").value;
    // var   isDelivery = document.getElementById("isdelivery").value;
    var isDelivery = "false";


    //////console.log("send this new id as auto :" + driver_list_ids);
    var newTrip = "newTrip";

  
    var paymenttypechkcars = document.getElementsByName("paymenttypechkcars");
    var paymenttypechkcar = Array.from(paymenttypechkcars).find(radio => radio.checked);

    const vehicleInfo = document.getElementById('vehicledetails').value;
    //  paymenttypechkcar.value 

    var statuss = "pending";
    const BookingStatusonline = "Yes";

    var datakey = {
        //booo
        //   BookingStatusonline: BookingStatusonline,
        // Job : "system",
        booking_id: key,
        status: statuss,
        rider_id: userid,


        rider_name: rider_names,
        rider_phone: rider_phone,
        riderProfileImage: riderProfileimage,



        estimated_duration: Bookingduration,
        estimated_distance: BookingDistance,


        estimated_fare: parseFloat(estimated_fare),


        pickup_address: currentAddress,
        destination_address: dropAddress,


        pickup_location: { latitude: parseFloat(pickupLat), longitude: parseFloat(pickupLng) },

        drop_off_location: { latitude: parseFloat(dropLat), longitude: parseFloat(dropLng) },

        scheduledTrip: scheduledTrip,
        //   paymentMethod : paymentMethod.value,
        paymentMethod: paymenttypechkcar.value,
        isDelivery: isDelivery,

        vehicleInfo: vehicleInfo,
    }

    var data = {

        id: "1",
        status: "done",
        rideId: key,
        type: "newTrip",
        rideInfo: JSON.stringify(datakey),
    }

    let body = {

        to: token,
        data: data,
        priority: "high",

    };



    // Setting options for push notification
    const options = {
        method: "POST",
        headers: new Headers({
            // Add your server key after key=
            Authorization: "key=AAAAZTuVihE:APA91bF5zWvD3peaAyJrAAlxc6KgUkFbYAhitrRYNx0WFZ4cPcVPgd9U6KtKlAyWtVmav8VF0kxp4CRIm867fTxK3_wHVqMzIekZclKecUMAsqNS3bdmYytsua3kW-rgWmQTsQk9XaVH",
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(body)
    };

    // Sending Push notifications to user using fetch api

    var oldnewkey2 = parseFloat(document.getElementById("oldnewkey2").value);
    var oldnewkey1 = parseFloat(document.getElementById("oldnewkey1").value);
    //   var newkey = document.getElementById("arraybookedkey").value;
    var oldnewkey33 = parseFloat(document.getElementById("oldnewkey3").value);

    //  var sending = document.getElementById('sending').value;

    // document.getElementById("arraynumberdisplay").value = total;
    // 

    if (oldnewkey2 == oldnewkey1 && oldnewkey33 == "10") {
        fetch("https://fcm.googleapis.com/fcm/send", options)
            .then(res => res.json())
            .then(data => {
                if (data.failure == 1) {
                    ////console.log("Token Expire" + data.failure);
                } else {
                    document.getElementById('checkautotoken').value = "checkautotoken";
                    document.getElementById('checktheautotoken').value = "TUG";
                    document.getElementById('oldnewkey3').value = "100";
                    document.getElementById('sending').value = "10";
                    ////console.log("Send Success my push done .............. 2 ");
                    window.setTimeout(function() {
                        checkifdriveracceptjob();
                    }, 16000);


                }
            })
            .catch(err => {
                //////console.log(err);
            });
        //
    } else {
        ////console.log("Send Success my push done .............. Ends ");
        //  setTimeout(function() { window.location = "bookinglinks"; }, 3000);
    }
};


/* Manual Booking */


function sendPush(key) {

    //  var getautodriverIdsdriverId="0";

    var driver_list_ids;


    const getdriver_list_id = document.getElementById('driver_list_id').value;

    const token = document.getElementById('manualtoken').value;

    const userid = document.getElementById('userID').value;

    const Bookingduration = document.getElementById("Bookingduration").value;
    const BookingDistance = document.getElementById("BookingDistance").value;

    const pickupLat = document.getElementById("lat").value;
    const pickupLng = document.getElementById("long").value;
    const currentAddress = document.getElementById("txtSource").value;

    const dropAddress = document.getElementById("txtDestination").value;
    const dropLat = document.getElementById("lat2").value;
    const dropLng = document.getElementById("long2").value;

    const rider_name = document.getElementById("fname").value;
    const rider_lname = document.getElementById("lname2").value;
    const rider_phone = document.getElementById("phone").value;

    //        const  rider_email = document.getElementById("email").value;

    var rider_names = rider_name + " " + rider_lname;

    const estimated_fare = document.getElementById("BookingCost").value;

    const riderProfileimage = document.getElementById("profilePhoto").value;

    var scheduledTrip = document.getElementById("scheduledTrip").value;
    // var   isDelivery = document.getElementById("isdelivery").value;
    var isDelivery = "false";


    //////console.log("send this new id as auto :" + driver_list_ids);
    var newTrip = "newTrip";

    //    var  paymentMethod="cash";

    //   var paymenttype = document.getElementsByName("paymenttypechkNo");
    //   var paymentMethod = Array.from(paymenttype).find(radio => radio.checked);

    var paymenttypechkcars = document.getElementsByName("paymenttypechkcars");
    var paymenttypechkcar = Array.from(paymenttypechkcars).find(radio => radio.checked);

    const vehicleInfo = document.getElementById('vehicledetails').value;
    //  paymenttypechkcar.value 

    var statuss = "pending";
    const BookingStatusonline = "Yes";

    var datakey = {
        //booo
        //  BookingStatusonline: BookingStatusonline,

        // Job : "system",
        booking_id: key,
        status: statuss,
        rider_id: userid,


        rider_name: rider_names,
        rider_phone: rider_phone,
        riderProfileImage: riderProfileimage,



        estimated_duration: Bookingduration,
        estimated_distance: BookingDistance,


        estimated_fare: parseFloat(estimated_fare),


        pickup_address: currentAddress,
        destination_address: dropAddress,


        pickup_location: { latitude: parseFloat(pickupLat), longitude: parseFloat(pickupLng) },

        drop_off_location: { latitude: parseFloat(dropLat), longitude: parseFloat(dropLng) },

        scheduledTrip: scheduledTrip,
        //   paymentMethod : paymentMethod.value,
        paymentMethod: paymenttypechkcar.value,
        isDelivery: isDelivery,

        vehicleInfo: vehicleInfo,
    }

    var data = {

        id: "1",
        status: "done",
        rideId: key,
        type: "newTrip",
        rideInfo: JSON.stringify(datakey),
    }

    let body = {

        to: token,
        data: data,
        priority: "high",

    };



    // Setting options for push notification
    const options = {
        method: "POST",
        headers: new Headers({
            // Add your server key after key=
            Authorization: "key=AAAAZTuVihE:APA91bF5zWvD3peaAyJrAAlxc6KgUkFbYAhitrRYNx0WFZ4cPcVPgd9U6KtKlAyWtVmav8VF0kxp4CRIm867fTxK3_wHVqMzIekZclKecUMAsqNS3bdmYytsua3kW-rgWmQTsQk9XaVH",
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(body)
    };

    // Sending Push notifications to user using fetch api
    fetch("https://fcm.googleapis.com/fcm/send", options)
        .then(res => res.json())
        .then(data => {
            if (data.failure == 1) {
                //////console.log("Token Expire" + data.failure);
            } else {

                ////console.log("Send Success");
                // 
                //
                // /*
                window.setTimeout(function() {
                    updatedrivernewtrips(key, getdriver_list_id);
                }, 2000);
                //*/

            }
        })
        .catch(err => {
            //////console.log(err);
        });
    //push end    
};







function updatedrivernewtrips(key, getdriver_list_id) {
    //

    // var driverIds = document.getElementById('driver_list_id').value;
    // ////console.log(" driver_list_i is  =" + getdriver_list_id);

    firebase
        .database()
        .ref()
        .child("akwaaba/drivers/" + getdriver_list_id)
        .update({
            newTrip: key,

        });



}












// getdrivertoken_next_person(key,getnew_driverId_for_next_car)


function getdrivertoken_next_person(key, getnew_driverId_for_next_car) {


    var getautodriverIdsdriverId = getnew_driverId_for_next_car;

    firebase.database().ref("akwaaba/drivers/" + getautodriverIdsdriverId).orderByValue().on("value", function(sht) {


        document.getElementById('autotoken').value = '';
        document.getElementById('usertoken2').value = '';
        //////console.log('Seen is coming from auto booking' +sht.val().token);
        //   alert
        document.getElementById('usertoken2').value = sht.val().token;
        document.getElementById('autotoken').value = sht.val().token;
        // //alert'The token' + token);


        setTimeout(myautosendPush2(key, getautodriverIdsdriverId), 1000);

        document.getElementById('oldnewkey2').value = getautodriverIdsdriverId;

        document.getElementById('oldnewkey1').value = getautodriverIdsdriverId;
        document.getElementById('oldnewkey3').value = "10";

        //  setTimeout(myautosendPush2(key), 2000);
        //   updatedrivernewtrips_update_for_next_driver(key, getdriver_list_id);

        window.setTimeout(function() {
            updatedrivernewtrips_update_for_next_driver(key, getautodriverIdsdriverId);
        }, 2000);


    })

    vehicleinformationnextdriver(getautodriverIdsdriverId);
    //setTimeout(function() { getthisbook(); }, 2000);



}



// Get Vehicle Information

function vehicleinformationnextdriver(getautodriverIdsdriverId) {

    firebase.database().ref('akwaaba/assignDrivers/').on("value", function(snapshot) {

        snapshot.forEach(function(data) {
            var val = data.val();
            //////console.log("Get the Status key " + data.key) ;


            if (val.status == "approved" && val.driverId == getautodriverIdsdriverId) {

                var carcolor = val.carColor;
                var carname = val.vehiclemodel;
                var carmodel = val.vehiclemodel;
                var carregnumber = val.licensePlate;
                var cardetails = carcolor + " " + carname + " " + carmodel + " " + carregnumber;

                document.getElementById('vehicledetails').value = "";
                document.getElementById('vehicledetails').value = cardetails;

            }
        })
    }, function(error) {
        //alert"Error: " + error.code);
    });
}



function checkifdriveracceptjob() {
    var getarraynum = parseFloat(document.getElementById("arraynumberdisplay").value);
    var newkey = document.getElementById("arraybookedkey").value;
    var arraybookedkey_arraytotal = parseFloat(document.getElementById("arraybookedkey_arraytotal").value);
    var getdriver_old_list_id = document.getElementById('driver_list_id').value;



    var olddriverkey = document.getElementById('olddriverkey').value;
    var getnewdriverIdwithbooking = document.getElementById('getnewdriverIdwithjoboldid').value;


    if (getarraynum != arraybookedkey_arraytotal) {


        var total;
        var key = newkey;
        var x1 = 1;
        const xy = parseFloat(x1);

        //var getarraynum=parseFloat(x2);  
        total = getarraynum += xy;
        //////console.log(total); 
        document.getElementById("arraynumberdisplay").value = "";




        document.getElementById("arraynumberdisplay").value = total;

        //  ////console.log(" The total for next driver the kye =========== " + key + total);

        var get_new_autodriver_arraysz = getalldrivers();

        // var get_new_autodriver_arrays = document.getElementById("thearray").value;


        var getnew_driverId_for_next_car = get_new_autodriver_arraysz[total]['id'];



        ////console.log(" The getnew_driverId_for_next_car  calculate =========== " + getnew_driverId_for_next_car);

        firebase.database().ref('akwaaba/bookings/' + key).orderByValue().on("value", function(sht) {

            var BookingStatus = sht.val().status;

            ////console.log("The id 3 " + key);
            //olddriverkey
            if (BookingStatus == "pending") {

                firebase.database().ref("akwaaba/drivers/" + olddriverkey).on("value", function(shts) {

                    var BookingStatuss = shts.val().newTrip;

                    //accepted
          


                    if (BookingStatuss == getnewdriverIdwithbooking) {

                        old_driver_newTrip_updatedri(getdriver_old_list_id);
                        getdrivertoken_next_person(key, getnew_driverId_for_next_car);

                        document.getElementById('olddriverkey').value = "";
                        document.getElementById('olddriverkey').value = getnew_driverId_for_next_car;

                    }

                })

            }



        })


    } else {

      //  clearform();
        document.getElementById("txtSource").value = "";

        document.getElementById("txtDestination").value = "";
        document.getElementById("carType").options.length = 0;
        document.getElementById("customers_lists").options.length = 0;
        // $("#carType")[0].selectedIndex = 0;
        // $("#customers_lists")[0].selectedIndex = "0";


        //  $("#paymenttype")[0].selectedIndex = "";
        // $("#BookingState")[0].selectedIndex = 0;

        $("#driver_list")[0].selectedIndex = 0;
        $('#driver_list').prop('disabled', false);

      //  $("#scheduledTrip")[0].selectedIndex = 0;


        //////console.log("Auto searching Drivers Done "  );

        document.getElementById("arraybookedkey").value = "0";
        document.getElementById("arraybookedkey_arraytotal").value = "1";
        document.getElementById("lat").value = "";
        document.getElementById("long").value = "";
        document.getElementById("getnewdriverIdwithjob").value = "";
    }



    // */
}




function updatedrivernewtrips_update_for_next_driver(key, getdriver_list_id) {
    //
    // var driverIds = document.getElementById('driver_list_id').value;
    // ////console.log(" driver_list_i is  =" + getdriver_list_id);
    firebase
        .database()
        .ref()
        .child("akwaaba/drivers/" + getdriver_list_id)
        .update({
            newTrip: key,

        });
}

function old_driver_newTrip_updatedri(getdriver_old_list_id) {
    //

    // var driverIds = document.getElementById('driver_list_id').value;
    // ////console.log(" driver_list_i is  =" + getdriver_list_id);
    firebase
        .database()
        .ref()
        .child("akwaaba/drivers/" + getdriver_old_list_id)
        .update({
            newTrip: "waiting",

        });



}




    // $("#mysend").on("click", function() {
        function getalldrivers() {
//driversCurrentLocation
//  alert(677)
var firebaseRef = firebase.database().ref('akwaaba/driversCurrentLocation/');
//driversCurrentLocation
var geoFire = new geofire.GeoFire(firebaseRef);
var geoQuery;
var oneDriver = {};

var alldrivers = [];


// Create a new GeoFire instance at the random Firebase location
//var geoFireInstance = new geofire.GeoFire(firebaseRef);


//var geoQuery;






            //  document.getElementById("lat").value="";
            // document.getElementById("long").value="";
            alldrivers.length = 0;
            var lat = parseFloat($("#lat").val());
            var lon = parseFloat($("#long").val());
            var radius = 200;
    
    
    
            geoFire.query({
                center: [lat, lon],
                radius: radius
            }, function(error) {
                //////console.log("Error: " + error);
            }).on('key_entered', (key, location, distance) => {
    
                oneDriver = {
                    id: key,
                    distance: distance.toFixed(2),
                    location: location
                };
                //   //////console.log(oneDriver);
    
    
                alldrivers.push(oneDriver);
    
    
            });
    
    
            return alldrivers;
        }
    
    




        function clearform() {

            // document.getElementById("resetform").reset();
            document.getElementById("myInput").value = "";
            document.getElementById("dvDistance").innerHTML = "";
            document.getElementById("dvDistance2").innerHTML = "";
    
           // document.getElementById("dvMap").innerHTML = "";
           // document.getElementById("dvPanel").innerHTML = "";
    
            //  document.getElementById("lat").value="";
            //  document.getElementById("long").value="";
            document.getElementById("txtSource").value = "";
    
            document.getElementById("txtDestination").value = "";
            document.getElementById("lat2").value = "";
            document.getElementById("long2").value = "";
            // Customer
    
    
            document.getElementById('userID').value = "";
            //End Customer
    
    
            document.getElementById('customers_lists').value = "";
            document.getElementById("carType").value = "";
            // document.getElementById("paymentType").value="";
            //    document.getElementById("BookingState").value="";
    
            // Driver
            //   document.getElementById('driver_list_id').value = "";
    
            document.getElementById("DriverMessage").value = "";
            //End Driver
    
            document.getElementById("Bookingduration").value = "";
            document.getElementById("BookingDistance").value = "";
    
            document.getElementById("scheduledTrip").value="";

            document.getElementById("scheduledTripDate").value = "";
            document.getElementById("scheduledTripTime").value = "";
    
            document.getElementById("usertoken2").value = "";
            document.getElementById("usertoken").value = "";
    
    
            $("#carType")[0].selectedIndex = "";
            $("#customers_lists")[0].selectedIndex = "0";
    
    
            //  $("#paymenttype")[0].selectedIndex = "";
            // $("#BookingState")[0].selectedIndex = 0;
    
            $("#driver_list")[0].selectedIndex = 0;
            $('#driver_list').prop('disabled', false);
    
            $("#scheduledTrip")[0].selectedIndex = 0;
    
    
        }


export { MakeRequest };




 



//// , function(error) {
//     if (error) {
//         // The write failed...
//         //////console.log("Error in saving");
//     } else {
//         // Data saved successfully!

//         //////console.log("Data saved successfully!"  );
//         const Toast = Swal.mixin({
//             // ld(),
//              toast: true,
//              position: 'top-right',
//              iconColor: 'white',
//              customClass: {
//                  popup: 'colored-toast'
//              },
//              showConfirmButton: false,
//              timer: 18500,
//              timerProgressBar: true
//          })
//        //  await
//           Toast.fire({
//              icon: 'success',
//              title: ' Request Sending',
//              text: 'Successful!',
//              //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
             
//          })
//         document.getElementById("txtSource").value = "";

//         document.getElementById("txtDestination").value = "";
//         document.getElementById("dvDistance").innerHTML = ""
//         document.getElementById("dvDistance2").innerHTML = ""
//         document.getElementById("myInput").innerHTML = ""


//     }