

import firebases from '../Auth/userauth';
// import bootstrap from 'bootstrap/dist/css/bootstrap.min.css';
// import $ from "jquery";
// import moment from 'moment';
const google = window.google;
//const google = window.google = window.google ? window.google : {}
//console.log(moment().format());
 
// var script = document.createElement('script');
// script.type = 'text/javascript';
// script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD4pU3iPmaZW0mu5Sfu91Ar8x1PAgIx3Ck&libraries=places&callback=initMap';    

// document.head.appendChild(script);
 
       
let map, trackers = {};

function initMap() {

   

    let mapOptions = {
        // zoom: 8,
        zoom: 7,
        center: new google.maps.LatLng(5.5697816512578, -0.21317566815868),
        mapTypeId: google.maps.MapTypeId.ROADMAP
    // 
      };
     map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions)

}





// <LoadScript  googleMapsApiKey='AIzaSyD4pU3iPmaZW0mu5Sfu91Ar8x1PAgIx3Ck' />
//initMap();


// Lat_Long_All();
// function initialize() {
    const initialize = async () => {

        document.getElementById("hidediv").style.display = 'inline';
        

    initMap();
    // var infowindow = new google.maps.InfoWindow();
    var map = new google.maps.Map(
        document.getElementById("map_canvas"), {
        //Ghana           //   center: new google.maps.LatLng(7.9465, -1.0232),

        center: new google.maps.LatLng(5.5697816512578, -0.21317566815868),

        zoom: 10,
        // mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    // Initialize Firebase


    firebases.database().ref("akwaaba/drivers/").on("child_added", (snap) => {
        //  // // alert(12374);
        // var totalRecord =  snap.numChildren();
        //  console.log("The new key is =" + snap.key);

        document.getElementById("hidediv").style.display = 'none';

        var datas = localStorage.getItem("dkey");
        var keyid = snap.key;
        var lat;
        var long;
        var icons;
        var uluru;
        var marker = null;
        var chkcolor;
        var markersArray = [];
        var chkbooking;
        var chkcustomer;
        var bounds;
        const drivers_details = "";
        //   //console.log(snapshot.val());
        var icon1 = {
            // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#40ff00", //<-- Car Color, Green
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            // rotation: snapshot.val().angle  //<-- Car angle
        };


        var icon2 = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#ffff00", //<-- Car Color, Yellow
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            //   rotation: snapshot.val().angle  //<-- Car angle
        };



        var icon3 = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#0000FF", //<-- Car Color, blue
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            // rotation: snapshot.val().angle  //<-- Car angle
        };


        var icon4 = { // car icon
            path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
            scale: 0.7,
            fillColor: "#ff00bf", //<-- Car Color, Pink
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            //    rotation: snapshot.val().angle  //<-- Car angle
        };


        var icon0 = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#808080", //<-- Car Color, Cream
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5),
            // rotation: snapshot.val().angle //<-- Car angle
        };


        var iconoffline = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#000000", //<-- Car Color, IndiaRed
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5),
            // rotation: snapshot.val().angle  //<-- Car angle
        };






        var locationsRef = firebases.database().ref("akwaaba/drivers/" + keyid + "/lastLocation").orderByValue();
        bounds = new google.maps.LatLngBounds();
        locationsRef.on("value", function (snapss) {

            lat = snapss.child("Lat").val();
            long = snapss.child("lng").val();

            //   data = snapss.val();
            uluru = { lat: parseFloat(lat), lng: parseFloat(long) };


            chkcolor = "";
        })

        //   var locationsRef = firebase.database().ref("akwaaba/drivers/currentLocation/" +  keyid ).orderByChild("l");
        var locationsRef = firebases.database().ref("akwaaba/driversCurrentLocation/" + keyid + "/l").orderByValue();
        //var bounds = new google.maps.LatLngBounds();
        locationsRef.on("value", function (snapshot) {

            if (snapshot.child(0).val() == null) {
                var locationsRef = firebases.database().ref("akwaaba/drivers/" + keyid + "/lastLocation").orderByValue();
                //    var bounds = new google.maps.LatLngBounds();
                locationsRef.on("value", function (snapss) {


                    if (snapss.child("Lat").val() != null) {
                        //console.log("Get new lonitude  Lat " + snapss.child("Lat").val() + " and Longitude=" + snapss.child("lng").val());
                        lat = snapss.child("Lat").val();
                        long = snapss.child("lng").val();
                        //     data = snapss.val();
                        uluru = { lat: parseFloat(lat), lng: parseFloat(long) };


                    }

                })
                icons = iconoffline;

                ///*

                var title = 'The Driver 1';
                marker = new google.maps.Marker({
                    position: uluru,
                    map: map,
                    //  draggable:false,
                    icon: icons,
                    title: title

                });

                // */
            } else {

                //trips_booked_manual_app_links
                chkcolor = snap.val().newTrip;
                lat = snapshot.child(0).val();
                long = snapshot.child(1).val();
                // data = snapshot.val();
                uluru = { lat: parseFloat(lat), lng: parseFloat(long) };

                if (snap.val().newTrip == "waiting") {
                    // //console.log(snap.val().newTrip);
                    icons = "";
                    icons = icon1;


                } else if (snap.val().newTrip == "accepted") {
                    //  //console.log( snap.key);
                    icons = "";
                    icons = icon2;

                } else if (snap.val().newTrip == "arrived") {
                    // //console.log( snap.key);
                    icons = "";
                    icons = icon3;
                } else {
                    icons = "";

                    icons = icon4;
                }


                // firebases.database().ref("akwaaba/driversCurrentLocation").on('child_changed', function (data) {
                //  // 
                //    console.log("The Data Key Change " + data.key)
                //     marker[data.key].setMap(null);
                //     // // alert(53);
                // });

                firebases.database().ref("akwaaba/driversCurrentLocation").on('child_changed', function (data) {
                    console.log("The Data Key Change " + data.key)
                    marker.setMap(null);
                    //marker.push(icons);
                    for (var i = 0; i < 10000000; i++) {
                        markersArray[i].setMap(null);
                    }
                    markersArray.length = 0;
                });

                var title = 'Intro 2';
                marker = new google.maps.Marker({
                    position: uluru,
                    map: map,
                    //  draggable:false,
                    icon: icons,
                    title: title

                });
            }




            bounds.extend(marker.getPosition());
            marker.addListener('click', (function (data) {
                return function (e) {
                    //$('#exampleModal').modal('show');
                    // Get the modal
                    //  var modal = document.getElementById("exampleModal");

                    // Get the button that opens the modal
                    //  var btn = document.getElementById("myBtn");
                    //   modal.style.display = "block";
                    //      // alert('click' );
                    //  
                    //searchdriver(keyid, chkcolor);
                    // searchbooking(keyid);

                }
                // }(data)));
            }));


            marker.addListener("click", () => {
                searchdriver(keyid);
              //  searchbooking(keyid);
 
               document.getElementById("myModal").style.display = 'block';
              // openModal();
              

            });


            function openModal() {



             //   // alert(keyid);

             
                
             searchdriver(keyid, chkcolor);
             searchbooking(keyid);


                var modal = document.getElementById("myModal");
                modal.style.display = "block";
                var span = document.querySelector("btn-close");
                span.onclick = function () {
                    modal.style.display = "none";
                }
                window.onclick = function (event) {
                    if (event.target == modal) {
                        modal.style.display = "none";
                    }
                }


                var element = document.getElementsById("myModal");
                var i;
                if (element && element.length > 0) {
                    for (i = 0; i < element.length; i++) {
                        element[i].map.remove();
                    }
                }



            }


            map.fitBounds(bounds);
            google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
                this.setZoom(map.getZoom() - 1);

                if (this.getZoom() > 13) {
                    this.setZoom(13);
                }
            });
            map.fitBounds(bounds);
        })

        // })

        function searchdriver(keyid ) {
            console.log(keyid + chkcolor);
           // document.getElementsById('myimg2').src = 'ab.png';
            firebases.database().ref('akwaaba/drivers/').orderByChild("driverId")
            .equalTo(keyid).on("child_added", function (datadriver) {
              //  var x = document.getElementById("myDIV");
               // console.log("thisssssssss 333333" + chkcolor + datadriver.val().phone);
                // if (chkcolor == "waiting" || chkcolor == "" || chkcolor == null) {
                //     x.style.display = "none";
                // } else {
        
                //     x.style.display = "block";
                // }
        
        
                document.getElementById("Drivernamephone").value = "";
                document.getElementById("Driverphone").value = "";
        
                document.getElementById("Drivernamephone").value = datadriver.val().firstName + " " + datadriver.val().lastName;
                document.getElementById("Driverphone").value = datadriver.val().phone;
        
                //document.getElementById("myimg2").src = driverimgs; 
                //document.getElementById("myimg2").src = data.val().nationalIDFrontPhotoURL;
                if (datadriver.val().profilePhoto == null) {
        
                 //   $('#myimg2').attr('src', 'images/xyz1.png');
                    //document.getElementById("myimg2").src ="ab.png"
                //    document.getElementsById('myimg2').src = 'ab.png';
        
                } else {
        
                   // document.getElementById("myimg2").src = data.val().profilePhoto;
                 //  document.getElementsById('myimg2').src = 'ab.png';
                }
        
                //document.getElementById("myimg2").src = data.val().driversLicenseBackPhotoURL; 
            })
        }
        

    });

}



export { initialize };







//;
///*
function searchcustomers(keyid) {
    //
    ////// alertchkcustomer);
    firebases.database().ref('akwaaba/bookings/').orderByChild("driverId").equalTo(keyid).on("child_added", function (data) {
        //document.getElementById("customername").value =data.val().Customerfname + " " + data.val().Customerlname ;
        //console.log("distanc " + data.val().estimated_distance);
        //console.log("status " + data.val().status);
        //console.log("Trip " + data.val().status);


        document.getElementById("customername").value = data.val().rider_name;


        document.getElementById("customerphone").value = data.val().rider_phone;

        document.getElementById("bookingdistances").value = data.val().estimated_distance;
        document.getElementById("bookingstatuss").value = data.val().status;
        document.getElementById("tripstatuss").value = data.val().status;


    })
}
//*/


function searchbooking(keyid) {
    // //// alertchkbookingbydriver);
    //MRzKKZ51UpgYDiaPRuz
    ////// alertkeyid);
    firebases.database().ref('akwaaba/bookings/').orderByChild("driverId")
        .equalTo(keyid).on("child_added", function (data) {

            /*
var bookstat;
if( data.val().driverMessage==null){
bookstat="NA"
}else{
bookstat= data.val().driverMessage;
} ;
*/

            var bookstatcost;
            if (data.val().estimated_fare == null) {
                bookstatcost = "NA"
            } else {
                bookstatcost = data.val().estimated_fare;
            };

            if ((data.val().driverId != null) && (data.val().status == "waitings" || data.val().status == "completeds")) {

                //if( (data.val().driverId !=null ) &&  data.val().status =="completeds"  ){
                document.getElementById("BookingCost").value = bookstatcost;
                document.getElementById("BookingDate").value = data.val().created_at;
                //document.getElementById("BookingDistance").value = data.val().BookingDistance; 

                document.getElementById("Bookingduration").value = data.val().estimated_duration;
                document.getElementById("currentAddress").value = data.val().pickup_address;
                document.getElementById("dropAddress").value = data.val().destination_address;

                /// customer info

                //searchcustomers(keyid);

                document.getElementById("customername").value = data.val().rider_name;


                document.getElementById("customerphone").value = data.val().rider_phone;

                document.getElementById("bookingdistances").value = data.val().estimated_distance;
                document.getElementById("bookingstatuss").value = data.val().status;
                document.getElementById("tripstatuss").value = data.val().status;

                ///  
            }
        },
            function (error) {
                //console.log("Error: " + error);
            });


}



