import React, { useState, useEffect } from 'react';
//import './driverstyle.css';
//import './driverstyle.css';


import {searchimg, searchs, DriverVehicleRegisterUpdate,DriverVehicleRegisterwithcarUpdate } from "./drivervehcleonline_fbregisterUpdate";
import Swal from 'sweetalert2'
 
import dlogo from '../ab.png';
import dscar from '../ds.png';

import $ from "jquery";
import './Tablestyle.css';
const DriverRegFormUpdate = () => {

// Get All Driver Details here Here
 
useEffect(() => {    
    var data = localStorage.getItem("driveredits");
    var datas = localStorage.getItem("driveredits");
 
   // alert(data);
   document.getElementById("hidediv").style.display = 'inline';
   searchimg(data);
   searchs(data);
  })

  useEffect(() => {    
    var data = localStorage.getItem("driveredits");
    var datas = localStorage.getItem("driveredits");

   // alert(data);
  })












    // update here
    const [showText, setShowText] = useState(false);

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
     const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = useState("");

    const [image, setImage] = useState(null);
    const [imagecar, setImagecar] = useState(null);
    const [PhoneNumber, setPhoneNumber] = useState("");

    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Address, setAddress] = useState("");
    const [Gender, setGender] = useState("");

    // const [Password, setPPassword] = useState("");
    const [emergencyContact, setemergencyContact] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");




    const [Licence, setLicenceRegNumber] = useState("");
    const [LicencePlateNumberexpiringdate, setLicencePlateNumberexpiringdate] = useState("");
    const [SeatCapacity, setSeatCapacity] = useState("");
    const [vehicleaddress, setVehicleaddress] = useState("");
    const [roadworthinessexpiringdate, setRoadworthinessexpiringdate] = useState("");
    const [insuranceRenewaldate, setInsuranceRenewaldate] = useState("");

    const [roadworthinessfront, setRoadworthinessfront] = useState(null);
    const [insurancepolicyfront, setInsurancepolicyfront] = useState(null);
    const [drivinglincencefront, setDrivinglincencefront] = useState(null);
    // const [proveidtypefront, setProveidtypefront] = useState(null);
    const [imagecarfile, setImagecarfile] = useState(null);
    const [imageperson, setImageperson] = useState(null);
    




    useEffect(() => {
        const date = new Date();

        // 👇️
    document.getElementById('datecreated').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
            
     document.getElementById('datecreatedd').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
         

})




    const uploadImage =  (e) => {
        //  const files = e.target.files
       
        document.getElementById('previewImg').removeAttribute('src');
        try {
            //  setFile(e.target.files[0]);
             //   var file = document.querySelector('input[type=file]')['files'][0];
 
  
             if (e.target.files && e.target.files[0]) {
                 setImage(URL.createObjectURL(e.target.files[0]));
             } else {
                 setImage(dlogo);
             }
 
         } catch {
             setImage(dlogo);
         }


    }
    // const profile_pics= document.querySelector("#profile_pics");

     
    // profile_pics.onchange = evt => {
    //     var previewImg=document.querySelector("#previewImg");
    //     const [file] = profile_pics.files
    //     if (file) {
    //         previewImg.src = URL.createObjectURL(file)
    //     }
    //   }
    useEffect(() => {
    $("#profile_pics").change(function () {
        const file = this.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = function (event) {
                $("#previewImg")
                  .attr("src", event.target.result);
            };
            reader.readAsDataURL(file);
        }
    });
    })
    const uploadImagecar = async e => {
        //  const files = e.target.files
        try {
           //  setFile(e.target.files[0]);
            //   var file = document.querySelector('input[type=file]')['files'][0];
 
            if (e.target.files && e.target.files[0]) {
                setImagecar(URL.createObjectURL(e.target.files[0]));
            } else {
                setImagecar(dlogo);
            }

        } catch {
            setImagecar(dlogo);
        }


    }




    const handleSubmitwithnocarUpdate = (e) => {
        e.preventDefault();
 
    // var driveraddress = document.getElementById("driveraddress").value;
   // var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;


    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
        || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {
          //  alert("Please enter name / password")
         
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="#">Error “Please fill out all required fields.</a>'
          })
          //    setLoading(true);
          //  setShowText(true);
        }
        else {
          
           // alert("Please update")
           
          
          try {
         //   DriverVehicleRegister();

           DriverVehicleRegisterUpdate();
         
     
          }
          catch (error) {
         //   setShowText(true);
          //  setLoading(false);
          }
    
    
        }
      };
    





      
    const handleSubmitwith_carUpdates = async (e) => {
        e.preventDefault();

 

    // var driveraddress = document.getElementById("driveraddress").value;
    //var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;


    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
          || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {


         //   alert("Please enter name / password")

         const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
          })
          await Toast.fire({
            icon: 'error',
            title: 'Something went wrong! ',
           // text: 'Something went wrong!',
            footer: '<a href="#" style="color:white;font-weight:bold">Error “Please 1 fill out all required fields.</a>'
          })
 
             //  setShowText(true); 
             // || !roadworthinessfront ||  !insurancepolicyfront || !drivinglincencefront || !proveidtypefront  ||
        }
        else {

          //  
         // alert("Please 123 ok")
 
         DriverVehicleRegisterwithcarUpdate();
          //  DriverVehicleRegisterwithcar();

            

          
          try {

           // DriverVehicleRegister();
         
     
          }
          catch (error) {
         //   setShowText(true);
          //  setLoading(false);
          }
    
    
        }
      };
    







   
 


    return (
        <>



                <div class="hidediv" id="hidediv">
                <div class="loading"></div>
                </div>
                   

                <div id="loaderid" >
                    <span class="loaderspage"></span>
                 </div>
                   


            <div class="content-wrapper">
                <div class="col-12 grid-margin">
                    <div class="card">
                        <div class="card-body">


                            <h4 class="card-title">Drivers Registration Update</h4>

                            <p class="card-description">
                                Personal info
                            </p>



                            <div class="stepwizard" >
                                <div class="stepwizard-row setup-panel">
                                    <div class="stepwizard-step col-xs-3">
                                        <a href="#step-1" type="button" class="btn btn-success btn-circle">  <i class="chk">1</i></a>
                                        <p><small>Step 1</small></p>
                                    </div>
                                    <div class="stepwizard-step col-xs-3">
                                        <a href="#step-2" type="button" class="btn btn-default btn-circle"  ><i class="chk">2</i></a>
                                        <p><small>Step 2</small></p>
                                    </div>
                                    <div class="stepwizard-step col-xs-3">
                                        <a href="#step-3" type="button" class="btn btn-default btn-circle"  ><i class="chk">3 </i></a>
                                        <p><small>Step 3</small></p>
                                    </div>
                                    <div class="stepwizard-step col-xs-3">
                                        <a href="#step-4" type="button" class="btn btn-default btn-circle"  ><i class="chk">4</i></a>
                                        <p><small>Finish</small></p>
                                    </div>

                                    {/* <div class="stepwizard-step col-xs-3"> 
                <a href="#step-5" type="button" class="btn btn-default btn-circle"  ><i class="chk">5</i></a>
                <p><small>Finish</small></p>
            </div> */}
                                </div>
                            </div>





                            <form onSubmit={e => { e.preventDefault(); }}  id="formpagereset">

                                <div class="panel panel-primary setup-content" id="step-1">

                                    <div class="panel-body">







                                        <div class="row">
                                             <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class=" col-form-label">Upload Image here</label>

                                                    <img width="150" height="150" id="previewImg" 
                                                     src={image || dlogo}
                                                        class="viewimage img-responsive rounded-circle center-block d-block mx-auto"
                                                        onError={(e) => { e.target.onerror = null; e.target.src = dlogo }} />
                                                    <input type="file" name="profile_pics" id="profile_pics"
                                                       //  onChange={uploadImage} 
                                                         // value ={""}
                                                        
                                                        //accept="image/*"
                                                      //  value={imageperson}
                                             //   onChange={ (e) => {  setImageperson(e.target.value) } } 
                                                    />

                                                    {/* <input type="hidden" name="memberimg" id="memberimg" value={memberimg}
                                                onChange={(e) => { setMemberimg(e.target.value) }} /> */}
                                                </div>

                                            </div>

                                        </div>




                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">First Name</label>
                                                    <div class="col-sm-9">
                                                        {/* <input type="text" placeholder="First Name" class="form-control shadow-sm"
                                                            id="driver_first_name" required /> */}

                                                        <input class="form-control"  name="driver_first_name" placeholder="First Name" required
                                                            type="text" pattern="[A-Za-z0-9-. _]+" id="driver_first_name"
                                         title="letters and numbers only, no punctuation or special characters or Only letters (either case),
                               numbers, hyphens, underscores, and periods"
                                                            // value={FirstName}
                                                            // onChange={(e) => { setFirstName(e.target.value) }}
                                                        />



                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" placeholder="Last Name" class="form-control shadow-sm"
                                                            id="driver_last_name"
                                                            required="required"
                                                            title="letters and numbers only, no punctuation or special characters or Only letters (either case),
                                                            numbers, hyphens, underscores, and periods"

                                                            // value={LastName}
                                                            // onChange={(e) => { setLastName(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Email</label>
                                                    <div class="col-sm-9">
                                                        <input type="email"  required readOnly
                                                        placeholder="Email"  class="form-control shadow-md"
                                                            id="driver_email" name="driver_email"



                                                            // value={EmailAddress}
                                                            // onChange={(e) => { setEmailAddress(e.target.value) }}
                                                        />
                                                        <b id='result'></b>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Phone Number</label>
                                                    <div class="col-md-9">
                                                        <input type="number" pattern="-?[0-9]*(\.[0-9]+)?"
                                                            placeholder="Phone Number" class="form-control shadow-sm"
                                                            id="driver_phone"
                                                            minlength="10"   maxlength="25" 
                                                            title="Enter  at least 10 letters long"
                                                            required="required"
                                                            // value={PhoneNumber}
                                                            // onChange={(e) => { setPhoneNumber(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3  ">Address</label>
                                                    <div class="col-sm-9">

                                                      <input type="text" class="form-control"  id="driveraddress" 
                                                            placeholder="Address"
                                                            value={Address}
                                                            required="required"
                                                            onChange={(e) => { setAddress(e.target.value) }}
                                                        />
 
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-6 hideall">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Password</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control shadow-sm" id="driver_password"
                                                            placeholder="Password"
                                                            minlength="8"   maxlength="15" 
                                                             title="Enter  at least 8 letters long"
                                                             
                                                            // value={Password}
                                                            // onChange={(e) => { setPPassword(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

{/* 
                                        </div>

                                        <div class="row"> */}


                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">  Emergency Contact</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" pattern="-?[0-9]*(\.[0-9]+)?"
                                                            class="form-control shadow-sm" id="emergencyContact"
                                                            placeholder="Emergency Contact"
                                                            minlength="10"   maxlength="25" 
                                                            title="Enter  at least 10 letters long"
                                                            required="required"
                                                            // value={emergencyContact}
                                                            // onChange={(e) => { setemergencyContact(e.target.value) }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>





                                        {/*   disabled={!FirstName || !LastName   || !PhoneNumber }  */}


                                        <input id="Next"
                                            class="btn btn-primary nextBtn pull-right float-md-right"
                                            type="submit"
                                          //  disabled={!FirstName || !LastName || !PhoneNumber}
                                            value={"Next"} />




                                    </div>
                                </div>



                                {/* 
step two start */}
                                <div class="panel panel-primary setup-content " id="step-2">
                                    <div class="panel-body">


                                        <div class="col-md-12">
                                            <span> <b> * Driving Licence </b> &nbsp;&nbsp; </span>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Front </label>
                                                    <div class="col-sm-9">
                                                        <input type="file" id="drivinglincencefront" 
                                                        class="form-control shadow-sm"  
                                                        
                                                        // value={drivinglincencefront}
                                                        // onChange={(e) => { setDrivinglincencefront(e.target.value) }}

                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Back </label>
                                                    <div class="col-sm-9">
                                                        <input type="file" 
                                                        class="form-control shadow-sm" id="drivinglincenceback" />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class=" col-form-label">Expiring  date</label>
                                                    <div class="col-sm-8">
                                                        <input type="date" 
                                                        min="2022-12-02" id="drivinglincenceexpiringdate"
                                                            placeholder="Expiring  date" 
                                                            class="form-control shadow-sm" required
                                                            
                                                            />

                                                    </div>
                                                </div>
                                            </div>
                                            <p>.</p>
                                        </div>



                                        <div class="row">
                                            <div class="col-sm-12">
                                                <span><b> Prove of Your ID Type </b> &nbsp;&nbsp; </span>

                                                <label for="ProveidtypechkNo">
                                                    <input type="radio" value="Ghana Card " id="proveidtypechkNo" name="proveidtypechkcar" checked />
                                                    Ghana Card  &nbsp;&nbsp;
                                                </label>

                                                <label for="ProveidtypechkYes" class="hideall">
                                                    <input type="radio" id="proveidtypechkYes" value="Passport" name="proveidtypechkcar" />
                                                    &nbsp;&nbsp; Passport
                                                </label>

                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Front </label>
                                                    <div class="col-sm-9">
                                                        <input type="file" id="proveidtypefront" 
                                                        class="form-control shadow-sm"  
                                                        
                                                        // value={proveidtypefront}
                                                        // onChange={(e) => { setProveidtypefront(e.target.value) }}

                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Back </label>
                                                    <div class="col-sm-9">
                                                        <input type="file" 
                                                        class="form-control shadow-sm" id="proveidtypeback" />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class=" col-form-label">Expiring  date</label>
                                                    <div class="col-sm-8">
                                             <input type="date" min="2022-12-02" id="proveidtypeexpiringdate"
                                              placeholder="Expiring  date" class="form-control shadow-sm"
                                              required
                                              />
                                               
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <p>.</p>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <span>Do you own a car? &nbsp;&nbsp; </span>



                                                <label for="chkYes">
                                                    <input type="radio" id="driverwithcarchkYes" value="Yes" name="doyouownchkcar"
                                                        defaultChecked />
                                                    Yes   &nbsp;&nbsp;
                                                </label>


                                                <label for="chkNo">
                                                    <input type="radio" id="driverwithcarchkNos" value="No"
                                                        name="doyouownchkcar" />
                                                    &nbsp;    No



                                                </label>

                                                <hr />


                                            </div>
                                        </div>







                                        <div class="driverwithnocardivbox" id='driverwithnocardivbox'>

                                            {/* <input class="btn btn-success  pull-right float-md-right"
                                                disabled={!FirstName || !LastName || !PhoneNumber}
                                                id="Submits" type="submit" value={"Register Now!"}
                                            /> */}
                                            
                                            <button type="submit" form="form1" value="Submit" 
                                            // disabled={!LastName || !FirstName  || !Address || 
                                            // !Address || !emergencyContact || !Password || !PhoneNumber }
                                            class="btn btn-success pull-right float-md-right"
                                            onClick={handleSubmitwithnocarUpdate} >Submit</button>


                                        </div>



                                        <div class="Nodriverwithnocardivbox" id='Nodriverwithnocardivbox'>

                                            <input class="btn btn-primary nextBtn pull-right float-md-right"
                                              //  disabled={!FirstName || !LastName || !PhoneNumber}
                                                //  disabled={!FirstName || !LastName || !Gender || !PhoneNumber } 
                                                type="submit"
                                                value={"Next"} />
                                        </div>
 
                                    </div>
                                </div>
                                {/* step 2 end */}





                                {/* step 3 star */}

                                <div class="panel panel-primary setup-content " id="step-3">
                                    <div class="panel-body">
                                        <h3 class="card-title" id="izplaysd">Vehicle Registration</h3>



                                        <div class="row">
                                      


                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Make / Type </label>
                                                    <div class="input-group">
                                                        <select name="carManufacturer" 
                                                        id="carManufacturer" class="form-control shadow-sm" >
                                                            <option value="none" selected disabled hidden>
                                                                Select Make / Type
                                                            </option>

                                                            <option>Audi</option>
                                                            <option>BMW</option>
                                                            <option>Buick</option>
                                                            <option>Cadillac</option>
                                                            <option>Chevrolet</option>
                                                            <option>Chrysler</option>
                                                            <option>Dodge</option>
                                                            <option>Ferrari</option>
                                                            <option>Ford</option>
                                                            <option>GM</option>
                                                            <option>GEM</option>
                                                            <option>GMC</option>
                                                            <option>Honda</option>
                                                            <option>Hummer</option>
                                                            <option>Hyundai</option>
                                                            <option>Infiniti</option>
                                                            <option>Isuzu</option>
                                                            <option>Jaguar</option>
                                                            <option>Jeep</option>
                                                            <option>Kia</option>
                                                            <option>Lamborghini</option>
                                                            <option>Land Rover</option>
                                                            <option>Lexus</option>
                                                            <option>Lincoln</option>
                                                            <option>Lotus</option>
                                                            <option>Mazda</option>
                                                            <option> Mercedes-Benz</option>
                                                            <option>Mercury</option>
                                                            <option>Mitsubishi</option>
                                                            <option>Nissan</option>
                                                            <option>Oldsmobile</option>

                                                            <option>Opel/Vauxhall</option>
                                                            <option>Peugeot</option>
                                                            <option>Pontiac</option>
                                                            <option>Porsche</option>
                                                            <option>Regal</option>
                                                            <option>Saab</option>
                                                            <option>Saturn</option>
                                                            <option>Subaru</option>
                                                            <option>Suzuki</option>
                                                            <option>Toyota</option>
                                                            <option>Volkswagen</option>
                                                            <option>Volvo</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Vehicle Model</label>
                                                    <div class="input-group">
                                                        <input class="form-control shadow-sm"
                                                         placeholder="Model" type="text" id="vehiclemodel" />

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Vehicle Type</label>
                                                    <div class="input-group">
                                                        <select name="carType" id="carType" class="form-control shadow-sm">
                                                            <option value="none" selected disabled hidden>
                                                                Select vehicle Type
                                                            </option>
                                                            <option value="Saloon">Car (Saloon)</option>
                                                            <option value="Estate">Estate</option>
                                                            <option value="Minibus">Minibus</option>
                                                            <option value="Executive">Executive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                        <div class="row">

                                           <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Vehicle registration number .</label>
                                                      <div class="input-group">
                                                        <input placeholder="Vehicle  registration number"
                                                            class="form-control shadow-smt"
                                                             type="text" id="vehicleRegistrationNumber"
                                                            
                                                            // value={vehicleRegistrationNumber}
                                                            // onChange={(e) => { setVehicleRegistrationNumber(e.target.value) }}
                                                            
                                                            />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4 hideall">
                                                <div class="form-group">
                                                <label>Lincence Plate Number</label>
                                                <div class="input-group">
                                                <input    placeholder="Lincence Plate Number" 
                                                class="form-control shadow-smt" type="text" id="licensePlateNumber" />
                                                    
                                                </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4 hideall">
                                                <div class="form-group">
                                                    <label>Licence Plate Expiring  date</label>
                                                    <div class="input-group">
                                                         <input type="date"
                                                             min="2022-12-02"
                                                             id="licensePlateNumberexpiringdate"
                                                             placeholder="Expiring  date"
                                                             class="form-control shadow-md" 
                                                            //  value={LicencePlateNumberexpiringdate}
                                                            //  onChange={(e) => { setLicencePlateNumberexpiringdate(e.target.value) }}

                                                       />

                                                    </div>
                                                </div>
                                            </div>





                                         

                                        <div class="col-md-4 hideall">
                                                <div class="form-group">
                                                    <label>Seat Capacity</label>
                                                    <div class="input-group">
                                                        <input title="Seating Capacity"
                                                            class="form-control shadow-sm"
                                                             type="number" placeholder="Seating Capacity"
                                                            pattern="-?[0-9]*(\.[0-9]+)?" id="SeatCapacity"
                                                            
                                                            // value={SeatCapacity}
                                                            // onChange={(e) => { setSeatCapacity(e.target.value) }}
                                                           
                                                            />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Vehicle Year</label>
                                                    <div class="input-group">
                                                        <select placeholder="Year" class="form-control" id="carYear"  >
                                                            <option value="none" selected disabled hidden>
                                                                Select manufacturing year
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Vehicle Color</label>
                                                    <div class="input-group">
                                                        <select placeholder="vehicle Colour"
                                                         class="form-control" id="carColor" required>
                                                            <option value="none" selected disabled hidden>
                                                                Select Vehicle  Colour
                                                            </option>

                                                            <option value="Red">Red</option>
                                                            <option value="Green">Green</option>
                                                            <option value="Blue">Blue</option>
                                                            <option value="Grey">Grey</option>
                                                            <option value="Brown">Brown</option>
                                                            <option value="Yellow">Yellow</option>
                                                            <option value="Orange">orange</option>
                                                            <option value="Black">Black</option>
                                                            <option value="White">White</option>
                                                            <option value="Aliceblue">aliceblue</option>

                                                            <option value="Pink">pink</option>
                                                            <option value="Purple">purple</option>
                                                            <option value="Lightpink">lightpink</option>

                                                            <option value="Skyblue">skyblue</option>
                                                            <option value="Whitesmoke">whitesmoke</option>
                                                            <option value="Yellowgreen">yellowgreen</option>

                                                            <option value="Chocolate">Chocolate</option>
                                                            <option value="Aqua">Aqua</option>
                                                            <option value="Fuchsia">Fuchsia</option>
                                                            <option value="Lime">Lime</option>
                                                            <option value="Maroon">Maroon</option>

                                                            <option value="Navy">Navy</option>
                                                            <option value="Olive">Olive</option>
                                                            <option value="Purple">Purple</option>
                                                            <option value="Silver">Silver</option>


                                                            <option value="Teal">Teal</option>
                                                            <option value="Burlywood">Burlywood</option>
                                                            <option value="Cadetblue">Cadetblue</option>
                                                            <option value="Chartreuse">Chartreuse</option>

                                                            <option value="Coral">Coral</option>
                                                            <option value="Cornflowerblue">Cornflowerblue</option>
                                                            <option value="Cornsilk">Cornsilk</option>

                                                            <option value="Darkgreen">Darkgreen</option>
                                                            <option value="Violet">Violet</option>
                                                            <option value="Lightgreen">Lightgreen</option>
                                                            <option value="Floralwhite">Floralwhite</option>
                                                            <option value="Ivory">Ivory</option>

                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Full Address</label>
                                                    <div class="input-group">

                                                        <input class="form-control"
                                                            type="text" title="Valid Addresss Required"
                                                            id="vehicleaddress"
                                                            placeholder="Vehicle Address"

                                                            
                                                            // value={vehicleaddress}
                                                            // onChange={(e) => { setVehicleaddress(e.target.value) }}


                                                        />
                                                    </div>
                                                </div>
                                            </div>



                                        </div>






                                        <div class="row">


 

                                        </div>




                                        <hr />




                                        <div class="row">





                                            <div class="col-md-12">
                                                <span>Wheelchair accessible Vehicle ? &nbsp;&nbsp; </span>

                                                <label for="chkNo">
                                                    <input type="radio" value="No" id="disabilitychkNo" name="disabilitychkcar" checked />
                                                    No  &nbsp;&nbsp;
                                                </label>


                                                <label for="chkYes">
                                                    <input type="radio" value="Yes" id="disabilitychkYes" name="disabilitychkcar" />
                                                    &nbsp;&nbsp; Yes
                                                </label>
                                                <hr />


                                            </div>
                                        </div>


 


                                        <input class="btn btn-primary nextBtn pull-right float-md-right" type="submit"
                                            value={"Next"} />


                                    </div>
                                </div>



                                <div class="panel panel-primary setup-content" id="step-4">
                                    <div class="panel-body">


 


                                        <div class="row" style={{ display: "none" }}>
                                            <div class="col-md-12">
                                                <span> <b> *    Hired / Lease agreement or proved of ownership
                                                </b> &nbsp;&nbsp; </span>

                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Front</label>
                                                    <div class="col-sm-9">
                                                        <input type="file" id="ownershipfront" class="form-control shadow-sm" />

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Back</label>
                                                    <div class="col-sm-9">
                                                        <input type="file" class="form-control shadow-sm" id="ownershipback" />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class=" col-form-label">Expiring  date</label>
                                                    <div class="col-sm-8">
                                                        <input type="date" min="2022-12-02" id="ownershipexpiringdate"
                                                            placeholder="Expiring  date" class="form-control shadow-sm" />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                        <div class="row">
                                            <div class="col-md-12">
                                                <span> <b> *     Insurance Certificate
                                                </b> &nbsp;&nbsp; </span>

                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Front</label>
                                                    <div class="col-sm-9">
                         <input type="file" id="insurancepolicyfront" class="form-control shadow-sm"
                                   
                                //    value={insurancepolicyfront}
                                //     onChange={(e) => { setInsurancepolicyfront(e.target.value)  }}
                                                        
                                                 
                                                 
                                                 />

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Back</label>
                                                    <div class="col-sm-9">
                                                        <input type="file" class="form-control shadow-sm" id="insurancepolicyback" 
                                                        
                                                        
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class=" col-form-label">Expiring  date</label>
                                                    <div class="col-sm-8">
                                                        <input type="date" min="2022-12-02" id="insuranceRenewaldate"
                                                            placeholder="Expiring  date" class="form-control shadow-sm"
                                                            
                                                            // value={insuranceRenewaldate}
                                                            // onChange={(e) => { setInsuranceRenewaldate(e.target.value) }}


                                                            
                                                            />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div class="row"  >.</div>


                                        <div class="row"  >
                                            <div class="col-md-12">
                                                <span> <b> *   Roadworthiness Certificate sticker
                                                </b> &nbsp;&nbsp; </span>

                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Front</label>
                                                    <div class="col-sm-9">
                                                        <input type="file" id="roadworthinessfront" class="form-control shadow-sm" 
                                                        

                                                        // value={roadworthinessfront}
                                                        // onChange={(e) => { setRoadworthinessfront(e.target.value) }}

                                                        />

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Upload Back</label>
                                                    <div class="col-sm-9">
                                                        <input type="file" class="form-control shadow-sm" id="roadworthinessback" />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group row">
                                                    <label class=" col-form-label">Expiring  date .</label>
                                                    <div class="col-sm-8">
                                                        <input type="date" min="2022-12-02"
                                                            id="roadworthinessexpiringdate"
                                                            placeholder="Expiring  date" class="form-control shadow-sm"
                                                            

                                                            // value={roadworthinessexpiringdate}
                                                            // onChange={(e) => { setRoadworthinessexpiringdate(e.target.value) }}

                                                            />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>





                                        <div class="row">


                                            <label class="  col-form-label">Upload Vehicle Image </label>


                                            <div class="col-md-4">
                                                <div class="form-group">

                                                    <img width="150" height="150" id="VehiclepreviewImg" src={imagecar || dscar}
                                                        class="img-responsive rounded-circle center-block d-block mx-auto"
                                                        onError={(e) => { e.target.onerror = null; e.target.src = dscar }} />
                                                    <input type="file"  
                                                    // accept="image/*"
                                                    name="photosimagecar" id="photosimagecar"
                                                     //
                                                       onChange={uploadImagecar}  

                                                //         value={imagecarfile}
                                                // onChange={(e) => {  setImagecarfile(e.target.value) }}
                                                    
                                                    />

                                                    {/* <input type="hidden" name="memberimg" id="memberimg" value={memberimg}
                                                onChange={(e) => { setMemberimg(e.target.value) }} /> */}
                                                </div>

                                            </div>










                                            <div class="col-md-8">
                                            </div>
                                        </div>



                                        <input class="btn btn-success pull-right float-md-right"
                                            //  onClick={Insertmember}  
                                             
                                            onClick={handleSubmitwith_carUpdates} 
                                            id="Submits" type="submit" value={"Update Now!"}
                                        />

 


                  
                                    </div>
                                </div>



                         <input  id="datecreated"   type="hidden"  style={{display: "none"}}  /><br/>
                         <input  id="datecreatedd"  type="hidden"  style={{display: "none"}}  />
                         <input  id="licensePlate" value="100001"  type="hidden"  style={{display: "none"}}  />
                         <input  id="vehicleid"    type="hidden"  style={{display: "none"}}  />
                      
                         <input  id="driveridupdatecarid" 
                          class="form-control"   type="hidden"  style={{display: "none"}}         />
                         
                         <input  id="driveridupdate"    type="hidden"  style={{display: "none"}}       class="form-control"
                           />
                      
                            <progress value="0" max="100"   id="progress"   style={{display: "none"}} ></progress>

                            </form>




                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DriverRegFormUpdate