import React, { useState, useEffect,Redirect } from "react";
import { myFunctionLogout } from "../LoginUsers/LoginAuth";

import {  checktheuserLogin} from "../LoginUsers/LoginAuth";
 
import yiedzIcon from "./Asserts/icon.png";

 
function MenuSideBar() {


    const handleSubmitLogout = (e) => {
        e.preventDefault();

        // alert(1234)
       // myFunctionLogout();
    }

 

  useEffect(() => {
    //checkuserLoginWithEmailAndPasswordLogout();
    checktheuserLogin()
  });






return (
       
     <>
      
      <nav id="sidebar">
            <div class="sidebar-header">
                <h3>
                <img src={yiedzIcon} class="img-fluid"  />
                    <span>Yiedze</span></h3>
            </div>
            <ul class="list-unstyle components">
			<li  class="active">
                    <a href="/monitor" class="dashboard"><i class="material-icons">dashboard </i><span>
                     
                        Dashboard </span></a>
                </li>
		
		      <div class="small-screen navbar-display">
                <li class="dropdown d-lg-none d-md-block d-xl-none d-sm-block">
                    <a href="#homeSubmenu0" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">notifications</i><span> 4 notification</span></a>
                    <ul class="collapse list-unstyled menu" id="homeSubmenu0">
                                    <li>
                                    <a href="#">You have 5 new messages</a>
                                    </li>
                                    <li>
                                        <a href="#">You're now friend with Mike</a>
                                    </li>
                                    <li>
                                        <a href="#">Wish Mary on her birthday!</a>
                                    </li>
                                    <li>
                                        <a href="#">5 warnings in Server Console</a>
                                    </li>
                    </ul>
                </li>

                <li class="dropdown d-lg-none d-md-block d-xl-none d-sm-block">
                    <a href="#homeSubmenu0" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">notifications</i><span> 4 notification</span></a>
                    <ul class="collapse list-unstyled menu" id="homeSubmenu0">
                                    <li>
                                    <a href="#">You have 5 new messages</a>
                                    </li>
                                    <li>
                                        <a href="#">You're now friend with Mike</a>
                                    </li>
                                    <li>
                                        <a href="#">Wish Mary on her birthday!</a>
                                    </li>
                                    <li>
                                        <a href="#">5 warnings in Server Console</a>
                                    </li>
                    </ul>
                </li>
				
				<li  class="d-lg-none d-md-block d-xl-none d-sm-block">
                    <a href="#"><i class="material-icons">apps</i><span>bar_chart</span></a>
                </li>
				
				 <li  class="d-lg-none d-md-block d-xl-none d-sm-block">
                    <a href="#"><i class="material-icons">person</i><span>user</span></a>
                </li>
				
				<li  class="d-lg-none d-md-block d-xl-none d-sm-block">
                    <a href="#"><i class="material-icons">settings</i><span>setting</span></a>
                </li>
				</div>
			
			
                <li class="dropdown sidebarnavcolor">
                    <a href="#homeSubmenu1" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle  ">
					<i class="material-icons">airline_seat_recline_normal</i><span >Drivers</span></a>
                    <ul class="collapse list-unstyled menu sidebarnavcolordropdown" id="homeSubmenu1">
                        <li>
                            <a href="/driversregistration">New driver</a>
                        </li>
                        <li>
                            <a href="/driversunderpending">Pending drivers</a>
                        </li>
                        <li>
                            <a href="/driversunderactiveservice">Approved drivers</a>
                        </li>

                        <li>
                            <a href="/driversundersuspension">Suspended drivers</a>
                        </li>
                        <li>
                            <a href="/driversregistrationview">View All drivers</a>
                        </li>
                        
                        <li>
                            <a href="/assignacartoadriver">Assign driver to vehicle</a>
                        </li>

                        <li>
                            <a href="/">View Assigned Vehicles</a>
                        </li>

                        

                    </ul>
                </li>
                



                <li class="dropdown">
                    <a href="#pageSubmenu2" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">time_to_leave</i><span>Vehicles</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu2">
                    <li>
                            <a href="/vehiclesregistration">Add New vehicle</a>
                        </li>
                        <li>
                            <a href="/vehiclesunderpending">Pending vehicle's</a>
                        </li>
                        <li>
                            <a href="/vehiclesunderactiveservice">Approved vehicle's</a>
                        </li>

                        <li>
                            <a href="/vehiclesundersuspension">Suspended vehicle's</a>
                        </li>
                        <li>
                            <a href="/vehiclesregistrationview">View All vehicles</a>
                        </li>
                        
                      

                    </ul>
                </li>
				


                <li class="dropdown ">
                    <a href="#pageSubmenu33" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">lan</i><span>Assign Car-Driver</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu33">
                        <li>
                            <a href="/assignacartoadriver">Assign driver to vehicle</a>
                        </li>

                        <li>
                            <a href="/assignacartoadriverview">View Assigned Vehicles</a>
                        </li>
                    </ul>
                </li>


				 <li class="dropdown ">
                    <a href="#pageSubmenu3" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">people_outline</i><span>Customers</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu3">
                        <li>
                            <a href="/customernew">Add New Customer</a>
                        </li>
                        <li>
                            <a href="/customerdetails">View Customers</a>
                        </li>
                        <li>
                        <a href="/customerdetailspending">Pending Customers</a>
                        </li>
                        <li>
                        <a href="/customerdetailssuspended">Suspended Customers</a>
                        </li>
                        <li>
                        <a href="/customerdetailsapproved">Approved Customers</a>
                        </li>
                    </ul>
                </li>

				  <li class="dropdown">
                    <a href="#pageSubmenu4" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">app_shortcut</i><span>Bookings</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu4">
                        <li>
                            <a href="/userbookingrequest">New Bookings</a>
                        </li>
                        <li>
                            <a href="/userbookingrequestviewdedetails">All Bookings</a>
                        </li>
                        <li>
                            <a href="/userbookingrequestcancelledbydriver"> Bookings Cancelled by Driver</a>
                        </li>
                        <li>
                            <a href="/userbookingRequestcancelledbycustomer"> Bookings Cancelled by Customer</a>
                        </li>
                        <li>
                            <a href="/userbookingrequestacceptedjobs">All Booked Jobs</a>
                        </li>
                        <li>
                            <a href="/userbookingpersononboard">Person's on-Board (POB)</a>
                        </li>
                        <li>
                            <a href="/userbookingrequestofficebooking">Manual/Office Booking</a>
                        </li>
                        <li>
                            <a href="/userbookingrequestautoappbookings">Auto/App Booking</a>
                        </li>
                    </ul>
                </li>

                
				<li class="dropdown">
                    <a href="#pageSubmenu5" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">emoji_transportation</i><span>Trips</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu5">
                        <li>
                            <a href="/userbookingrequesttripcompleted">Completed Trips</a>
                        </li>
                        <li>
                            <a href="/userbookingtripspending">Pending Trips</a>
                        </li>
                        <li>
                            <a href="/userprebooked">Pre-Booked</a>
                        </li>
                    </ul>
                </li>
               
			   
			   
			   <li class="dropdown">
                    <a href="#pageSubmenu6" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">emergency_share</i><span>SOS</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu6">
                        <li>
                            <a href="/sospanicdriver">Driver</a>
                        </li>
                        <li>
                            <a href="/sospaniccustomer">Customer</a>
                        </li>
                        {/* <li>
                            <a href="#">Page 3</a>
                        </li> */}
                    </ul>
                </li>
               
			   
			     <li class="dropdown">
                    <a href="#pageSubmenu7" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">payment</i><span>Account</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu7">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li>











                <li class="dropdown sidebarnavcolor">
                    <a href="#homeSubmenu8" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle  ">
					<i class="material-icons">sentiment_very_satisfied</i><span >Permissions</span></a>
                    <ul class="collapse list-unstyled menu sidebarnavcolordropdown" id="homeSubmenu8">
                        <li>
                            <a href="#">Home 1</a>
                        </li>
                        <li>
                            <a href="#">Home 2</a>
                        </li>
                        <li>
                            <a href="#">Home 3</a>
                        </li>
                    </ul>
                </li>
                
                <li class="dropdown">
                    <a href="#pageSubmenu9" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">forklift</i><span>Vehicle Services</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu9">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li>
				
				 <li class="dropdown ">
                    <a href="#pageSubmenu10" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">military_tech</i><span>Promotions</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu10">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li>

				  <li class="dropdown">
                    <a href="#pageSubmenu11" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">edit_note</i><span>Complaints</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu11">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li>
				
				<li class="dropdown">
                    <a href="#pageSubmenu12" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
					<i class="material-icons">headset_mic</i><span>Chat</span></a>
                    <ul class="collapse list-unstyled menu" id="pageSubmenu12">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li>








               
               <li class="dropdown">
                    <a href="#pageSubmenu13" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"> 
                     <i class="material-icons">manage_accounts</i><span>Settings</span></a>
                     <ul class="collapse list-unstyled menu" id="pageSubmenu13">
                        <li>
                            <a href="#">Page 1</a>
                        </li>
                        <li>
                            <a href="#">Page 2</a>
                        </li>
                        <li>
                            <a href="#">Page 3</a>
                        </li>
                    </ul>
                </li>
				
				 {/* <li  class="#">
                    <a href="#"><i class="material-icons">library_books</i><span>Calender</span></a>
                </li> */}
               
               
            </ul>

           
        </nav>
		
       

 
     </>
      
      
    );
   }
 

   export default MenuSideBar; 