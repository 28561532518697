import firebase from "../DbConn"
import Swal from 'sweetalert2'
import swal from 'sweetalert'; 
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";

import Modal from 'bootstrap/js/dist/modal';
import   'bootstrap/js/dist/modal';
// 
// toast.configure()
 


const DriverAssignedcar = async () => {

     
  firebase.database().ref("akwaaba/drivers/").on('value', function(snapshots) {
    snapshots.forEach(function(xdata){
          
            if(xdata.key !=null )
         {
           var vals = xdata.val();
    
   
           firebase.database().ref('akwaaba/vehicleInfo/' ).on('value', function(snapshot) {
   
           if (snapshot.exists()){
               var vehi_list = '';
               snapshot.forEach(function(data) {
       
                   //   $('#vehicles_list').prop('selectedIndex', -1);
       
                   $('#vehicles_list').empty().append('<option>New Option</option>');
       
                   //$("#vehicles_list").val('').attr('selected', true);
       
                   var val = data.val();
                 //  //alertval.status);
                    //if( getidtsatus ==null && data.val().vehicleid==null && data.val().status=="approved")
   
                   if( (data.key != vals.vehicleid)   &&  (val.status == "approved" ) && val.assigned==null  ) 
                   // {
                     //  if(  val.status == "approved"  ) 
                       {
                    vehi_list += '<option value="' + val.vehicleid + '">' +
                    val.carManufacturer + ' ' + val.vehiclemodel + ' : ' +
                    ' ' + val.vehicleRegistrationNumber + ' - ' + val.carYear + ' ' + '</option>';
                 
                   }else
                   {
                       ////console.log("The helle Vehicle"   );
                   }
       
                   });
       
               $('#vehicles_list').append(vehi_list);
           }
       })
       }
   
   
   
       //
   //}
       })
       
       }, function (error) {
       //alert"Error: " + error.code);
       });
       
       
       
        
   
       
       function loaddrivers(){
        firebase.database().ref('akwaaba/drivers/').on('value', function(snapshot) {
       
               if (snapshot.exists()) {
                   var vehi_list = '';
                   snapshot.forEach(function(data) {
                       $('#driver_list').empty().append('<option>Select New Option</option>');
              
           
                       var val = data.val();
                   //    if ( (val.vehicleid == null || val.vehicleid == ""  || val.vehicleid == NULL  ) &&   (val.status == "approved" ) ) {        
                    // if (val.status == "approved") {
                    if ( val.vehicleid == null || val.vehicleid == "" )
                     {
           
                           vehi_list += '<option value="' + data.key + '">' + val.firstName + '     ' + val.lastName + '</option>';
           
                      // }  
                      }
           
           
                   });
           
                   $('#driver_list').append(vehi_list);
           
               }
           });
       }
       
       
    //    function Driversm_mainte_event() {
        const vehicleidnew=document.getElementById("thedriver_list")

        vehicleidnew.addEventListener('change',function(e){
  let getvalue=this.value;
  let selectedText = "";
  selectedText = this.value;
           ////alert15);
           //Get text or inner html of the selected option
        //    var d = document.getElementById("thedriver_list");
        //    var selectedText = d.options[d.selectedIndex].value;

          // alert(selectedText);
           // firebase.database().ref("akwaaba/drivers/").child("driverId").on('value', function(vehinumSnapshot) {
           //     var vehinumChildSnapshot = vehinumSnapshot.val();
       
               //var queryRef = firebase.database().ref("akwaaba/drivers/").orderByChild('driverId').equalTo(selectedText);
       
               var queryRef = firebase.database().ref("akwaaba/drivers/");
       
               queryRef.on('value', function(querySnapshot) {
                   querySnapshot.forEach(function(dinoSnapshot) {
       
                       if (dinoSnapshot.key == selectedText) {
       
                           var driver_list_id = '';
                           var driver_list_idupdate = '';
                           var firstName = '';
                           var lastName = '';
                           var email = '';
                           var phone = '';
       
                           // var nationalIDNumber= '';
       
                           var City = '';
                           var profilePhotoURL = '';
       
                           var val = dinoSnapshot.val();
       
                           firstName += val.firstName;
                           driver_list_id += dinoSnapshot.key;
                           driver_list_idupdate += dinoSnapshot.key;
                           lastName += val.lastName;
                           email += val.email;
                           phone += val.phone;
                           //        nationalIDNumber += val.nationalIDNumber;
                        //   City += val.city; profilePhoto
                           profilePhotoURL += val.profilePhoto;
       
                           document.getElementById('driver_list_id').value = driver_list_id;
                           document.getElementById('driver_list_idupdate').value = driver_list_idupdate;
                           //  document.getElementById('driver_list_name').value = plate_mainte;
       
                           document.getElementById('driver_list_firstName').value = firstName;
                           document.getElementById('driver_list_lastName').value = lastName;
                           document.getElementById('driver_list_email').value = email;
                           document.getElementById('driver_list_phone').value = phone;
                           //     document.getElementById('driver_list_nationalIDNumber').value = nationalIDNumber;
       
                           document.getElementById('driver_list_profilePhotoURL').value = profilePhotoURL;
                        //   document.getElementById('driver_list_profilePhotoURL').value = profilePhotoURL;
                           //profilePhoto

                     //      document.getElementById('driver_list_City').value = City;
       
                       }
                   });
       
               });
       
          // });
       })
       
       //
     // 
     







     const vehicleidnewlist=document.getElementById("vehicles_list")
     vehicleidnewlist.addEventListener('change',function(e){
     let getlistvalue=this.value;
     let selectedText = "";
     selectedText = this.value;
         //  //alert5);

         firebase.database().ref("akwaaba/vehicleInfo/").on('value', function(vehinumSnapshot) {
             //  var vehinumChildSnapshot = vehinumSnapshot.val();
     
             //    var queryRef = firebase.database().ref("akwaaba/vehicleInfo/").orderByChild('vehicleid').equalTo(selectedText);
             //       queryRef.on('value', function(querySnapshot) {
             //      querySnapshot.forEach(function(dinoSnapshot) {
     
     
             var queryRef = firebase.database().ref("akwaaba/vehicleInfo/");
     
             queryRef.on('value', function(querySnapshot) {
                 querySnapshot.forEach(function(dinoSnapshot) {
     
                     if (dinoSnapshot.key == selectedText) {
     
                         var vehicleid = '';
                         var licensePlate = '';
                         var carManufacturer = '';
                         var vehiclemodel = '';
     
                         var SeatCapacity = '';
                         var carYear = '';
                         var status = '';
                         var carType = '';
     
                         var val = dinoSnapshot.val();
     
                         vehicleid += val.vehicleid;
                         licensePlate += val.vehicleRegistrationNumber;
                         carManufacturer += val.carManufacturer;
                         vehiclemodel += val.vehiclemodel;
     
                         carType += val.carType;
                        // SeatCapacity += val.SeatCapacity;
                         carYear += val.carYear;
                         status += val.status;
     
     
                         document.getElementById('vehicles_list_id2').value = vehicleid;
     
                         document.getElementById('vehicles_list_id').value = vehicleid;
                         document.getElementById('vehicles_list_licensePlate').value = licensePlate;
                         document.getElementById('vehicles_list_carManufacturer').value = carManufacturer;
                         document.getElementById('vehicles_list_vehiclemodel').value = vehiclemodel;
     
                         document.getElementById('vehicles_list_carType').value = carType;
                         document.getElementById('vehicles_list_carYear').value = carYear;
                         document.getElementById('vehicles_list_status').value = status;
                       //  document.getElementById('vehicles_list_SeatCapacity').value = SeatCapacity;
     
                     }
                 });
     
             });
     
         });
     }
     )

















       
       
       // submit Vechicle to Driver
       
    //    function submitassignvehicle() {
        const submitassignvehiclenew=document.getElementById("submitassignvehicles")

        submitassignvehiclenew.addEventListener('click',function(e){
//   let value=this.value;
//alert(589)
       
           var website = document.getElementById("thedriver_list").value;
       
           //  
           var name = document.getElementById("driver_list_firstName").value;
            
       
           if (name === "" || website === '' || website == null) {
       
        
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                iconColor: 'white',
                customClass: {
                  popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true
              })
               Toast.fire({
                icon: 'error',
                heading: 'Oops...Something went wrong',
                     text: 'Error: "Invalid Selection. Please select a valid selection." ',
              })
            
       
               return false;
           } else {
       

            var carManufacturer= document.getElementById('vehicles_list_carManufacturer').value;
             var  vehiclemodel =  document.getElementById('vehicles_list_vehiclemodel').value;
             var  vehicleRegistrationNumber = document.getElementById('vehicles_list_licensePlate').value;
                   
            var vehicleInfos = carManufacturer + " " + vehiclemodel + " - " + vehicleRegistrationNumber;
    



       
             firebase.database().ref('akwaaba/assignDrivers/').push({
                vehicleInfo:vehicleInfos,
                   firstName: document.getElementById('driver_list_firstName').value,
                   lastName: document.getElementById('driver_list_lastName').value,
                   email: document.getElementById('driver_list_email').value,
                   phone: document.getElementById('driver_list_phone').value,
                   //   nationalIDNumber : document.getElementById('driver_list_nationalIDNumber').value ,
                   City: document.getElementById('driver_list_City').value,
                  // profilePhotoURL: document.getElementById('driver_list_profilePhotoURL').value,
                    profilePhoto: document.getElementById('driver_list_profilePhotoURL').value,
                  
                   driverId: document.getElementById('driver_list_id').value,
       
                 //profilePhoto
                   vehicleid: document.getElementById('vehicles_list_id').value,
                  // licensePlate: document.getElementById('vehicles_list_licensePlate').value,
                   carManufacturer: document.getElementById('vehicles_list_carManufacturer').value,
                   vehiclemodel: document.getElementById('vehicles_list_vehiclemodel').value,
       
                   carType: document.getElementById('vehicles_list_carType').value,
                   carYear: document.getElementById('vehicles_list_carYear').value,
                   status: document.getElementById('vehicles_list_status').value,
                 //  SeatCapacity: document.getElementById('vehicles_list_SeatCapacity').value,
   
                   assignedstatus:"approved",
                   createdAt : document.getElementById("datecreated").value,
       
               
               }) .then( async  snap => {
                //   .then(( snap) => {
            
                    const key = snap.key 
                    console.log("The Key Driver  = " + key)
                    // var key = abc.getKey();
                    UpdateVehicleidatdriverside();
                    //  var dk = document.getElementById("vehicles_list");
                    var keys = document.getElementById("vehicles_list_id2").value;
                    ////alertkeys);
                    UpdateVehicleapproved(keys);
                    UpdateVassignDrivers(key);
        
        
                   // load driver
                  //  thenewdrink();
                  thenewdrink();
              
                  })
                  .catch(async function ( error) {
                    
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-right',
                        iconColor: 'white',
                        customClass: {
                        popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 5500,
                        timerProgressBar: true
                      })
                      await Toast.fire({
                        icon: 'error',
                        title: 'Something went wrong!',
                       // text: 'Something went wrong!',
                        footer: '<a href="#" style="color:white;font-weight:bold">Error “ Try again later!.</a>'
                      })
            
            
                      document.getElementById("hidediv").style.display = 'none';
             
                }).then(async success => {
            
                    const Toast = Swal.mixin({
                        // ld(),
                         toast: true,
                         position: 'top-right',
                         iconColor: 'white',
                         customClass: {
                             popup: 'colored-toast'
                         },
                         showConfirmButton: false,
                         timer: 8500,
                         timerProgressBar: true
                     })
                     await Toast.fire({
                         icon: 'success',
                         title: ' driver-vehicle assignments',
                         text: 'Successful!',
                         footer: '<a href="#" style="color:white;font-weight:bold">Please wait - "Saving records.........</a>'
                         
                     })
            
                       document.getElementById("hidediv").style.display = 'none';
            
                   //   setTimeout(function () { window.location = "#"; }, 900);
            
            
            
                  });
            
               
       
              
            }
          //  
           
       })
       
       
       
       
      //  function clearform() {
      //      // document.getElementById("myForm").reset();
       
      //      document.getElementById("vehicles_list_id2").value = "";
       
      //      $("#thedriver_list")[0].selectedIndex = 0;
      //      $("#vehicles_list")[0].selectedIndex = 0;
       
      //  }
       
       function UpdateVehicleapproved(keys) {
           var status = "approved";
           firebase
               .database()
               .ref()
               .child("akwaaba/vehicleInfo/" + keys)
               .update({
                   status: status,
                   assigned:"Yes"
               });
       
       
           // clearform();
       
       }
   
   
       function UpdateVassignDrivers(key) {
         //  var status = "approved";
           firebase
               .database()
               .ref()
               .child("akwaaba/assignDrivers/" + key)
               .update({
                   assignDriver: key,
       
               });
       
       
           // clearform();
       
       }
       
       // firebase
       // .database()
       // .ref()
       // .child("/akwaaba/drivers/" + key)
       // .update({
       //     driversLicenseFrontPhotoURL: url,
       // });
   
     //  UpdateVehicleidatdriverside();
       function UpdateVehicleidatdriverside() {
         //  var status = "approved";
          var  keys=document.getElementById('driver_list_idupdate').value;
          var k=document.getElementById('vehicles_list_id').value;
       ////console.log(keys + "id key")
       
       ////console.log(k + "Vehicle id key")
       var statd="9x2mNfo3qrc0XZp8n12vmHmSk6L2";
       ////alertkeys + "ok89");
       var carManufacturer= document.getElementById('vehicles_list_carManufacturer').value;
       var  vehiclemodel =  document.getElementById('vehicles_list_vehiclemodel').value;
       var  vehicleRegistrationNumber = document.getElementById('vehicles_list_licensePlate').value;
             
      var vehicleInfos = carManufacturer + " " + vehiclemodel + " - " + vehicleRegistrationNumber;

       
           firebase
               .database()
               .ref()
               .child("akwaaba/drivers/" + keys)
               .update({
                
                  vehicleInfo:vehicleInfos,
                  vehicleid: document.getElementById('vehicles_list_id').value,
                  // vehicleid: k,
                  assigned:"Yes",
              //    assignednull:statd,
       
               });
       
       
           // clearform();
          // loaddrivers();
          //refreshdriver();
         //  refreshdriver();
       }
   
   
   
   function getthedrivernow(){
    firebase.database().ref('akwaaba/vehicleInfo/' ).on('value', function(snapshot) {
   
           if (snapshot.exists()){
               var vehi_list = '';
               snapshot.forEach(function(data) {
       
                   //   $('#vehicles_list').prop('selectedIndex', -1);
       
                   $('#thedriver_list').empty().append('<option>New Option</option>');
       
                   //$("#vehicles_list").val('').attr('selected', true);
       
                   var val = data.val();
                 //  //alertval.status);
                    
                   // if( (data.key != vals.vehicleid)   &&  (val.status == "pending" ) ) 
                   // {
                       if(  val.status == "pending"  ) 
                       {
                    vehi_list += '<option value="' + val.vehicleid + '">' +
                    val.carManufacturer + ' ' + val.vehiclemodel + ' : ' +
                    ' ' + val.vehicleRegistrationNumber + ' - ' + val.carYear + ' ' + '</option>';
                 
                   }
       
                   });
       
               $('#thedriver_list').append(vehi_list);
           }
       //})
       // }
       // //}
       // })
       
       }, function (error) {
       //alert"Error: " + error.code);
       });
       
   }
   
   
   
   
   
   
   
   function  refreshdriver(){
      // //alert1234567);
       
      firebase.database().ref('akwaaba/drivers/').on('value', function(snapshot) {
   
       if (snapshot.exists()) {
           var vehi_list = '';
           snapshot.forEach(function(data) {
               $('#driver_list').empty().append('<option>Select New Option</option>');
    
               var val = data.val();
           //    if ( (val.vehicleid == null || val.vehicleid == ""  || val.vehicleid == NULL  ) &&   (val.status == "approved" ) ) {
   
           
           // if (val.status == "approveds") 
            //{
         //
           if( val.vehicleid == "" )
           // if ( val.vehicleid == null )
                   {
   
                   vehi_list += '<option value="' + data.key + '">' + val.firstName + '     ' + val.lastName + '</option>';
   
              // }  
                   }
   
           });
   
           $('#driver_list').append(vehi_list);
   
       }
   });
   }
   
   
   //
   //refreshdriver();
   
   
   
   
   //thenewdrink();
   
   function thenewdrink(){
     



    firebase.database().ref("akwaaba/drivers/").on('value', function(snapshots) {
        snapshots.forEach(function(xdata){
              
                if(xdata.key !=null )
             {
               var vals = xdata.val();
        
       
               firebase.database().ref('akwaaba/vehicleInfo/' ).on('value', function(snapshot) {
       
               if (snapshot.exists()){
                   var vehi_list = '';
                   snapshot.forEach(function(data) {
           
                       //   $('#vehicles_list').prop('selectedIndex', -1);
           
                       $('#vehicles_list').empty().append('<option>New Option</option>');
           
                       //$("#vehicles_list").val('').attr('selected', true);
           
                       var val = data.val();
                     //  //alertval.status);
                        //if( getidtsatus ==null && data.val().vehicleid==null && data.val().status=="approved")
       
                       if( (data.key != vals.vehicleid)   &&  (val.status == "approved" ) && val.assigned==null  ) 
                       // {
                         //  if(  val.status == "approved"  ) 
                           {
                        vehi_list += '<option value="' + val.vehicleid + '">' +
                        val.carManufacturer + ' ' + val.vehiclemodel + ' : ' +
                        ' ' + val.vehicleRegistrationNumber + ' - ' + val.carYear + ' ' + '</option>';
                     
                       }else
                       {
                           ////console.log("The helle Vehicle"   );
                       }
           
                       });
           
                   $('#vehicles_list').append(vehi_list);
               }
           })
           }
       
       
       
           //
       //}
           })
           
           }, function (error) {
           //alert"Error: " + error.code);
           });
           
           















   
      ////alert5678903);
   
//       $('#thedriver_list').empty().append('<option>Select New Option.</option>');
    
//    firebase.database().ref('akwaaba/drivers/').orderByChild("vehicleid").equalTo(null).on("child_added", function(data){
      
//        var vehi_list = '';
//        var val = data.val();
     
//    var getidtsatus= data.val().assigned;
//    var status;
//    if( getidtsatus ==null && data.val().vehicleid==null && data.val().status=="approved")
//    {
//    //   $(this).css("background-color","#FF0000");
//    vehi_list += '<option value="' + data.key + '">' + val.firstName + '     ' + val.lastName + '</option>';
   
//    }else
//    {
//        ////console.log("The hello"  );
//    }
           
   
//        // 
//        $('#thedriver_list').append(vehi_list);
//        const options = []
//        document.querySelectorAll('#thedriver_list > option').forEach((option) => {
//            if (options.includes(option.value)) option.remove()
//            else options.push(option.value)
//        })
    
//    }
//    )
   
     
       
   }
    
   // getpdfs();




  
   
}


const DriverAssignedcarview = async () => {

     
  //getpdfs();

   
       

}





//Driver Start
// var database = firebase.database();
// database.ref('akwaaba/drivers/').orderByChild("status").equalTo("active").on('value', function(snapshot){




  

export {DriverAssignedcar  };