import React, { useState, useEffect,Redirect } from "react";

import { myFunctionLogout } from "../LoginUsers/LoginAuth";

import {  checktheuserLogin } from "../LoginUsers/LoginAuth";
// import yiedzelogo from "../Assert/ydw.png";
 




function MenuTop() {

    const handleSubmitLogout = (e) => {
        e.preventDefault();
    
        // alert(1234)
        myFunctionLogout();
    }
    
    
    
    useEffect(() => {
       // alert(50)
       checktheuserLogin();
    });
    

 
    // const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     setLoading(true)
          
    //      // window.setTimeout(function () {   LoadGMaps(); }, 4000) ;
    //    })

   
 

return (
       
     <>

{/*        
    {loading ? 
                 (  
                 <div   class="loading"></div>
                 ) : (
                   <h6></h6>
                 )} */}
       
         
         <div class="top-navbar">
        <nav class="navbar navbar-expand-lg">
                <div class="container-fluid">

                    <button type="button" id="sidebarCollapse" class="d-xl-block d-lg-block d-md-mone d-none">
                        <span class="material-icons">arrow_back_ios</span>
                    </button>
					
					<a class="navbar-brand" href="/monitor"> Yiedze</a>
					
                    <button class="d-inline-block d-lg-none ml-auto more-button" type="button" data-toggle="collapse"
					data-target="#navbarSupportedContent"
                     aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="material-icons"> <b class="menuformobileviewcolor">menu</b> </span>
                    </button>

                    <div class="collapse navbar-collapse d-lg-block d-xl-block d-sm-none d-md-none d-none" id="navbarSupportedContent">
                        <ul class="nav navbar-nav ml-auto">   
                            {/* <li class="dropdown nav-item active">
                                <a href="#" class="nav-link" data-toggle="dropdown">
                                   <span class="material-icons">notifications</span>
								   <span class="notification">4</span>
                               </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a href="#">You have 5 new messages</a>
                                    </li>
                                    <li>
                                        <a href="#">You're now friend with Mike</a>
                                    </li>
                                    <li>
                                        <a href="#">Wish Mary on her birthday!</a>
                                    </li>
                                    <li>
                                        <a href="#">5 warnings in Server Console</a>
                                    </li>
                                  
                                </ul>
                            </li> */}
                            {/* <li class="nav-item">
                                <a class="nav-link" href="#">
								<span class="material-icons">apps</span>
								</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
								<span class="material-icons">person</span>
								</a>
                            </li>
							<li class="nav-item">
                                <a class="nav-link" href="#">
								<span class="material-icons">settings</span>
								</a>
                            </li> */}


                            <li class="dropdown nav-item active">
                                <a href="#" class="nav-link" data-toggle="dropdown">
                                   <span class="material-icons">manage_accounts</span>
								   <span class="person">.</span>
                               </a>
                                <ul class="dropdown-menu">
                                    <li>
                                    <a href="#" class="dropdown-item">Profile</a>
                                    </li>
                                    <li>
                                        <a href="#">Reset Password</a>
                                    </li>
                                    <li>
                                        <a href="#">Make Request</a>
                                    </li>
                                    <li>
                                    <a href="#"
                              onClick={handleSubmitLogout}
                              class="dropdown-item">Logout</a>
                                    </li>
                                  
                                </ul>
                            </li>
                           

                        </ul>
                    </div>
                </div>
            </nav>
	    </div>



 
     </>
      
      
    );
   }
 

   export default MenuTop; 