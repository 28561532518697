import React, { useState, useEffect } from 'react';

import dlogo from '../ab.png';
import firebase from "../DbConn";
 
 import { PanicHistoryViewsall,Loadpdfsonly,TurnoffPanicHistoryAlarmViewsall , StopPanicHistoryAlarm } from './PanicHistoryViewallFormdb';

//import './Tablestyle.css';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");


//   import $ from "jquery";



const PanicHistoryViewallForm = () => {

    
  const [image, setImage] = useState(null);
    useEffect(() => {
      TurnoffPanicHistoryAlarmViewsall();
        window.setTimeout(function () { PanicHistoryViewsall(); }, 1000);
    }, [0])
 
    useEffect(() => {
      // Loadpdfonly();
       window.setTimeout(function () {
       
          Loadpdfsonly();
       }, 10000);
   
    
     })

    const Getroutemap = async (e) => {
        e.preventDefault();
        //   getthemaps();
       // initiatlizemapsChange();
    }

   //TurnoffPanicHistoryAlarmViewsall





   const stopPanicHistory = async (e) => {
    e.preventDefault();
    //   getthemaps();
   // initiatlizemapsChange();
   StopPanicHistoryAlarm()
 //  TurnoffPanicHistoryAlarmViewsall();
}

useEffect(() => {
 // psChange();
  StopPanicHistoryAlarm();

})


const  ondownloadpanicimage = (e) => {
  e.preventDefault();

  const t = document.getElementById("panicimagelink").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("panicimagelink").value="";
    //http://localhost:3000/undefined
};





const onLoginFormSubmits = (e) => {
  e.preventDefault();

  const t = document.getElementById("mylinkDriversLicenseFronturl").value;
  // window.location.href =t;

  let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("mylinkDriversLicenseFronturl").value="";
};


const onLoginFormSubmitsback = (e) => {
  e.preventDefault();

  const t = document.getElementById("mylinkDriversLicenseBackurl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("mylinkDriversLicenseBackurl").value="";
    //http://localhost:3000/undefined
};



const onLoginFormSubmitspersonalid = (e) => {
  e.preventDefault();

  const t = document.getElementById("passportfronturl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("passportfronturl").value="";
    //http://localhost:3000/undefined
};

const onLoginFormSubmitspersonalidback = (e) => {
  e.preventDefault();

  const t = document.getElementById("passportbackurl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("passportbackurl").value="";
    //http://localhost:3000/undefined
};


const  onLoginFormSubmitsInsurance = (e) => {
  e.preventDefault();

  const t = document.getElementById("insurancefronturl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("insurancefronturl").value="";
    //http://localhost:3000/undefined
};

const  onLoginFormSubmitsInsuranceback = (e) => {
  e.preventDefault();

  const t = document.getElementById("insurancebackurl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("insurancebackurl").value="";
    //http://localhost:3000/undefined
};

const  onLoginroadworthinessBack = (e) => {
  e.preventDefault();

  const t = document.getElementById("roadworthinessbackurl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("roadworthinessbackurl").value="";
    //http://localhost:3000/undefined
};


const  onLoginroadworthinessFront = (e) => {
  e.preventDefault();

  const t = document.getElementById("roadworthinessfronturl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("roadworthinessfronturl").value="";
    //http://localhost:3000/undefined
};


const  onLoginVehicleImage = (e) => {
  e.preventDefault();

  const t = document.getElementById("vehicleimageurl").value;
   let link = window.open(t, "_blank");
  link.opener = null;
  document.getElementById("vehicleimageurl").value="";
    //http://localhost:3000/undefined
};



    return (
        <>




<div class="hidediv" id="hidediv">
        <div class="loading"></div>
      </div>




      <div class="content-wrapper" >

        <div class="row">
          <div class="col-md-12 grid-margin">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-end flex-wrap">
                <div class="mr-md-3 mr-xl-5">
                  <h4>SOS  History</h4>
                  <p class="mb-md-0">History of the Crime Victims'</p>
                </div>

              </div>

              <div class="row">
              <div class="col-md-5">
                                  <div class="d-flex justify-content-between align-items-end flex-wrap">
                            
                                    <button id="themanagepanics" class="btn btn-danger  hide all"
                                    data-toggle="modal" data-target="#mythemanagepanic">
                                    <i class="fa fa-comment-o"></i> <b>View PanicHistory Zone</b></button>
                                    </div>
                                    </div>
                                    <div class="col-md-5">
                                      <div class="d-flex justify-content-between align-items-end flex-wrap">
                                      
                                      <button id="themanagepanics" class="btn btn-info hideall "
                                      
                                      onClick={() => window.open("/historyofpanicsosdatas")}  >
                                       <i class="fa fa-edit"></i> <b> View PanicHistory History</b> </button>
                                      </div>
                                      </div>
                                   </div>


            </div>
          </div>
        </div>

        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">.</h4>
              <div class="table-responsive">
                <div style={{ overflowX: "auto;" }}>


 

                  <table id="driveruserlist"
                    class="display "
                    style={{ width: "100%" }}>

                    <thead id="thead">
                      <tr id="tra">

                        <th style={{ display: "none" }} >ID</th>
                        <th style={{ display: "none" }} >ID</th>

                                <th>Name</th>
                    
                                <th>Phone</th>
                                <th>Email</th>
                                
                                <th> Date</th>

                      </tr>
                    </thead>
                    <tbody data-toggle="modal"
                      title="list of All registered drivers, Click the cell to view Drivers "></tbody>
                  </table>

                  {/* </a> */}


                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
     
     
      {/*                     pop up modal */}

      {/* <!-- Button trigger modal 
<!-- Modal --> */}

 
<div id="mythemanagepanic" class="modal fade" role="dialog">
  <div class="modal-dialog">  
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">.</h4>
      </div>
      <div class="modal-body">
         
                <div class="row">
                        <div class="col-md-12" >
                            <div class="form-group row">
                                <label class="col-form-label" style={{color: "green"}}><b> PanicHistory Management</b> </label>
                                <div class="col-sm-12">
                            
                                  <select class="form-control"  id="panic_listz"  >
                                        <option value="none" selected disabled hidden> 
                                    
                                    <div class="spinner-border text-info"> Select Victim Name.....</div>
                                        </option>
                                   </select>
                                </div>
                             </div>
                            </div>
                         </div>
 

                                         <input type="hidden"   class="form-control shadow-sm"
                                          id="panic_listz_idd" style={{fontWeight: "900"}}    />
 
                                         <input type="hidden"   class="form-control shadow-sm"
                                          id="panic_liveid" style={{fontWeight: "900"}}    />

 
 
 
                    <div class="col-md-12 hideall" >
                            <div class="form-group row">
                            <label>Do you want to send message to the nearest driver? </label> 

                                <div class="col-sm-12">
                                {/* <!-- <span> <label><b  style="color:red;">
                                <span id="panicinformation"></span>
                                </b> </label></span> --> */}


                                <label for="chkNo">
                                  <input type="radio" id="chkNo" value="No" name="chkcar" 
                                    onclick="ShowHideDivpanic()" checked />
                                  <span style={{fontSize:"20px", color:"black"}}> No  </span>  &nbsp;&nbsp;
                                 </label>


                                  <label for="chkYes">
                                  <input type="radio" id="chkYes" value="Yes" name="chkcar"   onclick="ShowHideDivpanic()"  />
                                  &nbsp;&nbsp; <span style={{fontSize:"20px", color:"black"}}> Yes</span> 
                                  </label>
                                  
                                </div>
                             </div>
                        </div>
                          
 
                    
                                     <div class="col-md-12">
                                       <div class="form-group row">
                                         <div class="col-sm-12">
                                    
                                         <div id="doyouwanttosendmessage" style={{display: "none"}}>
                                        <textarea id="driverpanicmessage" placeholder="Send Nearest Driver Message"
                                            name="driverpanicmessage" rows="4"  class="form-control shadow-sm"></textarea>
                                          </div>

                                         </div>
                                       </div>
                                     </div>
 
                                     <div class="col-md-12" id="panicimgid"  >
                                       <div class="form-group row">
                                         <div class="col-sm-12">
                                         <label><b  style={{color: "blue"}}>  </b> </label>
                                         {/* <a id="panicimagelinkdownload" target="_blank"
                                          href="javascript:void(0)" onclick="clickpimage()" download> 
                                          <img width="100" height="100" alt="" src="images/d3.png" />        
                                        </a> */}
                                                   <a target="_blank" href="#"
                                                    rel="noopener noreferrer"
                                                  onClick={ondownloadpanicimage}
                                                >Download alert location image<span class="fa fa-download"></span> </a>
                                                  &nbsp;&nbsp;
                                                  &nbsp;&nbsp;
                                            

                                         </div>
                                       </div>
                                     </div>
 

                                      <div class="col-md-12">
                                       <div class="form-group row">
                                         <div class="col-sm-12">
                                         <label><b  style={{color: "blue"}}>Live Status  </b> </label>
                                          <select id="paniclivestatuss"  class="form-control shadow-sm"  >
                                                <option value=""> Select Option</option>
                                                <option value="0"> Stop Alarm</option>
                                                <option value="1"> Pending </option>
                                                 
                                          </select>

                                         </div>
                                       </div>
                                     </div>
 
      </div>



      <div class="modal-footer">     
       <button type="button"  class="btn btn-info"  id="stopAlertpopup"> Stop Alarm </button>

        <button type="button" class="btn btn-default hideall" data-dismiss="modal">Close</button>
        <button type="button"  class="btn btn-success hideall"  id="approvepanicdriver"> Activate </button>
     
      </div>
    </div>

  </div>
</div> 


{/* 
<!-- Modal --> */}
 
 
 <div class="modal fade" id="PanicHistorydriverModal" tabindex="-1" role="dialog"
        aria-labelledby="PanicHistorydriverModalLabel" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop"  >
        <div class="modal-dialog modal-sm" style={{ maxWidth: "350px" }}>
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="PanicHistorydriverModalLabel">Driver Details</h5>
           
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Status</label>
                    <div class="col-sm-9">
                      {/* 
                           <!-- <select disabled id="driverlive_statuss"  class="form-control shadow-sm"  required  />
                                                --> */}

                      <select id="driverlive_statuss" title="Select option to change driver status"
                        class="form-control shadow-sm" disabled>
                        <option value=""> Select Option</option>
                        <option value="pending"> Pending </option>
                        <option value="suspended"> Suspended </option>
                        <option value="approved"> Approved </option>
                      </select>
                    </div>
                  </div>
                </div>


              </div>




              <div class="row">
                <div class="col-md-12">
                  <div class="centercimg">
                    <span class="loaderimg centercimg" id="loaderimg"></span>

                  </div>

                  <div id="hidecimg">

                    <img width="40" height="40"
                      src={image || dlogo}
                      class="img-responsive rounded-circle 
                                        center-block d-block mx-auto "
                      id="cimg" alt="No Image avalable"
                    />

                  </div>
                </div>
                {/* <!-- <div class="col-md-12">
                                       <div class="form-group row">
                                       
                                         <div class="col-sm-12">
                                         <input type="text"  class="form-control shadow-sm"
                                          id="mymodelfname"  placeholder="Full name"  required  />

                                         </div>
                                       </div>
                                     </div> --> */}

                <div class="col-md-12"  >
                  <label>Name :</label>
                  <label for="" id="driverlive_statussx">  <span id="mymodelfname"> </span></label>
                </div>

                <div class="col-md-12"  >
                  <label>Email :</label>
                  <label for="" id="driverlive_statussx">  <span id="mymodelemail"> </span></label>
                  &nbsp;&nbsp;&nbsp;&nbsp; <br />
                  <label>Phone Number :</label>
                  <label for="" id="driverlive_statussx">  <span id="mymodelphone"> </span></label>
                </div>


                <div class="col-md-12 hideall"  >
                  <label>Badge Number :</label>
                  <label for="" id="driverlive_statussx">  <span id="badgenumber"> </span></label>
                </div>

                <div class="col-md-12 hideall"  >
                  <label>Call Sign:</label>
                  <label for="" id="driverlive_statussx">  <span id="callsign"> </span></label>
                </div>

                <div class="col-md-12"  >
                  <label>Vehicle Info:</label>
                  <label for="" id="driverlive_statussx">  <span id="vehicleinfos"> </span></label>
                </div>
              </div>

              <div class="row" style={{ display: "none" }}>
                <div class="col-md-12" style={{ display: "none" }}>
                  <div class="form-group row">

                    <div class="col-sm-12">
                      <input type="text" class="form-control shadow-sm"
                        id="mymodellcity" placeholder="No City" />
                    </div>
                  </div>
                </div>
                <div class="col-md-12" style={{ display: "none" }}>
                  <div class="form-group row">

                    <div class="col-sm-12">
                      <input type="email" class="form-control shadow-sm"
                        id="mymodelemailk" placeholder="No Email" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" >
                <div class="col-md-12" style={{ display: "none" }}>
                  <div class="form-group row">

                    <div class="col-sm-12">

                      <input type="number" class="form-control shadow-sm" id="mymodelphone"
                        placeholder="No Phone Number" />
                    </div>
                  </div>
                </div>


                <div class="col-md-12">
                  <div class="form-group row">

                    <div class="col-sm-12">
                      <label for="photo">
                        <hr />

                        <p> Click the link below to Download </p>


                        <span>
                          <a target="_blank" href="#"
                            id="mylinkDriversLicenseFront"
                            rel="noopener noreferrer"
                            onClick={onLoginFormSubmits}
                          >Driving Licence Front<span class="fa fa-download"></span> </a>
                            &nbsp;&nbsp;
                           &nbsp;&nbsp;
                           <br />

                           <a target="_blank" href="#"
                           
                            rel="noopener noreferrer" class="hideall"
                            onClick={onLoginFormSubmitsback}
                          >Driving Licence Back<span class="fa fa-download"></span> </a>
                            &nbsp;&nbsp;
                           &nbsp;&nbsp;
                           <br />

                           <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitspersonalid}
                         >Personal ID Front <span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          <a target="_blank" href="#"  class="hideall"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitspersonalidback}
                         >Personal ID Back <span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />  


                          <a target="_blank" href="#" 
                             rel="noopener noreferrer"
                           onClick={onLoginVehicleImage}
                         >Vehicle Image<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          
                          <a target="_blank" href="#" class="hideall"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitsInsurance}
                         >Insurance File Front ..<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          <a target="_blank" href="#"  class="hideall"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitsInsuranceback}
                         >Insurance file Back<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />
                         






                          <a target="_blank" href="#" class="hideall"
                             rel="noopener noreferrer"
                           onClick={onLoginroadworthinessFront}
                         >roadworthiness Front ..<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          <a target="_blank" href="#"  class="hideall"
                             rel="noopener noreferrer"
                           onClick={onLoginroadworthinessBack}
                         >roadworthiness Back<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />
                          <br />



                          
   



                        </span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="modal-footer">
              {/* <button type="button" class="btn btn-secondary close"
               data-dismiss="modal" onClick={onLoginFormSubmit}>Close</button> */}
              {/* <button type="submit" class="btn btn-info"
              //  onclick="location.href='drivers_registrationeditt' " 
           //   onclick={openNewpapage}
           onClick={() => window.open("/driversregistrationeditupdate")}
              >Update  </button> */}
            </div>
          </div>
        </div>
      </div>


 


  
        
<input type="hidden"
        id="panicimagelink" />

        

      <input type="hidden" id="newdriveridupdate" />
      <input type="hidden" id="newdriveridupdatestatus" />



      <input type="hidden"
        id="mylinkDriversLicenseFronturl" />
      <input type="hidden"
        id="mylinkDriversLicenseBackurl" />



      <input type="hidden"
        id="privateHiredriverslincencepaperformbackurl" />

      <input type="hidden"
        id="privateHiredriverslincencepaperformfronturl" />



      <input type="hidden"
        id="DBSPoliceReportbackurl" />

      <input type="hidden"
        id="DBSPoliceReportfronturl" />



      <input type="hidden"
        id="passportfronturl" />

      <input type="hidden"
        id="passportbackurl" />


        
<input type="hidden"
        id="insurancefronturl" />

        
      <input type="hidden"
        id="insurancebackurl" /> 


        <input type="hidden"
          id="roadworthinessfronturl" />
  
          
        <input type="hidden"
          id="roadworthinessbackurl" />



<input type="hidden"
        id="vehicleimageurl" />

      <input type="hidden"
        id="passportbackurl" />

        
      <input type="hidden"
        id="vehicleImagefronturl" />

                                  <input   type="hidden"       id="driveridimg" /> 
                                     <input   type="hidden"       id="riderid" />   
                                     
                                     <input   type="hidden"       id="driverid" />   
                                      <input   type="hidden"       id="driverIds" />   
                                      <input   type="hidden"       id="driverId" />                                   
                                     <input   type="hidden"       id="thecustomerId" /> 
                                     <input id="userID"    type="hidden"    value="uid"   class="form-control"      />
                                    <input  id="datecreated"   type="hidden"  style={{display: "none"}}  /><br/>
                                    <input  id="datecreatedd"  type="hidden"  style={{display: "none"}}  />
             
        </>
    )
}

export default PanicHistoryViewallForm