import React, { useState, useEffect } from 'react';

import dlogo from '../ab.png';
import firebase from "../DbConn";
import { DriverAssignedcar } from './AssignedDriverToVehicleViewAlldb';

import './Tablestyle.css';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");



const  AssignedDriverToVehicleViewAllForm = () => {

  useEffect(() => {
    const date = new Date();

    // 👇️
document.getElementById('datecreated').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
        
 document.getElementById('datecreatedd').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
     

})
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  // close modal but not use now
  // const onLoginFormSubmit = (e) => {
  //   e.preventDefault();

  //   document.getElementById("exampleModal").style.display = 'none';

  //   handleClose();
  // };









  useEffect(() => {

     DriverAssignedcar();
 

  //   window.setTimeout(function () {
  //  //   getcolorloadsd();
  //     //  window.location.reload(1);
  //   }, 5000);
 
  },[])

  useEffect(() => {
   // Loadpdfonly();
  //   window.setTimeout(function () {
  //   //  getcolorloadsd();
  //      ///  window.location.reload(1);
  //  //    Loadpdfonly();
  //   }, 10000);

 
  })


// open new page to edit
  

  return (
    <>



      <div class="hidediv" id="hidediv">
        <div class="loading"></div>
      </div>



 

      <div class="content-wrapper"  >

          <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                  <div class="mr-md-3 mr-xl-5">
                    <h2>Drivers Vehicle Assignment  </h2>
                    <p class="mb-md-0">List of Drivers.</p>
                  </div>
                 
                </div>
                <div class="d-flex justify-content-between align-items-end flex-wrap">
        
                  <button id="themanagedrivers" style={{display: "none"}} class="btn btn-primary mt-2 mt-xl-0"
                   data-toggle="modal" data-target="#mythemanagedrivers"  >Manage Driver</button>
              
                </div>
                <div class="d-flex justify-content-between align-items-end flex-wrap">
                    
                    <button id="managethedriverss" style={{display: "none"}}
                    class="btn btn-success mt-2 mt-xl-0">Manage Vehicles</button>
                </div>
              </div>
            </div>
          </div>



            <div class="col-12 grid-margin"  >
            <div id="msgs" style={{ color:"green", backgroundColor:"#a2fa67"}}></div>
   
               <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Assigning A Car to  Driver</h4>
                  <form >



                    <div class="row">
                      <div class="col-md-6">
                         
                          <label class="  col-form-label">Select Driver</label>
                        
                       
                <select class="form-control shadow-sm" name="thedriver_list"
                 id="thedriver_list"
                  // onchange="Driversm_mainte_event()"
                 >
                                <option value="none" selected disabled hidden> 
                               
                                <div class="spinner-border text-info"> Select Option</div>
                                 </option>

                           </select>
                          </div>
                       


                      <div class="col-md-6"> 
                          <label class=" col-form-label">Select Vehicle </label>
                          
                           <select class="form-control shadow-sm" name="vehicles_list"
                            id="vehicles_list" 
                            // onchange="vehicle_event()"
                            >
                                <option value="none" selected disabled hidden> 
                                Select New Vehicle
                                 </option>

                           </select>
                          </div>
                        </div>
                    
                   


                        <div class="row">.
                        </div>
                    



                   
                    <div class="row">
                      <div class="col-md-6">
                      <input type="hidden"  style={{display: "none"}}  placeholder="vehicle Name" 
                           class="form-control shadow-sm" id="seen"    /> 
                      </div>
                      <div class="col-md-6">
                        
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                       
                      </div>

                      <input   type="hidden"  style={{display: "none"}}  id="datecreated"  />
          
                    
 
  
                    </div>
                   <p>
                   {/* <input type="submit" id="submitassignvehicles" onclick="submitassignvehicle()"
                    class="btn btn-success shadow" value="Assign Now" 
                    /> */}
                    <button type="button"  id="submitassignvehicles" class="btn btn-success">Submit</button>

{/* 
<button type="submit" form="form1" value="Submit">Submit</button> */}

                   </p>
             
 
                               <input id="userid" type="hidden"  style={{display: "none"}}   name="date" />
                              <input id="txtdate" type="hidden"  style={{display: "none"}}  name="date" />
                              <input   type="hidden"  style={{display: "none"}}  id="driver_list_id"  />
                              <input   type="hidden"  style={{display: "none"}}  id="driver_list_idupdate"  />
                              <input  type="hidden"  style={{display: "none"}}  id="driver_list_user_id"  />
                              <input   type="hidden"  style={{display: "none"}}   id="driver_list_firstName"  />
                              <input   type="hidden"  style={{display: "none"}}   id="driver_list_lastName"  />
                              <input   type="hidden"  style={{display: "none"}}  id="driver_list_email"  />
                              <input   type="hidden"  style={{display: "none"}}  id="driver_list_phone"  />
                              <input   type="hidden"  style={{display: "none"}}  id="driver_list_nationalIDNumber"  />
                              <input   type="hidden"  style={{display: "none"}}  id="driver_list_City"  />
                              <input   type="hidden"  style={{display: "none"}} 
                               placeholder="url"  id="driver_list_profilePhotoURL"  />
                          
                              
                              {/* <!--vehicle Hidden inputs --> */}
                              <input   type="hidden"  style={{display: "none"}}  id="vehicles_list_id"  />
                              
                              <input   type="hidden"  style={{display: "none"}}  id="vehicles_list_id2"  />

                              <input   type="hidden"  style={{display: "none"}}   id="vehicles_list_licensePlate"  />
                              <input   type="hidden"  style={{display: "none"}}   id="vehicles_list_carManufacturer"  />
                              <input   type="hidden"  style={{display: "none"}}  id="vehicles_list_vehiclemodel"  />
                              <input   type="hidden"  style={{display: "none"}}  id="vehicles_list_carType"  />
                                <input   type="hidden"  style={{display: "none"}}   id="vehicles_list_SeatCapacity"  />
                              <input   type="hidden"  style={{display: "none"}}  id="vehicles_list_carYear"  />
                              <input   type="hidden"  style={{display: "none"}}  id="vehicles_list_status" value="active"  />

                   
 
       


                        <input  id="datecreated"   type="hidden"  style={{display: "none"}}  /><br/>
                         <input  id="datecreatedd"  type="hidden"  style={{display: "none"}}  />
                         <input  id="licensePlate" value="100001"  type="hidden"  style={{display: "none"}}  />

                         </form>
                        
                </div>
              </div>
              </div>
              </div>

    </>
  )
}

export default AssignedDriverToVehicleViewAllForm;