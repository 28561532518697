 
import firebases from '../Auth/userauth';
 

 

const LoginWithEmailAndPassword = async ( email, password) => {
  document.getElementById("loaderid").style.display = 'inline';

   
firebases.auth().signInWithEmailAndPassword(email, password).then(user => {
  document.getElementById("loaderid").style.display = 'inline';
  document.getElementById("errorpassword").style.display = 'none';
    localStorage.setItem("useremail", email);
    window.setTimeout(function () { 
       window.location.href = "/monitor";
      }, 3000);
  
 
firebases.database().ref('akwaaba/SystemUsers/').orderByChild("email").equalTo(email)
.on("child_added", function(data){
  
  window.setTimeout(function () {     window.location.href = "/monitor";  }, 3000);   
 
        localStorage.setItem("driverhideshows", data.val().driver); 
         
         localStorage.setItem("customerhideshows", data.val().customer); 
         
         localStorage.setItem("vehiclehideshows", data.val().vehicles); 
         
         localStorage.setItem("accounthideshows", data.val().account); 
         
         localStorage.setItem("bookinghideshows", data.val().booking); 
         
         localStorage.setItem("tripshideshows", data.val().trips); 
         
         localStorage.setItem("panichideshows", data.val().panicalert); 
        
         localStorage.setItem("permissionhideshows", data.val().permissions); 

         localStorage.setItem("settinghideshows", data.val().settings);   
         
         localStorage.setItem("driverhideshows", data.val().driver);   

          
      
         localStorage.setItem("complainthideshows", data.val().complaint);  
         localStorage.setItem("promotionshideshows", data.val().promotions);  
         localStorage.setItem("vehicleserviceshideshows", data.val().vehicleservices);  

           localStorage.setItem("getstoragechats", data.val().chats); 
   
           }, function (error) {
            alert ("Error: " + error.code);

              
            });
 
 

}).catch(err => {
   //alerterr.message);loaderid 
  // document.getElementById("errorpassword").innerHTML = " Login Error! <br><b> Invalid Email or Password<b>";
   document.getElementById("errorpassword").style.display = 'inline';
   document.getElementById("loaderid").style.display = 'none';
});
 
};














function myFunctionLogout() 
{
 ////alert"Hello oka");
 firebases.auth().signOut().then(function() {
// Sign-out successful.
window.location.replace("/");
}).catch(function(error) {
// An error happened.
});
}



function myviewcurentuser() 
{
//  Log Out
var user = firebases.auth().currentUser;

if (user !=null) {
  // User is signed in.
 var users= user.email
  //alert"User email : " + users);
} else {
  // No user is signed in.
} 




firebases.auth().onAuthStateChanged(function(user) {
  if (user) {
   ////console.log(user.email);
  } else {
    // No user is signed in.
    
 window.location.replace("/");
  }
});


}

const checkuserLoginWithEmailAndPasswordLogout = async () => {
 // alert(3333333333)
  const user = firebases.auth().currentUser
  if (user) {
      // user is signed in, show user data
  }
  else {
    
 window.location.replace("/");
      // user is signed out, show sign-in form
  }

// firebases.auth().onAuthStateChanged(function(user) {
//   if (user) {
//    ////console.log(user.email);
//    alert(13)
//   } else {
//     // No user is signed in.
//     alert(343)
//  window.location.replace("/");
//   }
// });



}

const checktheuserLogin = async () => {
 
 firebases.auth().onAuthStateChanged(function(user) {
   if (user) {
    ////console.log(user.email);
    //alert(13)
   } else {
     // No user is signed in.
     //alert(343)
  window.location.replace("/");
   }
 });
 
 
 
 }





export { LoginWithEmailAndPassword, checktheuserLogin , myFunctionLogout,checkuserLoginWithEmailAndPasswordLogout};

