 
import  firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import  "firebase/compat/auth";
import "firebase/compat/storage";

  
// import "firebase/auth/dist/index.cjs.js";
// import "firebase/firestore/dist/index.node.cjs.js";
// import "firebase/storage/dist/index.cjs.js";


var firebaseConfig = {
 
  // apiKey: "AIzaSyDH2cNxY6u72LRzobEnGVXOFOx3SPSTHxY",
  // authDomain: "vmtransportproject.firebaseapp.com",
  // projectId: "vmtransportproject",
  // storageBucket: "vmtransportproject.appspot.com",
  // messagingSenderId: "97514342588",
  // appId: "1:97514342588:web:348b448d448f5d3a7528b9",
  // measurementId: "G-BL5PSTBMMX"


  
  // apiKey: "AIzaSyAWczfDTJv98v-WWr66Z3MCpmYtmC65sTI",
  // authDomain: "akwaabaride-93106.firebaseapp.com",
  // databaseURL: "https://akwaabaride-93106-default-rtdb.firebaseio.com",
  // projectId: "akwaabaride-93106",
  // storageBucket: "akwaabaride-93106.appspot.com",
  // messagingSenderId: "434791352849",
  // appId: "1:434791352849:web:f11f6c0b1ea0be3915ffb0",
  // measurementId: "G-B07SXV5EJR"
  


  // Akwaaba
  apiKey: "AIzaSyAWczfDTJv98v-WWr66Z3MCpmYtmC65sTI",
  authDomain: "akwaabaride-93106.firebaseapp.com",
  databaseURL: "https://akwaabaride-93106-default-rtdb.firebaseio.com",
  projectId: "akwaabaride-93106",
  storageBucket: "akwaabaride-93106.appspot.com",
  messagingSenderId: "434791352849",
  appId: "1:434791352849:web:f11f6c0b1ea0be3915ffb0",
  measurementId: "G-B07SXV5EJR"
  
  
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
 
 
 
export default firebase;
