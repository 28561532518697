  import React, { useState, useEffect } from 'react';

import dlogo from '../ab.png';
import firebase from "../DbConn";
 
 import { RequestTripsPendingViewsall,Loadpdfsonly  } from './RequestTripsPendingViewallFormdb';

import './Tablestyle.css';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");


//   import $ from "jquery";



const RequestTripsPendingViewallForm = () => {

    
    useEffect(() => {
        window.setTimeout(function () { RequestTripsPendingViewsall(); }, 1000);
    }, [0])
 
    useEffect(() => {
      // Loadpdfonly();
       window.setTimeout(function () {
       
          Loadpdfsonly();
       }, 10000);
   
    
     })

    const Getroutemap = async (e) => {
        e.preventDefault();
        //   getthemaps();
       // initiatlizemapsChange();
    }

   

    return (
        <>




<div class="hidediv" id="hidediv">
        <div class="loading"></div>
      </div>




      <div class="content-wrapper" >

        <div class="row">
          <div class="col-md-12 grid-margin">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-end flex-wrap">
                <div class="mr-md-3 mr-xl-5">
                  <h4>Booking Management  </h4>
                  <p class="mb-md-0">List of bookings cancelled by Customer</p>
                </div>

              </div>
              <div class="d-flex justify-content-between align-items-end flex-wrap">

                <button id="themanagedrivers" class="btn btn-primary mt-2 mt-xl-0 hideall"
                  data-toggle="modal" data-target="#mythemanagedrivers" >Manage Driver</button>
              </div>
              <div class="d-flex justify-content-between align-items-end flex-wrap">

                <button id="managethedriverss" style={{ display: "none" }}
                  class="btn btn-success mt-2 mt-xl-0">Manage Vehicles</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">.</h4>
              <div class="table-responsive">
                <div style={{ overflowX: "auto;" }}>



                  {/* <a href="#" > */}


                  {/* <table id="driveruserlist" class="table table-striped table-bordered driveruserlist"
                                      style="width:100%"> */}

                  <table id="driveruserlist"
                    class="display "
                    style={{ width: "100%" }}>

                    <thead id="thead">
                      <tr id="tra">

                        <th style={{ display: "none" }} >ID</th>
                        <th style={{ display: "none" }} >ID</th>

                        <th style={{ display: "none" }}>Driver(s)</th>
                                 <th>Customer(s)</th> 
                                
                                

                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>  Distance</th>
                                <th>  Duration</th>
                                <th> Est. Fare(£)</th>
                               
                                <th> Actual Fare(£)</th>
                                <th> Comments</th>
                                <th> Dispatch Datetime</th>
                                <th> Acceptance Datetime</th>
                                 <th> Arrival  Datetime</th>
                                 <th> PickUp /POB  Datetime</th>
                                 <th> Dropoff  Datetime</th>

                      </tr>
                    </thead>
                    <tbody data-toggle="modal"
                      title="list of All registered drivers, Click the cell to view Drivers "></tbody>
                  </table>

                  {/* </a> */}


                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
     
     
      {/*                     pop up modal */}

      {/* <!-- Button trigger modal 
<!-- Modal --> */}

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document"  style={{maxWidth: "500px"}} >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"> Bookings info...</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ display: "none" }}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <div class="row">

                                      <div class="col-md-12">
                                      <label>Customer picture  :</label>
                                     <img width="40" height="40"   
                                      src={ dlogo}
                                        class="hideall img-responsive rounded-circle center-block d-block mx-auto"
                                         id="cimgcustomer" alt="No Image avalable"  />

                                     </div> 

                                   <div class="col-md-12"  >
                                     <label>Customer Name   :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <b style={{color:"green"}}> <i>   <span id="mymodelfname"> </span></i>  </b>
                                          </label>
                                       </div>
 
                                  {/* <!-- Driver info--> */}
                                  <hr/>
                                    
                                  <div class="col-md-12 hideall">
                                      <label>Driver picture  :</label>
                                     <img width="40" height="40"    
                                         src="images/xyz1.png"
                                        class="hideall img-responsive rounded-circle center-block d-block mx-auto"
                                         id="driverimg" alt="No Image avalable"
                                        
                                            />

                                     </div> 

                                   <div class="col-md-12 hideall"  >
                                     <label>Driver Name   :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <b style={{color:"green"}}> <i>   <span id="Drivernamephone"> </span></i>  </b>
                                          </label>
                                       </div>
 
                         </div>
                                   
                         <div class="row">

                             

                                  <div class="col-md-12"  >
                                     <label>Booking Status   :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="BookingStatus"> </span></i>  </span>
                                          </label>
                                       </div>

                                       <div class="col-md-12"  >
                                     <label>Trip Status   :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="Tripstatus"> </span></i>  </span>
                                          </label>
                                       </div>

                                    
                                       <div class="col-md-12"  >
                                     <label>Booking Distance  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="BookingDistance"> </span></i>  </span>
                                          </label>
                                       </div>
                                       <div class="col-md-12"  >
                                     <label>Booking Duration  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="Bookingduration"> </span></i>  </span>
                                          </label>
                                       </div>
                                       <div class="col-md-12"  >
                                     <label>Booking Cost  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="BookingCost"> </span></i>  </span>
                                          </label>
                                       </div>



                                       <div class="col-md-12"  >
                                     <label>Pick-Up(From)  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="currentAddress"> </span></i>  </span>
                                          </label>
                                       </div>

                                       <div class="col-md-12"  >
                                     <label>Destination(To)  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="dropAddress"> </span></i>  </span>
                                          </label>
                                       </div>

                                       <div class="col-md-12"  >
                                     <label>Booking Date  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="BookingDate"> </span></i>  </span>
                                          </label>
                                       </div>

                                       <div class="col-md-12"  >
                                     <label>Arrival Date  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="arrivaldate"> </span></i>  </span>
                                          </label>
                                       </div>



                                       <div class="col-md-12"  >
                                     <label>PickUp Date  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="pickupdate"> 

                                           </span></i>  </span>
                                          </label>
                                       </div>


                                       
                                       <div class="col-md-12"  >
                                     <label>Dropoff Date  :</label>
                                         <label for="" id="driverlive_statussx">  
                                        
                                           <span style={{color:"green"}}> <i>   <span id="completedate"> </span></i>  </span>
                                          </label>
                                       </div>


                                        
                                   
                         </div>

 
                                   <div class="row">

                                    
                                   </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" style={{ display: "none" }} data-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>
 


  
        
                                      <input   type="hidden"       id="driverIds" />                                   
                                     <input   type="hidden"       id="thecustomerId" /> 
                                     <input id="userID"    type="hidden"    value="uid"   class="form-control"      />
                                    <input  id="datecreated"   type="hidden"  style={{display: "none"}}  /><br/>
                                    <input  id="datecreatedd"  type="hidden"  style={{display: "none"}}  />
             
        </>
    )
}

export default RequestTripsPendingViewallForm