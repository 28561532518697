
//import firebaseb from './Drivers/Pages/Auth/userauth';
import firebase from "../DbConn"
import Swal from 'sweetalert2'
import swal from 'sweetalert';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";

// import { Field } from "formik";
//import moment from 'moment';
//const firebase=9;
 


const RequestFormdbs = async () => {

     
 



document.getElementsByName('dispatchmodechkcar').forEach(function(e) {
    e.addEventListener("click", function() {
       // alert(e.value);
          
    if(this.value== "auto" || this.value== "Auto"){
        document.getElementById("driver_list").disabled=true;
    }else{

        document.getElementById("driver_list").disabled=false;
    }
    
    });
});




var inputs = document.getElementsByClassName("dismode");
for(var i = 0; i < inputs.length; i++) {
   inputs[i].addEventListener("mouseover", function(){
  //  alert("WOrks" + this.value); 
    getdismodeformanualautodriver();
    
    });
}
 

function getdismodeformanualautodriver(){
    
document.getElementsByName('dispatchmodechkcar').forEach(function(e) {
    e.addEventListener("mouseover", function() {
       // alert(e.value);
          
    if(this.value== "auto" || this.value== "Auto"){
        document.getElementById("driver_list").disabled=true;
        document.getElementById("dispatchmodechkYes").checked = true;
    }else
    {
        document.getElementById("driver_list").disabled=false;
        document.getElementById("dispatchmodechkNo").checked = true;
        document.getElementById("dispatchmodechkYes").checked = false;
    }
    
    });
});
}

 

}
const RequestFormdbswithcar = async () => {

    // document.getElementById("hidediv").style.display = 'inline';

}





export { RequestFormdbs, RequestFormdbswithcar };
