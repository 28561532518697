
import React , {useState  }  from 'react'; 
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
  
 
  
import Logins from './LoginUsers/Logins';
 
import DriversRegistrations from "./Pages/Drivers/DriversRegistrations";
import DriversRegistrationsUpdate from "./Pages/Drivers/DriversRegistrationsUpdate";
import DriverDataViewAll from "./Pages/Drivers/DriverDataViewAll";
import Pending_DriverDataViewAll from "./Pages/Drivers/Pending_DriverDataViewAll";
import Suspended_DriverDataViewAll from "./Pages/Drivers/Suspended_DriverDataViewAll";
import Approval_DriverDataViewAll from "./Pages/Drivers/Approval_DriverDataViewAll";


import AssignedDriverToVehicleViewAll from "./Pages/AssignedVehicleToDriver/AssignedDriverToVehicleViewAll"; 
import AssignedVehicleViews from "./Pages/AssignedVehicleToDriver/AssignedVehicleView"; 



// import vehiclesRegistrations from "./Pages/Vehicles/VehiclesRegistrations";
import VehiclesRegistrations from "./Pages/Vehicles/VehiclesRegistrations";

 import VehiclesRegistrationsUpdate from "./Pages/Vehicles/VechicleRegistrationsUpdate";
  import VehiclesDataViewAll from "./Pages/Vehicles/VehiclesDataViewAll";
  import ApprovedVehiclesDataViewAll from "./Pages/Vehicles/ApprovedVehiclesDataViewAll";
  import SuspendedVehiclesDataViewAll from "./Pages/Vehicles/SuspendedVehiclesDataViewAll";
  import PendingVehiclesDataViewAll from "./Pages/Vehicles/PendingVehiclesDataViewAll";
  
import CustomersReg from "./Pages/Customers/CustomersReg";
import CustomersView from "./Pages/Customers/CustomersView";
import PendingCustomersView from "./Pages/Customers/PendingCustomersView";
import CustomersViewApproved from "./Pages/Customers/ApprovedCustomersView";
import CustomersViewSuspended from "./Pages/Customers/SuspendedCustomersView";
import CustomersViewUpdate from "./Pages/Customers/CustomerRegUpdate";

import Request from "./Pages/Bookings/Request";

import RequestAllBookedJobsViewall from "./Pages/Bookings/RequestAllBookedJobsViewall";

import RequestAutoViewall from "./Pages/Bookings/RequestAutoViewall";
import RequestManualViewall from "./Pages/Bookings/RequestManualViewall";
import RequestPobViewall from "./Pages/Bookings/RequestPobViewall";
import RequestViewall from "./Pages/Bookings/RequestViewall";
import RequestCancelledViewall from "./Pages/Bookings/RequestCancelledViewall";

import RequestPreBookedViewall from "./Pages/Bookings/RequestPreBookedViewall";
import RequestCancelledCustomerViewall from "./Pages/Bookings/RequestCancelledCustomerViewall";
import RequestCompletedViewall from "./Pages/Bookings/RequestCompletedViewall";

import RequestTripsPendingViewall from "./Pages/Bookings/RequestTripsPendingViewall";
 

import Panics from "./Pages/Panic/PanicViewall";
import PanicHistorys from "./Pages/Panic/PanicHistoryViewall";


import PanicCustomers from "./Pages/Panic/PanicCustomerViewall";
import PanicHistoryCustomerHistorys from "./Pages/Panic/PanicHistoryCustomerViewall";

import AdminDashs from "./Pages/AdminDash";
 

//
import FooterPanic from "./Pages/FooterPanic";

const AdminDash =React.memo(AdminDashs);

const Requests =React.memo(Request);

function App() {
   

  return (
    <div > 
  
<FooterPanic />

 

  
        <Router>
        {/* <Suspense fallback={<div>Loading...

          <div class="loading"></div>
        </div>}> */}
        <Routes>


        <Route path='/monitor' element={<AdminDash/>} />
        <Route exact path='/userbookingrequest' element={<Requests  />} /> 
          <Route path='/' element={<Logins/>} />
         <Route path='*' element={<Logins/>} />
         
 
        <Route exact path='/sospanicdriver' element={<Panics  />} /> 
        <Route exact path='/sospanichistory' element={<PanicHistorys  />} /> 
       
        <Route exact path='/sospaniccustomer' element={<PanicCustomers />} /> 
        <Route exact path='/sospanichistorycustomer' element={<PanicHistoryCustomerHistorys />} />     
            
             
    <Route exact path='/userbookingtripspending' element={<RequestTripsPendingViewall/>} /> 
          <Route exact path='/userbookingrequestacceptedjobs' element={<RequestAllBookedJobsViewall/>} /> 

          <Route exact path='/userbookingpersononboard' element={<RequestPobViewall/>} /> 
          
    <Route exact path='/userbookingrequestautoappbookings' element={<RequestAutoViewall/>} />

      <Route exact path='/userbookingrequestofficebooking' element={<RequestManualViewall/>} />
           <Route exact path='/userbookingrequestviewdedetails' element={<RequestViewall/>} /> 
           
    <Route exact path='/userbookingrequesttripcompleted' element={<RequestCompletedViewall/>} /> 

           <Route exact path='/userbookingrequestcancelledbydriver' element={<RequestCancelledViewall/>} /> 
          
          
           <Route exact path='/userprebooked' element={<RequestPreBookedViewall/>} /> 	
           <Route exact path='/userbookingRequestcancelledbycustomer' element={<RequestCancelledCustomerViewall/>} /> 
           
  
           <Route exact path='/customernew' element={<CustomersReg  />} />  
           <Route exact path='/customerdetails' element={<CustomersView  />} />
           <Route exact path='/customerdetailsupdate' element={<CustomersViewUpdate  />} />  
 
           <Route exact path='/customerdetailspending' element={<PendingCustomersView  />} />
           <Route exact path='/customerdetailsapproved' element={<CustomersViewApproved  />} />
           <Route exact path='/customerdetailssuspended' element={<CustomersViewSuspended  />} />



          <Route exact path='/vehiclesunderpending' element={<PendingVehiclesDataViewAll/>} /> 
          <Route exact path='/vehiclesundersuspension' element={<SuspendedVehiclesDataViewAll/>} />  
          <Route exact path='/vehiclesunderactiveservice' element={<ApprovedVehiclesDataViewAll/>} />
            
            <Route exact path='/vehiclesregistrationview' element={<VehiclesDataViewAll/>} />
            <Route exact path='/vehiclesregistration' element={<VehiclesRegistrations/>} />
          <Route exact path='/vehiclesregistrationeditupdate' element={<VehiclesRegistrationsUpdate />} />

 
          <Route exact path='/assignacartoadriverview' element={<AssignedVehicleViews />} />
          <Route exact path='/assignacartoadriver' element={<AssignedDriverToVehicleViewAll />} />



            <Route exact path='/driversundersuspension' element={<Suspended_DriverDataViewAll/>} />
          <Route exact path='/driversunderpending' element={<Pending_DriverDataViewAll/>} />
          <Route exact path='/driversunderactiveservice' element={<Approval_DriverDataViewAll/>} />
           <Route exact path='/driversregistrationview' element={<DriverDataViewAll/>} />
            <Route exact path='/driversregistration' element={<DriversRegistrations/>} />
              <Route exact path='/driversregistrationeditupdate' element={<DriversRegistrationsUpdate/>} />
   </Routes>
   {/* </Suspense> */}
      </Router>

    </div>
     );
}

export default App;
