import  React, { useState , useEffect } from 'react';
import MenuTop from '../MenuTop';
// import '../Asserts/css/bootstrap.min.css';
// import '../Asserts/css/custom.css';
// import '../file.css';
 
import MenuSideBar from "../MenuSideBar";
import Footer  from '../Footer';

import UserRegFormCustomerRegUpdate from './CustomerRegUpdateForm';

const CustomerRegUpdate = () => {

  return (
    <>
  
<div class="wrapper">


{/* <div class="body-overlay"></div> */}

{/*   <!-- Sidebar  --> */}
        <MenuSideBar/>
   { /* <!-- Sidebar  --> */}
		

         {/* <!-- Page Content  --> */}
          <div id="content">
		
	       {/* Top Bar Manu */}
          <MenuTop />
         {/* Top Bar Manu End */}
			
			
			<div class="main-content">

 
			 {/* Content Analysis  */}
              {/* <MainContentAnalysis /> */}
              <UserRegFormCustomerRegUpdate />
            {/* Content Analysis End  */}


					 
	         {/* Content Main */}
             
              <div class="row">
              {/* <MainContent1 />  */}
               {/* Live Map */}
              {/* <MainContent /> */}



              </div>
         {/* Content Main End  */}
					
                
                    {/* Footer  */}
                     <Footer />
                     {/* Footer End */}
				 
					 
					</div>
                    {/* main content End */}
					 
        </div>{/* content end */}

    </div>   {/* wrapper end */}


    
    </>
  )
}

export default CustomerRegUpdate;