import React, { useState, useEffect, Redirect } from "react";
//
import firebase from "./DbConn"
import Swal from 'sweetalert2'
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";

import Modal from 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/modal';
// import axios from "axios";
import daudio from './wake-up-soundcustomer.mp3';


import Panicimage from './1.gif';


function FooterPanic() {

    const MINUTEMS = 7000;

    useEffect(() => {
      const interval = setInterval(() => {
 
        getpanicalertdriver();
    console.log('Logs every minute 9 seconds');
}, MINUTEMS);

return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
}, [])

function getpanicalertdriver(){
    firebase.database().ref("visiominicabs/panic/drivers/" ).on('value',(snapshot)=>{     
        snapshot.forEach(function(xdata){
        var dvkey=xdata.key;
        xdata.forEach(function(xdata2){

            console.log("Get the alert id=  " 
            + xdata2.val().status + "  Theeeeeeeeeee " + xdata2.key);

        if (xdata2.val().status==1)
        {

         console.log("Get the alert id=  " 
        + xdata2.val().status + "  2 - " + xdata2.key);

        var alarmnum =xdata2.val().status;
       document.getElementById("get_alert_id").value =parseFloat(alarmnum);
       localStorage.setItem("driverpanicid", xdata2.key);
       // document.getElementById("get_alert_id_user").value=
     
       ////console.log("Panoc alert =======        =======  " + xdata2.key);
        }
        else{
         document.getElementById("get_alert_id").value =0;
        }
     
         })
           })
      })
}


    useEffect(() => {
        var x = document.getElementById("myAudioid");
        function playAudio() {
            x.play();
            document.getElementById('myAudioid').play();
        }
        function pauseAudio() {
            x.pause();
        }
        function PanicFunctionAlerts() {
             
             var id=parseFloat(document.getElementById("get_alert_id").value);
            if (id == 1) {
                ////alert"Abc123456");
                playAudio();
                //  $('#myModal').modal('show');
                
            const myModal  = document.getElementById('myModal');
            const modal = new Modal(myModal); // Instantiates your modal
            modal.show(); 
             document.getElementById("get_alert_id").value="";
            } else if (id == 0) {
                pauseAudio();
                // $('#myModal').modal('hide');
            }

            setTimeout(PanicFunctionAlerts, 5000);
        }

        PanicFunctionAlerts();

        window.setInterval(function () { PanicFunctionAlerts(); }, 5000);

    })





    const MINUTE_MS = 30000;

    useEffect(() => {
      const interval = setInterval(() => {
          
     var  xy = document.getElementById("myAudioid2");
     function playAudio() {
         xy.play();
     }
     function pauseAudio() {
         xy.pause();
     }
     function PanicFunctionAlert() {
         // do whatever you like here
         //
     //    var id = 1;
 
          var id=parseFloat(document.getElementById("get_alert_id").value);
         if (id == 1) {
             ////alert"Abc123456");
             playAudio();
             //  $('#myModal').modal('show');
             
         const myModal  = document.getElementById('myModal');
         const modal = new Modal(myModal); // Instantiates your modal
         modal.show(); 
          document.getElementById("get_alert_id").value="";
         } else if (id == 0) {
             pauseAudio();
             // $('#myModal').modal('hide');
         }
 
         setTimeout(PanicFunctionAlert, 5000);
     }
 
 
         PanicFunctionAlert();
        console.log('Logs every minute');
      }, MINUTE_MS);
    
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
 
 
 


    useEffect(() => {
 
      const interval = setInterval(() => {
          
        var  xy = document.getElementById("myAudioid2");
        function playAudio() {
            xy.play();
        }
        function pauseAudio() {
            xy.pause();
        }
        function PanicFunctionAlertss() {
        
            var id=parseFloat(document.getElementById("get_alert_id").value);
            if (id == 1) {
                ////alert"Abc123456");
                playAudio();
                //  $('#myModal').modal('show');
                
            const myModal  = document.getElementById('myModal');
            const modal = new Modal(myModal); // Instantiates your modal
            modal.show(); 
             document.getElementById("get_alert_id").value="";
            } else if (id == 0) {
                pauseAudio();
                // $('#myModal').modal('hide');
            }
    
             window.setTimeout(PanicFunctionAlertss, 15000);
        }
    
    
            PanicFunctionAlertss();
        }, 10000);
        return () =>  clearInterval(interval);
      }, []);









    function Update_submit_panic() {
        const get_alert_id_user = document.getElementById("get_alert_id_user").value;
        var status=0;
              firebase
                  .database()
                  .ref()
                  .child("visiominicabs/panic/drivers/" + get_alert_id_user)
                  .update({
                    status: status
                  });
          }
      



    return (

        <div class="container-fluid">
 
 
 
            <div class="hideall">
                <audio id="myAudioidpanic" loop  controls autoplay >
                    <source   src= {daudio} type="audio/mp3"
                    />
                </audio>

            </div>


            <div class="hideall">
                <audio id="myAudioid" loop  controls autoplay >
                    <source   src= {daudio} type="audio/mp3"
                    />
                </audio>

            </div>

            
            <div class="hideall">
                <audio id="myAudioid2" loop   controls autoplay >
                    <source   src= {daudio} type="audio/mp3" />
                </audio>

            </div>
            


            

{/* <!-- Modal --> */}
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">
{/* 
    <!-- Modal content--> */}
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
     
      </div>
      <div class="modal-body">
        <p>Danger alert.</p>
        <img src={Panicimage} />
      </div>
      <div class="modal-footer">
      <a href="/sospanicdriver" id="update_submit_panics"
      // onclick={Update_submit_panic} onClick={() => window.open("/vehiclesregistrationeditupdate")}
        class="btn btn-success">
         View Panic alert  </a>
     
      </div>
    </div>

  </div>
</div>





             

<input type="hidden" id="get_alert_id"                class="form-control"  />
<input type="hidden" id="get_alert_id_user"           class="form-control" />

<input  id="datecreated2"   type="hidden"  style={{display: "none"}}  />

<input  id="datecreated"   type="hidden"  style={{display: "none"}}  />

        </div>




    );
}


export default FooterPanic; 