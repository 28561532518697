

import firebases from "../DbConn"
// import bootstrap from 'bootstrap/dist/css/bootstrap.min.css';
 import $ from "jquery";
// import moment from 'moment';
const google = window.google;




const initiatlizemap = async () => {
     
     // GetRoute(); 
        var map = new google.maps.Map(document.getElementById('booking_mapview'), {
          center: {lat: 5.8937, lng: 0.2629},
          zoom: 9,
          mapTypeId: 'roadmap'
        });
    
        // Create the search box and link it to the UI element.
        var input = document.getElementById('txtSource');
        var searchBox = new google.maps.places.SearchBox(input);
     //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
        
        var inputDes = document.getElementById('txtDestination');
        var searchDesBox = new google.maps.places.SearchBox(inputDes);
     //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputDes);
        
        var searchIcon = document.getElementById('icon');
        var searchIconBox = new google.maps.places.SearchBox(searchIcon); 
      //  map.controls[google.maps.ControlPosition.TOP_LEFT].push(searchIcon); 
        
        var stepInfo = document.getElementById('stepInfo');
        var searchIconBox = new google.maps.places.SearchBox(stepInfo); 
        map.controls[google.maps.ControlPosition.LEFT_CENTER].push(stepInfo);
        
        var places = searchBox.getPlaces();
        var placesDes = searchDesBox.getPlaces();
        var markers = [];
        
        var pointA = new google.maps.LatLng(5.3584, 0.18029),
        pointB = new google.maps.LatLng(5.9546, 0.3309),
        myOptions = {
          zoom: 7,
          center: pointA
        },
        // Instantiate a directions service.
        directionsService = new google.maps.DirectionsService,
        directionsDisplay = new google.maps.DirectionsRenderer({
          map: map
        });
        var control = document.getElementById('convert');
        google.maps.event.addDomListener(control, 'click', function() { 
            getMarker(directionsService, directionsDisplay, map);
        }); 
        google.maps.event.addDomListener(control, 'keyup', function(e) {
            if (e.keyCode == 13) {
             getMarker(directionsService, directionsDisplay, map);
            }
        });     
    }
    function getMarker(directionsService, directionsDisplay, map){ 
        calculateAndDisplayRoute(directionsService, directionsDisplay, map);
    }
    function calculateAndDisplayRoute(directionsService, directionsDisplay, map) {
        var startPoint = document.getElementById('txtSource').value;
        var endPoint = document.getElementById('txtDestination').value;
        directionsService.route({
          origin: startPoint,
          destination: endPoint,
          travelMode: google.maps.TravelMode.DRIVING
        }, function(response, status) {
          if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
            showSteps(response, map);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        });
    }
    function showSteps(directionResult, map) {
      var jd="";
        var markerArray = []; 
        var myRoute = directionResult.routes[0].legs[0];
        document.getElementById("stepInfo").style.display = "block";
        var text = '<div class="routesegment" ><div style="width: 80%; float:left;">Route Segment</div>' 
        text += '<div class="closeroute"><a onclick="closeroute()" style="color:#8dd4ff;">X</a></div></div>';
        text += '<div class="routeinfo"><div class="routedirections"><b>Duration:</b> '+myRoute.duration.text + ','
        text += '    <b>Distance:</b> ' + myRoute.distance.text+'<br/>';
        var abc=myRoute.distance.text;
        var abcc=myRoute.duration.text;
        for (var i = 0; i < myRoute.steps.length; i++) {
          var marker = markerArray[i] = markerArray[i] || new google.maps.Marker;
          marker.setMap(map);
          marker.setPosition(myRoute.steps[i].start_location);
          text += myRoute.steps[i].instructions+'<br/>';
        }
        text += '</div></div>'; 
         document.getElementById('stepInfo').innerHTML = text;
        document.getElementById('abbc').innerHTML = abc;
        document.getElementById('abbcc').innerHTML = abcc;
        document.getElementById('abbccc').value = abcc;
    }

 
   
    
const initiatlizemaps = async () => { 
 
getthesemap();
}

function getthesemap(){
 // alert(123)
 
 document.getElementById("hidediv").style.display = 'inline';
  var map;
var source, destination;
var directionsDisplay;
var directionsService = new google.maps.DirectionsService();
google.maps.event.addDomListener(window, 'load', function() {
    new google.maps.places.SearchBox(document.getElementById('txtSource'));
    new google.maps.places.SearchBox(document.getElementById('txtDestination'));
    directionsDisplay = new google.maps.DirectionsRenderer({ 'draggable': true });
});

google.maps.event.addDomListener(window, 'load', initialize);

function initialize() {
    var input = document.getElementById('txtSource');
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', function() {
        var place = autocomplete.getPlace();
        // place variable will have all the information you are looking for.
        $('#lat').val(place.geometry['location'].lat());
        $('#long').val(place.geometry['location'].lng());

    });


    var inputs = document.getElementById('txtDestination');
    var autocompletes = new google.maps.places.Autocomplete(inputs);
    autocompletes.addListener('place_changed', function() {
        var places = autocompletes.getPlace();
        // place variable will have all the information you are looking for.
        $('#lat2').val(places.geometry['location'].lat());
        $('#long2').val(places.geometry['location'].lng());

    });



}
// function initialize() {
//   debugger;
  directionsDisplay =new google.maps.DirectionsRenderer();
  var route =new google.maps.LatLng(5.5941, 0.2376);
  var mapOptions = {
      zoom: 10,
      center: route
  };
  map =new google.maps.Map(document.getElementById('booking_mapview'), mapOptions,

  
 document.getElementById("hidediv").style.display = 'none'

  
  );
  directionsDisplay.setMap(map);
  //google.maps.event.addDomListener(document.getElementById('GetRoute'),'click', GetRoute);
//}

source = document.getElementById("txtSource").value;
destination = document.getElementById("txtDestination").value;

var request = {
    origin: source,
    destination: destination,
    travelMode: google.maps.TravelMode.DRIVING
};

directionsService.route(request,function (response, status) {
    debugger;

    if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
    }

});

var service =new google.maps.DistanceMatrixService();
service.getDistanceMatrix({
    origins: [source],
    destinations: [destination],
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.METRIC,
    avoidHighways:false,
    avoidTolls:false
},function (response, status) {
    if (status == google.maps.DistanceMatrixStatus.OK && response.rows[0].elements[0].status !="ZERO_RESULTS") {
      //   var distance = response.rows[0].elements[0].distance.text;
      //   var duration = response.rows[0].elements[0].duration.text;
      //   var dvDistance = document.getElementById("dvDistance");
      //   dvDistance.innerHTML ="";
      //   dvDistance.innerHTML +="Distance Is: " + distance +"<br />";
      //   dvDistance.innerHTML +="Duration Is:" + duration;
      // //  alert(dvDistance.innerHTML);


      var distance = response.rows[0].elements[0].distance.text;
      var distance100 = response.rows[0].elements[0].distance.value;
      var total = distance100 / 1609.34;
      var duration = response.rows[0].elements[0].duration.text;
      var dvDistance = document.getElementById("dvDistance");
      var dvDuration = document.getElementById("dvDistance2");
      // var dvDistance2 = document.getElementById("myInput").value;
      dvDistance.innerHTML = "";
     // dvDistance2.innerHTML = "";
      dvDistance.innerHTML += "Distance: " + distance + "  &nbsp; &nbsp; ";
      dvDistance.innerHTML += "Duration: " + duration + "&nbsp;";
      document.getElementById("Bookingduration").value = "";
      document.getElementById("BookingDistance").value = "";
      document.getElementById("BookingDistance").value = distance;
      document.getElementById("Bookingduration").value = duration;

      const sDistance =   distance;
      const resultdistance = sDistance.replace(",",".").replace(/[^0-9&.]/g,''); 
      document.getElementById("dvDistances").value = resultdistance;

     // document.getElementById("accountdistancing").value = resultdistance;


      const durations =   duration;
      const resultduration = durations.replace(",",".").replace(/[^0-9&.]/g,'');
      document.getElementById("dvdurations").value = resultduration;




      var total2 = total.toFixed(2);


      // document.getElementById('accountdistance').value = "";
      // document.getElementById('defaultamount').value = "";
      // document.getElementById('amountafters').value =  "";
  //    document.getElementById('AccountSetup_id_old').value =  "";
  
  
        var a = 0.8 //parseFloat(document.getElementById('defaultdistance').value);
      // var b = parseFloat(document.getElementById('defaultamount').value);
      // var c1 = parseFloat(document.getElementById('amountafters').value);

      //  document.getElementById('AccountSetup_id_old').value = AccountSetup_id;



      // var a = 1.5;
        var b = 6.0;
     //   var b = 1.40;
       var c1 = (b) + (0.3 * (total2 - a));



      var c = (b) + (c1 * (total2 - a)) - 10;


      if (total2 <= a) {
          var result = b;
          // document.getElementById('accountdistance').value = "";
          // document.getElementById('defaultamount').value = "";
          // document.getElementById('amountafters').value =  "";http://localhost:3000/userbookingrequest
          // document.getElementById('AccountSetup_id_old').value =  "";

          document.getElementById("myInput").value = "";
          document.getElementById("BookingCost").value = "";
          
          document.getElementById("estimateamount").innerHTML = "Est. Amt=GHC" + result.toFixed(0);
          document.getElementById("myInput").value = "Est. Amt=GHC" + result.toFixed(0);
          document.getElementById("BookingCost").value = result.toFixed(0);
         document.getElementById("hidediv").style.display = 'none';

      } 
      else {

          var result = c;

          // document.getElementById('accountdistance').value = "";
          // document.getElementById('defaultamount').value = "";
          // document.getElementById('amountafters').value =  "";
          // document.getElementById('AccountSetup_id_old').value =  "";

          document.getElementById("myInput").value = "";
          document.getElementById("BookingCost").value = "";
          document.getElementById("estimateamount").innerHTML = "Est. Amt=GHC" + result.toFixed(0);
        
          document.getElementById("myInput").value = "Est. Amt=GHC" + result.toFixed(0);
          document.getElementById("BookingCost").value = result.toFixed(0);
 
          document.getElementById("hidediv").style.display = 'none';

      }
     }else {
     console.log("Your Request For Distance Not Available");
    }
});





}






const initiatlizemapsChange = async () => { 
// 
const button = document.querySelector("#txtSource");
button.addEventListener('change', doThing);
function doThing(){
  console.log('Horray! Someone wrote "' + this.value + '"!');
}

const txtSourcebuttons = document.getElementById('txtSource');
txtSourcebuttons.addEventListener('input', function() { 
 // console.log('hello world');
getthesemap();
});

const txttxtDestination = document.getElementById('txtDestination');
txttxtDestination.addEventListener('input', function() { 
 // console.log('hello world');
getthesemap();
});


document.querySelector('#customers_lists').addEventListener("change", function() {
  getthesemap();
  
});


document.querySelector('#driver_list').addEventListener("change", function() {
  getthesemap();
  
});

document.querySelector('#carType').addEventListener("change", function() {
  getthesemap();
  
});


}








   
export { initiatlizemaps,initiatlizemapsChange };
