import React , {useState,useEffect}  from "react"; 

import {initialize  } from './maindash_maps.js';
import {initializestatistics  } from './maindash_mapsStatistics.js';
  const MainContent1 = () => {

      
  useEffect(() => {
    //window.setTimeout(function () {   initialize(); }, 4000) ;
   window.setTimeout(function () {   initialize(); }, 7000) ;
   
  //  window.setTimeout(function () {   initializestatistics(); }, 2000) ;
   })

   useEffect(() => {
  //  window.setTimeout(function () {   initialize(); }, 10000) ;
   
   window.setTimeout(function () {   initializestatistics(); }, 10000) ;
   })
   

   

   const [loading, setLoading] = useState(false)

   useEffect(() => {
       setLoading(true)
         
        // window.setTimeout(function () {   LoadGMaps(); }, 4000) ;
      })
  return (

 

 
<div class="col-lg-7 col-md-12">




<div id="overlay">
{/* {loading ?  (    <div   class="loading"></div>      ) : (   <h6></h6> )} */}
{/* <div   class="loading"></div> */}
</div>



    <div class="card" style={{minHeight: "485px"}}>
        <div class="card-header card-header-text">
  
  <h5>analytics dashboard</h5>
   




<div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body dashboard-tabs p-0">
                  <ul class="nav nav-tabs px-4" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="overview-tab" data-toggle="tab" 
                      href="#overview" role="tab" aria-controls="overview" aria-selected="true">Overview Today</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="drivers-tab" data-toggle="tab" href="#drivers"
                       role="tab" aria-controls="drivers" aria-selected="false">Drivers</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="customers-tab" data-toggle="tab" href="#customers"
                       role="tab" aria-controls="customers" aria-selected="false">Customers</a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" id="vehicles-tab" data-toggle="tab" href="#vehicles"
                       role="tab" aria-controls="vehicles" aria-selected="false">Vehicles</a>
                    </li>


                    <li class="nav-item">
                      <a class="nav-link" id="bookings-tab" data-toggle="tab" href="#bookings"
                       role="tab" aria-controls="bookings" aria-selected="false">Bookings</a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" id="trips-tab" data-toggle="tab" href="#trips"
                       role="tab" aria-controls="trips" aria-selected="false">Trips</a>
                    </li>
                        
                  </ul>
                  <div class="tab-content py-0 px-0">
                    <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">

                      
                      <a href="main_onlinevehicleslinks">
                      <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-bus fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Live Vehicles</small>
                            <h5 class="mr-2 mb-0"><span id="onlinevehicleswithdrivers"></span></h5>
                          </div>
                        </div>
                      </a>

                      
                      <a href="bookings_jobsinprogress_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-car fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">POB</small>
                          
                            <h5 class="mr-2 mb-0">  <span id="overviewbookedspob"></span></h5> 
                            
                          </div>
                        </div>
                      </a>

                      <a href="trips_booked_links">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-hourglass-2 fa-2x  mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Pre-Booked</small>
                            <h5 class="mr-2 mb-0"> <span id="overviewbookedspending"></span></h5>
                          </div>
                        </div>
                        </a>

                        <a href="trips_completedlinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-circle-o fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Complete</small>
                            <h5 class="mr-2 mb-0"> <span id="overviewbookedscompleted"></span></h5>
                          </div>
                        </div>
                        </a>


                        <a href="trips_cancelledlinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Cancelled</small>
                            <h5 class="mr-2 mb-0"> <span id="overviewbookedscancelled"></span></h5>
                          </div>
                        </div>
                        </a>
 
                      </div>
                    </div>

                    
                    <div class="tab-pane fade" id="drivers" role="tabpanel" aria-labelledby="drivers-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
 
                      <a href="permissionsdriverlink">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-users fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">All Registered Driver</small>
                            <h5 class="mr-2 mb-0"><span id="onlinealldrivers"></span></h5>
                          </div>
                        </div>
                      </a>
                      <a href="drivers_registrationview_approved_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa 	fa fa-check  fa-2x mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Approved Drivers</small>
                            <h5 class="mr-2 mb-0"> <span id="onlineactivevehiclesapprovred"></span> </h5>
                          </div>
                        </div>
                      </a>

                      <a href="drivers_registrationview_pending_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa  fa-deaf fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Pending Approval</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinependingpending"></span></h5>
                          </div>
                        </div>
                      </a>


                      <a href="drivers_registrationview_suspended_link">
                        <div class="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Suspended</small>
                            <h5 class="mr-2 mb-0"><span id="onlinesuspendeddrivers"></span></h5>
                          </div>
                        </div>
                        </a>
                        
                      </div>
                    </div>
                    
 
                    <div class="tab-pane fade" id="customers" role="tabpanel" aria-labelledby="customers-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        
                        
                      <a href="main_customers_allregistered_link">
                      <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-users fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Total Registered Customers</small>
                            <h5 class="mr-2 mb-0"><span id="onlineallcustomers"></span></h5>
                          </div>
                        </div>
                      </a>


                      <a href="main_customers_approval_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa 	fa fa-check  fa-2x mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Approved     </small>
                            <h5 class="mr-2 mb-0"> <span id="onlineactivecustomers"></span> </h5>
                          </div>
                        </div>
                      </a>


                      <a href="main_customers_pending_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa  fa-deaf fa-2x mr-3 icon-lg text-primary"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Pending Approval</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinependingcustomers"></span></h5>
                          </div>
                        </div>
                      </a>


                      <a href="main_customers_suspended_link">
                        <div class="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Suspended</small>
                            <h5 class="mr-2 mb-0"><span id="onlinesuspendedcustomers"></span></h5>
                          </div>
                        </div>
                      </a>
                      </div>
                    </div>
                    



                    <div class="tab-pane fade" id="vehicles" role="tabpanel" aria-labelledby="vehicles-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                      
                      <a href="main_vehicle_registered_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-truck fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">All vechicles</small>
                            <h5 class="mr-2 mb-0"><span id="onlineallvehicleInfo"></span></h5>
                          </div>
                        </div>
                      </a>

                      
                      <a href="main_vehicle_approval_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-car fa-2x mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Approved Vehicles</small>
                            <h5 class="mr-2 mb-0"><span id="onlineallvehicleInfoapproved"></span></h5>
                          </div>
                        </div>
                      </a>

                      
                      <a href="main_vehicle_inactive_link.php">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Pending Vehicles</small>
                            <h5 class="mr-2 mb-0"><span id="onlineallvehicleInfoapprovedpending"></span></h5>
                          </div>
                        </div>
                      </a>


                      <a href="drivers_registrationview_suspended_link">
                        <div class="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Suspended</small>
                            <h5 class="mr-2 mb-0"><span id="theonlinesuspendedvehicles"></span></h5>
                          </div>
                        </div>
                        </a>

                      </div>
                    </div>
 



                    
                    <div class="tab-pane fade" id="bookings" role="tabpanel" aria-labelledby="bookings-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">

                      <a href="main_bookining_all_link">
                      <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-window-restore fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">All Bookings </small>
                            <h5 class="mr-2 mb-0"><span id="onlineallbooking_all"></span></h5>
                          </div>
                        </div>
                      </a>
 
                      <a href="main_online_booked_nowlinks">
                       <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-car fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">POB</small>
                            <h5 class="mr-2 mb-0">  <span id="onlineBookingStatusbookedspobontrip"></span></h5>
                          </div>
                        </div>
                      </a>

                      
                      
                      <a href="trips_booked_manual_links">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-user-phone fa-2x mr-3 icon-lg text-primary"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Manual Bookings</small>
                            <h5 class="mr-2 mb-0">  <span id="onlineBookingStatusonline_officebooking_only"></span></h5>
                          </div>
                        </div>
                      </a>
                      <a href="trips_booked_manual_app_links">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-user fa-2x mr-3 icon-lg text-primary"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">App Bookings</small>
                            <h5 class="mr-2 mb-0">  <span id="onlinebookingsby_customerapp"></span></h5>
                          </div>
                        </div>
                      </a>

                      
                      
                      <a href="trips_pendinglinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-hourglass-2 fa-2x  mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Pending</small>
                            <h5 class="mr-2 mb-0"> <span id="onlineBookingStatuspending"></span></h5>
                          </div>
                        </div>
                      </a>


                      <a href="trips_completedlinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-circle-o fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Complete</small>
                            <h5 class="mr-2 mb-0"> <span id="onlineBookingStatuscomplete"></span></h5>
                          </div>
                        </div>
                      </a>


                      <a href="trips_cancelledlinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Cancelled by Driver</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinebookingstatus_cancelledsbydriver"></span></h5>
                          </div>
                        </div>
                      </a>

                      <a href="trips_cancelledlinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Cancelled by Customer</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinebookingstatus_cancelledsbycustomer"></span></h5>
                          </div>
                        </div>
                      </a>
                         
                        </div>
                    </div>   



                    
                    
                    <div class="tab-pane fade" id="trips" role="tabpanel" aria-labelledby="trips-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        
                        
                      <a href="main_onlinevehicleslinks">
                      <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-window-restore fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">All Active Trips </small>
                            <h5 class="mr-2 mb-0"><span id="onlineallactivetrip_pob"></span></h5>
                          </div>
                        </div>
                    </a>

                    
                    <a href="trips_booked_links">

                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-car fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Scheduled Trips</small>
                            <h5 class="mr-2 mb-0">  <span id="onlineonlineScheduledTrips2"></span></h5>
                          </div>
                        </div>
                    </a>

                      

                    <a href="trips_completedlinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-hourglass-2 fa-2x  mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Pending</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinetripspendingStatus1"></span></h5>
                          </div>
                        </div>
                    </a>

                    
                    <a href="trips_completedlinks">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-circle-o fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Complete</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinetripscompleteStatus2"></span></h5>
                          </div>
                        </div>
                    </a> 
                         
                        </div>
                    </div>   



                    <div class="tab-pane fade" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        
                      <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-suitcase fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">All Deliveries </small>
                            <h5 class="mr-2 mb-0"><span id="onlinealldelivery1"></span></h5>
                          </div>
                        </div>

                      

                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-shopping-bag  fa-2x mr-3 icon-lg text-primary"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Manual Delivery Booked </small>
                            <h5 class="mr-2 mb-0">  <span id="onlinemanualbookingdeliverybooked"></span></h5>
                          </div>
                        </div>

                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-hourglass-2 fa-2x  mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Pending</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinedeliveryStatuspending1"></span></h5>
                          </div>
                        </div>
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-circle-o fa-2x mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Complete</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinedeliveryStatuscomplte"></span></h5>
                          </div>
                        </div>
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="fa fa-close fa-2x mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Cancelled</small>
                            <h5 class="mr-2 mb-0"> <span id="onlinedeliveryStatus100"></span></h5>
                          </div>
                        </div>
                         
                        </div>
                    </div>   


 
                    <div class="tab-pane fade" id="account" role="tabpanel" aria-labelledby="account-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        <a href="driver_account_adaylink">
                        <div id="okayb" class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                        
                          <i class="mdi mdi-currency-eur mr-3 icon-lg text-warning"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Per Daily Income</small>
                            <h5 class="mr-2 mb-0"><span id="onlinCosttotals100day"></span> </h5>
                           </div>
                         
                        </div>
                        </a>

                        <a href="driver_account_a_week_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-currency-eur mr-3 icon-lg text-success"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Per Weekly Income</small>
                            <h5 class="mr-2 mb-0"><span id="onlinCosttotaldateweek"> </span></h5>
                          </div>
                        </div>
                        </a>


                        <a href="driver_account_a_monthly_link">
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-currency-eur mr-3 icon-lg text-info"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Per Monthly Income</small>
                            <h5 class="mr-2 mb-0"><span id="onlinCosttotaldatemonth"> </span></h5>
                          </div>
                        </div>
                        </a>

                        
                        <a href="driver_account_a_yearly_link">
                        <div class="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-currency-eur mr-3 icon-lg text-danger"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Per Yearly Income</small>
                            <h5 class="mr-2 mb-0"><span id="onlinCosttotaldateyear"> </span></h5>
                          </div>
                        </div>
                        </a>

                      </div>
                    </div>


 

                  </div>
                </div>
              </div>
            </div>
          </div>





   </div>
        <div class="card-content table-responsive">
            <div id="map_canvas"  style={{width: "100%", height: "317px"}}></div>
        </div>

  </div>  
</div>
 
 
 




)
}


export default MainContent1;