
//import firebaseb from './Drivers/Pages/Auth/userauth';
import firebase from "../DbConn"
import Swal from 'sweetalert2'
import swal from 'sweetalert';
// import { ToastContainer, toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";
import { Field } from "formik";
//import moment from 'moment';

//const firebase=9;


const updatedata = localStorage.getItem("driveredits");
const vehicledata = localStorage.getItem("driveredits");
const Driverupadteid = localStorage.getItem("driveredits");



const SearchforCustomer = async () => {

    //  function  searchingvehicle() {
        document.getElementById("hidediv").style.display = 'inline';

    var queryRef = firebase.database().ref("akwaaba/customers/");

    queryRef.on('value', function (snapshot) {
        snapshot.forEach(function (data) {

            if (data.key == Driverupadteid) {
                const datakey=data.key;
                // //alertxdata.key + " " + data  );
                 
                document.getElementById("driver_first_name").value = data.val().firstName;
                document.getElementById("driver_last_name").value = data.val().lastName;
                document.getElementById("driver_email").value = data.val().email;
                document.getElementById("driver_phone").value = data.val().phone;
                //
                // document.getElementById("myimg").src = data.val().profilePhoto; 
                // /*
                document.getElementById("emergencyContact").value = data.val().emergencyContact;
              
                var driveraddress;
                if (data.val().driverAddress == null || data.val().driverAddress == "") {
                    driveraddress = "No Record";
                }
                else
                {
                    driveraddress = data.val().driverAddress;
                }


                document.getElementById("driveraddress").value = data.val().customerAddress;
                document.getElementById("driveridupdatecarid").value = datakey;
                document.getElementById("driveridupdate").value = datakey;
 
                window.setTimeout(function () {
                searchimg();
            }, 1000);

                
                
            }
        })

    }, function (error) {
        //alert"Error: " + error.code);
    });




}





//




   //
   function searchimg() {
    
const updatedatas = localStorage.getItem("driveredits");
  ////alertdata);

 // const  searchimg = async (updatedata) => {
    var queryRef = firebase.database().ref("akwaaba/customers/");
       
                        queryRef.on('value', function(snapshot) {
  
                //          //alertxdata.key + " " + data  );
  
                          snapshot.forEach(function(xdata){
                           
                          if(xdata.key==updatedatas ){
                          //  //alertxdata.key + " " + data  );
                           var val = xdata.val();
       
    document.getElementById("previewImg").value ="";    
      
    if(xdata.val().profilePhoto !=null)
   {
  
   // document.getElementById("cimg").src = xdata.val().profilePhoto;
   document.getElementById("previewImg").src = xdata.val().profilePhoto; 
   
   } 
  
    document.getElementById("hidediv").style.display = 'none';
  // */
   }
                          })
  
     }, function (error) {
         //alert"Error: " + error.code);
      });
     
    
  }
  







     
 






const CustomerRegUpdatehere = async () => {


    submitwithupdatecustomers() 


};




export { SearchforCustomer, CustomerRegUpdatehere };







// Register new car
   
 
// function submitwithupdatecustomers() {

    


const submitwithupdatecustomers= async () => {
    document.getElementById("hidediv").style.display = 'none';


   // console.log(12345)
    //  swal("Submit!", "Done!", "success");
   // vehicleinfowithdrivernew1();
    // var driveraddress = document.getElementById("driveraddress").value;
   // var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;


    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
          || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {


        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="#">Error “Please fill out all required fields.</a>'
        })


    } else {


        // Loading page 
        document.getElementById("hidediv").style.display = 'inline';

        // Field 


        var driver_last_name = document.getElementById("driver_last_name").value;
        var firstname = document.getElementById("driver_first_name").value;
        var email = document.getElementById("driver_email").value;
        var emergencyContact = document.getElementById("emergencyContact").value;


       // var password = document.getElementById("driver_password").value;
        var driver_phone = document.getElementById("driver_phone").value;
        var driveraddresss = document.getElementById("driveraddress").value;

 
         var datecreated = document.getElementById("datecreated").value;
        var status = "pending"
        var Enddate = "NA"

        var userName= firstname + " " + driver_last_name;

        const keys= localStorage.getItem("driveredits");

                firebase.database().ref('akwaaba/customers/' + keys).update({
 
                    Enddate: Enddate,
                    customerAddress: driveraddresss,
                      email: email,
                    firstName: firstname,
                    lastName: driver_last_name,
                    phone: driver_phone,
                    emergencyContact: emergencyContact,

                    // token: key,

                    // user_customer_key: key,
                    // customerid: key,
                      userName:userName,
                       createdAt: datecreated,
                   // status: status

                })
                .then(success =>{
                    
                
                   // profilePhototure(key);
  
               const key= localStorage.getItem("driveredits");
             //     
              
                   setTimeout(function () {   Setinsertimg() }, 1000);
            
                } ) 
                .then(async success => {
                 
                    const Toast = Swal.mixin({
                       // ld(),
                        toast: true,
                        position: 'top-right',
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 8500,
                        timerProgressBar: true
                    })
                    await Toast.fire({
                        icon: 'success',
                        title: ' Customer  registration',
                        text: 'Successful!',
                        footer: '<a href="#" style="color:white;font-weight:bold"> “Please wait , records Saing...s.</a>'
                        
                    })

                    document.getElementById("hidediv").style.display = 'none';
                 setTimeout(function () { window.location = "/customerdetails"; }, 900);
              // clearandresetform();

                }
                )
               .catch(async function ( error) {
                
                


                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-right',
                    iconColor: 'white',
                    customClass: {
                    popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 5500,
                    timerProgressBar: true
                  })
                  await Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                   // text: 'Something went wrong!',
                    footer: '<a href="#" style="color:white;font-weight:bold">Error “Driver data already in use by another account!.</a>'
                  })


                  document.getElementById("hidediv").style.display = 'none';






            })
 
    }
}
 
 



function Setinsertimg(){
    const key= localStorage.getItem("driveredits");
    //      insertimg(key)
         // setTimeout(function () {   insertimg(key)}, 2000);
   
  
    const filechk = document.querySelector("#profile_pics").files[0];
if (filechk ==null)
{

////console.log("now file" + key);
}
else{


const file = document.querySelector("#profile_pics").files[0];
 const metadata = {
contentType: file.type,
  };
var Uploadtask;

 var uniq = 'id' + (new Date()).getTime();
//uploading files
Uploadtask=firebase.storage().ref('akwaaba/customers/' + uniq ).put(file, metadata);

 Uploadtask.on("state_changed", function(snapshot) {
     var percentage =  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
     document.getElementById("progress").value = percentage;
   
   },
    function error() {
     //alert"error uploading file");
   },

 function(){
   Uploadtask.snapshot.ref.getDownloadURL().then(function(url) {
  
   firebase
     .database()
     .ref()
     .child('akwaaba/customers/'+ key)
     .update({
       profilePhoto: url,
     });

   });
  // clearform();
        }
     );

}

}