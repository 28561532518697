

import firebases from "../DbConn"
// import bootstrap from 'bootstrap/dist/css/bootstrap.min.css';
 import $ from "jquery";
// import moment from 'moment';
const google = window.google;
//const google = window.google = window.google ? window.google : {}
//console.log(moment().format());
 
// var script = document.createElement('script');
// script.type = 'text/javascript';
// script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD4pU3iPmaZW0mu5Sfu91Ar8x1PAgIx3Ck&libraries=places&callback=initMap';    

// document.head.appendChild(script);
 
       
let map, trackers = {};





// var source, destination;
// var directionsDisplay;
// var directionsService = new google.maps.DirectionsService();
// google.maps.event.addDomListener(window, 'load', function() {
//     new google.maps.places.SearchBox(document.getElementById('txtSource'));
//     new google.maps.places.SearchBox(document.getElementById('txtDestination'));
//     directionsDisplay = new google.maps.DirectionsRenderer({ 'draggable': true });
// });

// google.maps.event.addDomListener(window, 'load', initialize);






function initMap() {

   

    let mapOptions = {
        // zoom: 8,
        zoom: 7,
        center: new google.maps.LatLng(5.5697816512578, -0.21317566815868),
        mapTypeId: google.maps.MapTypeId.ROADMAP
    // 
      };
     map = new google.maps.Map(document.getElementById("booking_mapview"), mapOptions)

}

const initializeAutocomplete = async () => {
    var input = document.getElementById('txtSource');
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', function() {
        var place = autocomplete.getPlace();
        // place variable will have all the information you are looking for.
        $('#lat').val(place.geometry['location'].lat());
        $('#long').val(place.geometry['location'].lng());

    });


    var inputs = document.getElementById('txtDestination');
    var autocompletes = new google.maps.places.Autocomplete(inputs);
    autocompletes.addListener('place_changed', function() {
        var places = autocompletes.getPlace();
        // place variable will have all the information you are looking for.
        $('#lat2').val(places.geometry['location'].lat());
        $('#long2').val(places.geometry['location'].lng());

    });


}





const getthemaps = async () => {
    
 //
   GetRoute();
}






function GetRoute() {

    // //alert12345)
 
var source, destination;
var directionsDisplay;
var directionsService = new google.maps.DirectionsService();
// google.maps.event.addDomListener(window, 'load', function () {
//     new google.maps.places.SearchBox(document.getElementById('txtSource'));
//     new google.maps.places.SearchBox(document.getElementById('txtDestination'));
//     directionsDisplay = new google.maps.DirectionsRenderer({ 'draggable': true });
// });
 
// function GetRoute() {
    var mumbai = new google.maps.LatLng(5.5697816512578, -0.21317566815868);
    var mapOptions = {
        zoom: 7,
        center: mumbai
    };
    map = new google.maps.Map(document.getElementById('booking_mapview'), mapOptions);
    directionsDisplay.setMap(map);
    directionsDisplay.setPanel(document.getElementById('dvPanel'));
 
    //*********DIRECTIONS AND ROUTE**********************//
    source = document.getElementById("txtSource").value;
    destination = document.getElementById("txtDestination").value;
 
    var request = {
        origin: source,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
    };
    directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
        }
    });
 
    //*********DISTANCE AND DURATION**********************//
    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix({
        origins: [source],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false
    }, function (response, status) {
        if (status == google.maps.DistanceMatrixStatus.OK && response.rows[0].elements[0].status != "ZERO_RESULTS") {
            var distance = response.rows[0].elements[0].distance.text;
            var duration = response.rows[0].elements[0].duration.text;
            var dvDistance = document.getElementById("dvDistance");
           dvDistance.innerHTML = "";
            dvDistance.innerHTML += "Distance: " + distance + "<br />";
            dvDistance.innerHTML += "Duration:" + duration;
 
        } else {
            alert("Unable to find the distance via road.");
        }
    });
}
 
 // }
 











// Customers

const initializeCustomers = async () => {

 //   var database = firebase.database();
//  database.ref('akwaaba/customers/').orderByChild("status").equalTo("approved").on('value', function(snapshot){
    firebases.database().ref('akwaaba/customers/').on('value', function(snapshot) {

    if (snapshot.exists()) {
        var cvehi_list = '';
        snapshot.forEach(function(data) {
            var val = data.val();
            if(val.firstName !=null && (val.status==null || val.status=="approved"|| val.status=="Approved"))
            {
                cvehi_list += '<option value="' + data.key + '">' + val.firstName + ' ' + val.lastName + ' - ' + ' ' + val.phone + ' ' + '</option>';
              
            }else
            {
            //console.log("val.status)");
            }
        
        });
        //userName

        //document.getElementById("customers_lists").innerHTML = "";
        
        $('#customers_lists')
        .empty()
        .append('<option selected="selected" value="">Select a New Customer</option>');
    $('#customers_lists').append(cvehi_list);
        $('#customers_lists').find('option:not(:first)').remove();
        $('#customers_lists').append(cvehi_list);

    }
});







// function customer_mainte_event() {
  //  const customers_lists=document.getElementById("customers_lists")
    document.getElementById("customers_lists").addEventListener('change',function(e){
        let keyvalue="";
      keyvalue=this.value;
    //alert(keyvalue);
    //Get text or inner html of the selected option
    // var d = document.getElementById("customers_lists");
    // var selectedText = d.options[d.selectedIndex].value;

    firebases.database().ref("akwaaba/customers/").on('value', function(vehinumSnapshot) {
        var vehinumChildSnapshot = vehinumSnapshot.val();

        //    var queryRef = firebase.database().ref("akwaaba/customers/").orderByChild('userID').equalTo(selectedText);
        var queryRef = firebases.database().ref("akwaaba/customers/");

        queryRef.on('value', function(querySnapshot) {
            querySnapshot.forEach(function(dinoSnapshot) {

                if (dinoSnapshot.key == keyvalue) {
                    ////alertdinoSnapshot.key);

                    //  /*
                    //   var userids = '';
                    var fname = '';
                    var lname = '';
                    var email = '';
                    var phone = '';
                    // var nationalIDNumber= '';
                    //  var City = '';
                    var profilePhoto = '';

                    var val = dinoSnapshot.val();

                    fname += val.firstName;
                    //  userids += val.customerid;
                    lname += val.lastName;
                    email += val.email;
                    phone += val.phone;

                    profilePhoto += val.profilePhoto;

                    //profilePhoto

                    document.getElementById('userID').value = "";
                    document.getElementById('userID').value = keyvalue;
                    //  document.getElementById('driver_list_name').value = plate_mainte;

                    document.getElementById('fname').value = fname;
                    document.getElementById('lname2').value = lname;
                    document.getElementById('email').value = email;
                    document.getElementById('phone').value = phone;

                    if (val.profilePhoto == "" || val.profilePhoto == null) {

                        document.getElementById('profilePhoto').value = "No Photo";
                        //    */

                    } else {

                        document.getElementById('profilePhoto').value = profilePhoto;

                    }

                }

            });

        });

    });
})






}


// Drivers

const initializeDrivers = async () => {

   // alert(60)



//Driver Start

var queryRef = firebases.database().ref("akwaaba/driversCurrentLocation/")
queryRef.on('value', function(snapshot) {

   
    var vehi_list;

    snapshot.forEach(function(data) {
     //   alert("Helooooooo" + data.key)
        //   var database = firebase.database();
        //  database.ref('akwaaba/drivers/').orderByChild("status").equalTo("approved").on('value', function(snapshot) {
        //  document.getElementById("driver_list").options.length = 0;

        vehi_list = '';

        var queryRefs = firebases.database().ref("akwaaba/drivers/" + data.key);
        queryRefs.on('value', function(snapshot) {

            if (snapshot.exists()) {
                
                var val = snapshot.val();
                if(val.firstName !=null && (val.status==null || val.status=="approved"|| val.status=="Approved"))
                {
              
                vehi_list += '<option value="' + data.key + '">' + val.firstName + ' ' + val.lastName + ' - ' + ' ' + val.phone + ' ' + '</option>';
                //   });
                }

            }

            $('#driver_list')
            .empty()
            .append('<option selected="selected" value="">Select a New Driver</option>');
        $('#driver_list').append(vehi_list);

        var values = new Array();
        $('#driver_list').children('option').each(function() {
            var text = $(this).text();
            if (values.indexOf(text) === -1) {
                values.push(text);
            } else {
                //  Its a duplicate
                $(this).remove()
            }
        })
        })
    })
})



// function customer_mainte_event() {
  //  const customers_lists=document.getElementById("customers_lists")
  document.getElementById("driver_list").addEventListener('change',function(e){
    let keyvalue="";
  keyvalue=this.value;
//alert(keyvalue);
//Get text or inner html of the selected option
// var d = document.getElementById("customers_lists");
// var selectedText = d.options[d.selectedIndex].value;

firebases.database().ref("akwaaba/drivers/").on('value', function(vehinumSnapshot) {
    var vehinumChildSnapshot = vehinumSnapshot.val();

    //    var queryRef = firebase.database().ref("akwaaba/customers/").orderByChild('userID').equalTo(selectedText);
    var queryRef = firebases.database().ref("akwaaba/drivers/");

    queryRef.on('value', function(querySnapshot) {
        querySnapshot.forEach(function(dinoSnapshot) {

            if (dinoSnapshot.key == keyvalue) {
                ////alertdinoSnapshot.key);

                //  /*
                //   var userids = '';
                var fname = '';
                var lname = '';
                var email = '';
                var phone = '';
                // var nationalIDNumber= '';
                //  var City = '';
                var profilePhoto = '';

                var val = dinoSnapshot.val();

                fname += val.firstName;
                //  userids += val.customerid;
                lname += val.lastName;
                email += val.email;
                phone += val.phone;

                profilePhoto += val.profilePhoto;

                //profilePhoto

                document.getElementById('driver_list_id').value = "";

                    document.getElementById('driver_list_id').value = dinoSnapshot.key;
                    document.getElementById('manualtoken').value = "";
                    document.getElementById('manualtoken').value = dinoSnapshot.val().token;


                if (val.profilePhoto == "" || val.profilePhoto == null) {

                    document.getElementById('profilePhoto').value = "No Photo";
                    //    */

                } else {

                    document.getElementById('profilePhotoDriver').value = profilePhoto;

                }

            }

        });

    });

});
})




}



// Get Drivers info

// 
// function Driversm_mainte_event() {

    
//     // document.getElementById("driver_list").addEventListener('change',function(e){
//     //   let keyvalue="";
//     //   keyvalue=this.value;
//     ////alert15);
//     //Get text or inner html of the selected option

//     var d = document.getElementById("driver_list");
//     var selectedText = d.options[d.selectedIndex].value;
//     var idfromdriver = d.options[d.selectedIndex].value;
//     //Driversm_mainte_event_token(selectedText);
//     firebases.database().ref("akwaaba/drivers/").on('value', function(vehinumSnapshot) {
//         var vehinumChildSnapshot = vehinumSnapshot.val();
//         //     var queryRef = firebase.database().ref("akwaaba/drivers/").orderByChild('user_id').equalTo(selectedText);
//         var queryRef = firebases.database().ref("akwaaba/drivers/");

//         queryRef.on('value', function(querySnapshot) {
//             querySnapshot.forEach(function(dinoSnapshot) {


//                 if (dinoSnapshot.key == selectedText) {


//                     document.getElementById('driver_list_id').value = "";

//                     document.getElementById('driver_list_id').value = dinoSnapshot.key;
//                     document.getElementById('manualtoken').value = "";
//                     document.getElementById('manualtoken').value = dinoSnapshot.val().token;



//                 }
//             });

//         });

//     });

// }
//)



















 
    const initialize = async () => {

        document.getElementById("hidediv").style.display = 'inline';
         

    initMap();
    // var infowindow = new google.maps.InfoWindow();
    var map = new google.maps.Map(
        document.getElementById("booking_mapview"), {
        //Ghana           //   center: new google.maps.LatLng(7.9465, -1.0232),

        center: new google.maps.LatLng(5.5697816512578, -0.21317566815868),

        zoom: 10,
        // mapTypeId: google.maps.MapTypeId.ROADMAP
    });
   

    firebases.database().ref("akwaaba/drivers/").on("child_added", (snap) => {
        //  // // alert(12374);
        // var totalRecord =  snap.numChildren();
        //  console.log("The new key is =" + snap.key);

        document.getElementById("hidediv").style.display = 'none';

        var datas = localStorage.getItem("dkey");
        var keyid = snap.key;
        var lat;
        var long;
        var icons;
        var uluru;
        var marker = null;
        var chkcolor;
        var markersArray = [];
        var chkbooking;
        var chkcustomer;
        var bounds;
        const drivers_details = "";
        //   //console.log(snapshot.val());
        var icon1 = {
            // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#40ff00", //<-- Car Color, Green
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            // rotation: snapshot.val().angle  //<-- Car angle
        };


        var icon2 = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#ffff00", //<-- Car Color, Yellow
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            //   rotation: snapshot.val().angle  //<-- Car angle
        };



        var icon3 = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#0000FF", //<-- Car Color, blue
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            // rotation: snapshot.val().angle  //<-- Car angle
        };


        var icon4 = { // car icon
            path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
            scale: 0.7,
            fillColor: "#ff00bf", //<-- Car Color, Pink
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5)
            //    rotation: snapshot.val().angle  //<-- Car angle
        };


        var icon0 = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#808080", //<-- Car Color, Cream
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5),
            // rotation: snapshot.val().angle //<-- Car angle
        };


        var iconoffline = { // car icon
            path: 'M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805',
            scale: 0.7,
            fillColor: "#000000", //<-- Car Color, IndiaRed
            fillOpacity: 1,
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 5),
            // rotation: snapshot.val().angle  //<-- Car angle
        };






        var locationsRef = firebases.database().ref("akwaaba/drivers/" + keyid + "/lastLocation").orderByValue();
        bounds = new google.maps.LatLngBounds();
        locationsRef.on("value", function (snapss) {

            lat = snapss.child("Lat").val();
            long = snapss.child("lng").val();

            //   data = snapss.val();
            uluru = { lat: parseFloat(lat), lng: parseFloat(long) };


            chkcolor = "";
        })

        //   var locationsRef = firebase.database().ref("akwaaba/drivers/currentLocation/" +  keyid ).orderByChild("l");
        var locationsRef = firebases.database().ref("akwaaba/driversCurrentLocation/" + keyid + "/l").orderByValue();
        //var bounds = new google.maps.LatLngBounds();
        locationsRef.on("value", function (snapshot) {

            if (snapshot.child(0).val() == null) {
                var locationsRef = firebases.database().ref("akwaaba/drivers/" + keyid + "/lastLocation").orderByValue();
                //    var bounds = new google.maps.LatLngBounds();
                locationsRef.on("value", function (snapss) {


                    if (snapss.child("Lat").val() != null) {
                        //console.log("Get new lonitude  Lat " + snapss.child("Lat").val() + " and Longitude=" + snapss.child("lng").val());
                        lat = snapss.child("Lat").val();
                        long = snapss.child("lng").val();
                        //     data = snapss.val();
                        uluru = { lat: parseFloat(lat), lng: parseFloat(long) };


                    }

                })
                icons = iconoffline;

                ///*

                var title = 'The Driver 1';
                marker = new google.maps.Marker({
                    position: uluru,
                    map: map,
                    //  draggable:false,
                    icon: icons,
                    title: title

                });

                // */
            } else {

                //trips_booked_manual_app_links
                chkcolor = snap.val().newTrip;
                lat = snapshot.child(0).val();
                long = snapshot.child(1).val();
                // data = snapshot.val();
                uluru = { lat: parseFloat(lat), lng: parseFloat(long) };

                if (snap.val().newTrip == "waiting") {
                    // //console.log(snap.val().newTrip);
                    icons = "";
                    icons = icon1;


                } else if (snap.val().newTrip == "accepted") {
                    //  //console.log( snap.key);
                    icons = "";
                    icons = icon2;

                } else if (snap.val().newTrip == "arrived") {
                    // //console.log( snap.key);
                    icons = "";
                    icons = icon3;
                } else {
                    icons = "";

                    icons = icon4;
                }

 

                firebases.database().ref("akwaaba/driversCurrentLocation").on('child_changed', function (data) {
                    console.log("The Data Key Change " + data.key)
                    marker.setMap(null);
                    //marker.push(icons);
                    for (var i = 0; i < 10000000; i++) {
                        markersArray[i].setMap(null);
                    }
                    markersArray.length = 0;
                });

                var title = 'Intro 2';
                marker = new google.maps.Marker({
                    position: uluru,
                    map: map,
                    //  draggable:false,
                    icon: icons,
                    title: title

                });
            }




            bounds.extend(marker.getPosition());
            marker.addListener('click', (function (data) {
                return function (e) {
              

                }
                // }(data)));
            }));


            marker.addListener("click", () => {
            
              //  searchbooking(keyid);
 
               document.getElementById("myModal").style.display = 'block';
              // openModal();
              

            });


 

            map.fitBounds(bounds);
            google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
                this.setZoom(map.getZoom() - 1);

                if (this.getZoom() > 13) {
                    this.setZoom(13);
                }
            });
            map.fitBounds(bounds);
        })

      
        

    });

}




export { initialize,initializeAutocomplete,initializeCustomers,initializeDrivers ,getthemaps};




 








