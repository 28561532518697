import React, { useState, useEffect } from 'react';

import dlogo from '../ab.png';
import firebase from "../DbConn";
import { SuspendedVehiclesVehicleView,Loadpdfonly,managevehicleformodaltable,UpdateVehicleViewmodalchangefirstmodal} 
from './SuspendedVehiclesDataViewAllFormdb';

import './Tablestyle.css';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");



const SuspendedDriverDataViewAllForm = () => {

  useEffect(() => {
    const date = new Date();

    // 👇️
document.getElementById('datecreated').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
        
 document.getElementById('datecreatedd').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
     

})
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  // close modal but not use now
  const onLoginFormSubmit = (e) => {
    e.preventDefault();

    document.getElementById("exampleModal").style.display = 'none';

    handleClose();
  };









  useEffect(() => {
    SuspendedVehiclesVehicleView();

    managevehicleformodaltable();

    UpdateVehicleViewmodalchangefirstmodal();
    window.setTimeout(function () {
      getcolorloadsd();
      //  window.location.reload(1);
    }, 5000);
 
  },[])

  useEffect(() => {
   // Loadpdfonly();
    window.setTimeout(function () {
    //  getcolorloadsd();
       ///  window.location.reload(1);
       Loadpdfonly();
    }, 10000);

 
  })


// open new page to edit
const openNewpapage =(e) =>{
  e.preventDefault();

  window.open(
    'https://google.com',
    '_blank' // <- This is what makes it open in a new window.
  );

}



  const onLoginFormSubmits = (e) => {
    e.preventDefault();

    const t = document.getElementById("mylinkDriversLicenseFronturl").value;
    // window.location.href =t;

    let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("mylinkDriversLicenseFronturl").value="";
  };


  const onLoginFormSubmitsback = (e) => {
    e.preventDefault();

    const t = document.getElementById("mylinkDriversLicenseBackurl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("mylinkDriversLicenseBackurl").value="";
      //http://localhost:3000/undefined
  };


  
  const onLoginFormSubmitspersonalid = (e) => {
    e.preventDefault();

    const t = document.getElementById("passportfronturl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("passportfronturl").value="";
      //http://localhost:3000/undefined
  };

  const onLoginFormSubmitspersonalidback = (e) => {
    e.preventDefault();

    const t = document.getElementById("passportbackurl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("passportbackurl").value="";
      //http://localhost:3000/undefined
  };


  const  onLoginFormSubmitsInsurance = (e) => {
    e.preventDefault();

    const t = document.getElementById("insurancefronturl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("insurancefronturl").value="";
      //http://localhost:3000/undefined
  };

  const  onLoginFormSubmitsInsuranceback = (e) => {
    e.preventDefault();

    const t = document.getElementById("insurancebackurl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("insurancebackurl").value="";
      //http://localhost:3000/undefined
  };

  const  onLoginroadworthinessBack = (e) => {
    e.preventDefault();

    const t = document.getElementById("roadworthinessbackurl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("roadworthinessbackurl").value="";
      //http://localhost:3000/undefined
  };


  const  onLoginroadworthinessFront = (e) => {
    e.preventDefault();

    const t = document.getElementById("roadworthinessfronturl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("roadworthinessfronturl").value="";
      //http://localhost:3000/undefined
  };


  const  onLoginVehicleImage = (e) => {
    e.preventDefault();

    const t = document.getElementById("vehicleimageurl").value;
     let link = window.open(t, "_blank");
    link.opener = null;
    document.getElementById("vehicleimageurl").value="";
      //http://localhost:3000/undefined
  };

  


  function Clicks() {
    //  const t = document.getElementById("mylinkDriversLicenseFronturl").value;
    alert(7)
    // const s_a = document.getElementById("mylinkDriversLicenseFront");
    // s_a.href =   t;
    // $("#downloadButton").click(function (e) {
    //   e.preventDefault();
    // window.location.href = t;
    // });
  }




  function getcolorloadsd() {

    $('#driveruserlist tr td').each(function () {
      var cellValue = $(this).html();
      if (cellValue) {
        if (cellValue == "pending") {
          $(this).parent().css("background-color", "yellow");
          //   $( "tr td" ).css( "background-color", "yellow" );
          //.parent().css("background-color", "yellow");
        }
        if (cellValue == "Pending") {
          $(this).parent().css("background-color", "yellow");
          //  $( "tr" ).css( "background-color", "yellow" );
        }

        if (cellValue == 2) {
          //only one cell color
          $(this).css('background-color', 'green');
        }

        if (cellValue == "suspended") {
          $(this).parent().css('background-color', 'red');
        }

        if (cellValue == "cancelled") {
          $(this).parent().css('background-color', 'red');
        }
        if (cellValue == "approved") {
          $(this).parent().css('background-color', 'green');
          // $(this).parent().css('color','white');
        }
        if (cellValue == "Approved") {
          $(this).parent().css('background-color', 'green');
          // $(this).parent().css('color','white');
        }
      }
    });


  }



  //$("#tableID tr:gt(0)").remove();
  //View All Pages






  // function chkbeforload(){

  //     setTimeout(function(){ getcolorloadsd();  }, 5000); 
  // }


  return (
    <>



      <div class="hidediv" id="hidediv">
        <div class="loading"></div>
      </div>




      <div class="content-wrapper" >

        <div class="row">
          <div class="col-md-12 grid-margin">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-end flex-wrap">
                <div class="mr-md-3 mr-xl-5">
                  <h4>Vehicle's Management  </h4>
                  <p class="mb-md-0">List of Suspended Vehicles.</p>
                </div>

              </div>
              <div class="d-flex justify-content-between align-items-end flex-wrap">

                <button id="themanagedrivers" class="btn btn-primary mt-2 mt-xl-0"
                  data-toggle="modal" data-target="#mythemanagedrivers" >Manage Vehicle</button>
              </div>
              <div class="d-flex justify-content-between align-items-end flex-wrap">

                <button id="managethedriverss" style={{ display: "none" }}
                  class="btn btn-success mt-2 mt-xl-0">Manage Vehicles</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">.</h4>
              <div class="table-responsive">
                <div style={{ overflowX: "auto;" }}>



                  {/* <a href="#" > */}


                  {/* <table id="driveruserlist" class="table table-striped table-bordered driveruserlist"
                                      style="width:100%"> */}

                  <table id="driveruserlist"
                    class="display "
                    style={{ width: "100%" }}>

                    <thead id="thead">
                      <tr id="tra">

                        <th style={{ display: "none" }} >ID</th>
                        <th style={{ display: "none" }} >ID</th>

                        
                          <th>Manufacturer</th>
                          <th>Model</th> 

                          <th>Type</th>
                          <th>Year</th>

                          <th>Reg. Number</th>
                          <th>Color</th>

                          <th> Address</th>
                          <th> Insurance Exp. Date</th>
                          <th>Roadworthy Exp. Date</th> 

                          <th>Status / Comments</th>
                          <th>Start Date</th>
                          <th>End Date</th>

                      </tr>
                    </thead>
                    <tbody data-toggle="modal"
                      title="list of All registered drivers, Click the cell to view Drivers "></tbody>
                  </table>

                  {/* </a> */}


                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
      {/*                     pop up modal */}


      {/* 
<button type="button" data-toggle="modal" data-target="#exampleModal">Open Modal</button> */}
      {/* 
<!-- Modal --> */}
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true" data-toggle="modal" data-target="#staticBackdrop"  >
        <div class="modal-dialog modal-sm" style={{ maxWidth: "350px" }}>
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Vehicle Details</h5>
              <button type="button" class="close hideall" data-dismiss="modal" aria-label="Close" onClick={onLoginFormSubmit}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Status</label>
                    <div class="col-sm-9">
                       
                      <select id="vehicleselect" title="Select option to change driver status"
                        class="form-control shadow-sm">
                        <option value=""> Select Option</option>
                        <option value="pending"> Pending </option>
                        <option value="suspended"> Suspended </option>
                        <option value="approved"> Approved </option>
                      </select>
                    </div>
                  </div>
                </div>


              </div>




              <div class="row">
                <div class="col-md-12">
                  <div class="centercimg">
                    <span class="loaderimg centercimg" id="loaderimg"></span>

                  </div>

                  <div id="hidecimg">

                    <img width="40" height="40"
                      src={image || dlogo}
                      class="img-responsive rounded-circle 
                                        center-block d-block mx-auto "
                      id="cimg" alt="No Image avalable"
                    />

                  </div>
                </div> 
 
                                     <br />
                                 
                                     <div class="col-md-12"  >
                                     <label>Manufacturer :</label>
                                         <label for="" id="driverlive_statussx">  <span id="carManufacturer"> </span></label>
                                       </div>
                                     <br />
                                       <div class="col-md-12"  >
                                     <label>Vehicle model :</label>
                                         <label for="" id="driverlive_statussx">  <span id="vehiclemodel"> </span></label>
                                         &nbsp;&nbsp;&nbsp;&nbsp; <br />
                                     <label>Vehicle Type :</label>
                                         <label for="" id="driverlive_statussx">  <span id="carType"> </span></label>
                                       </div>
 

                                       <div class="col-md-12"  >
                                     <label>Manufactiring Year :</label>
                                         <label for="" id="driverlive_statussx">  <span id="carYear"> </span></label>
                                       </div>

                                      <br />
                                      <div class="col-md-12"  >
                                     <label>Vehicle Registration Number :</label>
                                         <label for="" id="driverlive_statussx">  <span id="vehicleRegistrationNumber"> </span></label>
                                       </div>



                        
              </div>

            
              <div class="row" >
                <div class="col-md-12" style={{ display: "none" }}>
                  <div class="form-group row">

                    <div class="col-sm-12">

                      <input type="number" class="form-control shadow-sm" id="mymodelphone"
                        placeholder="No Phone Number" />
                    </div>
                  </div>
                </div>


                <div class="col-md-12">
                  <div class="form-group row">

                    <div class="col-sm-12">
                      <label for="photo">
                        <hr />

                        <p> Click the link below to Download </p>


                        <span>
                          {/* <a target="_blank" href="#"
                            id="mylinkDriversLicenseFront"
                            rel="noopener noreferrer"
                            onClick={onLoginFormSubmits}
                          >Driving Licence Front<span class="fa fa-download"></span> </a>
                            &nbsp;&nbsp;
                           &nbsp;&nbsp;
                           <br />

                           <a target="_blank" href="#"
                           
                            rel="noopener noreferrer"
                            onClick={onLoginFormSubmitsback}
                          >Driving Licence Back<span class="fa fa-download"></span> </a>
                            &nbsp;&nbsp;
                           &nbsp;&nbsp;
                           <br />

                           <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitspersonalid}
                         >Personal ID Front <span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitspersonalidback}
                         >Personal ID Back <span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br /> <br /> */}
                           




                          <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginVehicleImage}
                         >Vehicle Image<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          
                          <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitsInsurance}
                         >Insurance File Front ..<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginFormSubmitsInsuranceback}
                         >Insurance file Back<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />
                         






                          <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginroadworthinessFront}
                         >roadworthiness Front ..<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />

                          <a target="_blank" href="#"
                             rel="noopener noreferrer"
                           onClick={onLoginroadworthinessBack}
                         >roadworthiness Back<span class="fa fa-download"></span> </a>
                           &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <br />
                          <br />



                          
  



                          <a id="mypassportfront" target="_blank" class="hideall"
                            //           href="#" 
                            onclick="Click_Passportfront()"
                            download>Passport Front </a>
                          <br />


                          <a id="mypassportback" target="_blank"  class="hideall"
                            href="#"
                            // onclick="Click_Passportback()"
                            download> Passport  Back </a>
                      

                          <a id="privateHiredriverslincencepaperformfront"  class="hideall" target="_blank"
                            href="#"
                            // onclick="ClickprivateHiredriverslincencepaperformfront()" 
                            download>Private Hire / HC Driver Licence Front </a>
                         

                          <a id="privateHiredriverslincencepaperformback"  class="hideall" target="_blank"
                            href="#"
                            // onclick="ClickprivateHiredriverslincencepaperformback()"
                            download>Private Hire / HC Driver Licence Back </a>


                         

                          <a id="DBSPoliceReportback" target="_blank"  class="hideall"
                            href="#"
                            // onclick="ClickDBSPoliceReportback()"
                            download>DBS / PoliceReport back </a>

                         

                          <a id="DBSPoliceReportfront" target="_blank"  class="hideall"
                            href="#"
                            // onclick="ClickDBSPoliceReportfront()"
                            download>DBS / PoliceReport Front</a>




                      

                          <a id="budgedocumentfront" target="_blank"  class="hideall"
                            href="#"
                            //onclick="Clickbudgedocumentfront()"
                            download>Budge Document front </a>

                        

                          <a id="budgedocumentback" target="_blank"  class="hideall"
                            href="#"
                            // onclick="Clickbudgedocumentback()"
                            download>Budge Document Back</a>





                        </span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary close" data-dismiss="modal" onClick={onLoginFormSubmit}>Close</button>
              <button type="submit" class="btn btn-info"
              //  onclick="location.href='drivers_registrationeditt' " 
           //   onclick={openNewpapage}
           onClick={() => window.open("/vehiclesregistrationeditupdate")}
              >Update  </button>
            </div>
          </div>
        </div>
      </div>






      {/* 
<!-- Modal 2 for Drivers--> */}
      <div class="modal fade" id="mythemanagedrivers" tabindex="-1" role="dialog"
        aria-labelledby="mangedriversLabel" aria-hidden="true">

        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" style={{ color: "red", fontSize: "20px;" }} id="mangedriversLabel">Manage Vehicle</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">



            <div class="row">
                        <div class="col-md-12" >
                            <div class="form-group row">
                                <label class="col-form-label" style={{color: "blue"}}>Select Vehicle</label>
                                <div class="col-sm-12">
                            
                                  <select class="form-control shadow-sm"  id="vehicle_list"  >
                                        <option value="none" selected> 
                                    
                                    <div class="spinner-border text-info"> Select Vehicle</div>
                                        </option>
                                   </select>
                                </div>
                             </div>
                            </div>
                         </div>
                         <input type="hidden"   class="form-control shadow-sm"
                                          id="vehicle_list_idd"    required />

 

                    <div class="row">
                        <div class="col-md-12" >
                            <div class="form-group row">
                                <label class="  col-form-label" >Vehicle info.</label>
                                <div class="col-sm-12">
                                <img  id="vehicleincomemyimgs"       src={image || dlogo}
                                               class="img-responsive rounded-circle center-block d-block mx-auto"
                                            width="40" height="40"   
                                           
                                              />
                                <span> <label><b  style={{color:"red"}} >
                                <span id="vehicleinformation"></span>
                                </b> </label></span>
                                  
                                </div>
                             </div>
                           </div>
                         </div>

                    
                                      <div class="col-md-12">
                                       <div class="form-group row">
                                         <div class="col-sm-12">
                                         <label><b  style={{color: "blue"}} >  </b> </label>
                                          <select id="vehiclelivestatuss"  class="form-control shadow-sm"  required>
                                                <option value=""> Select Option</option>
                                                <option value="pending"> Pending </option>
                                                <option value="suspended"> Suspended </option>
                                                <option value="approved"> Approved </option>
                                          </select>

                                         </div>
                                       </div>
                                     </div>


 
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-success" id="approvecar"> Activate !!! </button>
            </div>
          </div>
        </div>

      </div>




      <input type="hidden" id="newdriveridupdate" />
      <input type="hidden" id="newdriveridupdatestatus" />



      <input type="hidden"
        id="mylinkDriversLicenseFronturl" />
      <input type="hidden"
        id="mylinkDriversLicenseBackurl" />



      <input type="hidden"
        id="privateHiredriverslincencepaperformbackurl" />

      <input type="hidden"
        id="privateHiredriverslincencepaperformfronturl" />



      <input type="hidden"
        id="DBSPoliceReportbackurl" />

      <input type="hidden"
        id="DBSPoliceReportfronturl" />



      <input type="hidden"
        id="passportfronturl" />

      <input type="hidden"
        id="passportbackurl" />


        
<input type="hidden"
        id="insurancefronturl" />

        
      <input type="hidden"
        id="insurancebackurl" /> 


        <input type="hidden"
          id="roadworthinessfronturl" />
  
          
        <input type="hidden"
          id="roadworthinessbackurl" />



<input type="hidden"
        id="vehicleimageurl" />

      <input type="hidden"
        id="passportbackurl" />

        
      <input type="hidden"
        id="vehicleImagefronturl" />



<input  id="datecreated"   type="hidden"  style={{display: "none"}}  /><br/>
                         <input  id="datecreatedd"  type="hidden"  style={{display: "none"}}  />
                         <input  id="licensePlate" value="100001"  type="hidden"  style={{display: "none"}}  />


    </>
  )
}

export default SuspendedDriverDataViewAllForm;