import React , {useState,useEffect}  from "react"; 

import {initialize  } from './maindash_maps.js';
import {initializestatistics  } from './maindash_mapsStatistics.js';
  const MainContent = () => {

      
  useEffect(() => {
    //window.setTimeout(function () {   initialize(); }, 4000) ;
   //window.setTimeout(function () {   initialize(); }, 5000) ;
   
  //  window.setTimeout(function () {   initializestatistics(); }, 2000) ;
   })

   useEffect(() => {
  //  window.setTimeout(function () {   initialize(); }, 10000) ;
   
   window.setTimeout(function () {   initializestatistics(); }, 11000) ;
   })
  return (
 
 

<div class="col-lg-5 col-md-12">
    <div class="card" style={{minHeight: "485px"}}>
        <div class="card-header card-header-text">
            <h4 class="card-title">Quick View</h4>
        </div>
        <div class="card-content">
            <div class="streamline">
                <div class="sl-item sl-primary">
                    <div class="sl-content">
                        <small class="text-muted">Active drivers</small>
                        <p>Total active drivers  : <i  id="theonlineactivevehiclesapprovred">0</i></p>
                    </div>
                </div>
                <div class="sl-item sl-danger">
                    <div class="sl-content">
                        <small class="text-muted">Pending Drivers</small>
                        <p>Total drivers pending for aproval : <i id="themainonlinependingpending">0</i> </p>
                    </div>
                </div>
                <div class="sl-item sl-success">
                    <div class="sl-content">
                        <small class="text-muted">SOS</small>
                        <p>Panic SOS Warning</p>
                    </div>
                </div>
                <div class="sl-item">
                    <div class="sl-content">
                        <small class="text-muted">New Complaint</small>
                        <p>New Drivers Complaints  || Customer Complaints </p>
                    </div>
                </div>
                <div class="sl-item sl-warning">
                    <div class="sl-content">
                        <small class="text-muted">View Payment</small>
                        <p>New Payment Setup</p>
                    </div>
                </div>
                <div class="sl-item">
                    <div class="sl-content">
                        <small class="text-muted">System office users</small>
                        <p>Active Users</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
 




)
}


export default MainContent;