
//import firebaseb from './Drivers/Pages/Auth/userauth';
import firebase from "../DbConn"
import Swal from 'sweetalert2'
import swal from 'sweetalert';
// import { ToastContainer, toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";
import { Field } from "formik";
//import moment from 'moment';

//const firebase=9;


const updatedata = localStorage.getItem("driveredits");
const vehicledata = localStorage.getItem("driveredits");
const Driverupadteid = localStorage.getItem("driveredits");
 

 
const  searchingvehicle = async () => {

//  function  searchingvehicle() {

document.getElementById("hidediv").style.display = 'inline';

    const vehicledata = localStorage.getItem("driveredits");
 
 //   const  searchsvehicl3 = async (vehicledata) => {
  ////alertdata);

 // //alert"Done");
//  firebase.database().ref('akwaaba/vehicleInfo/')
//  .orderByChild("driverId").equalTo(data)
//  .on("child_added", function(data){

  var queryRef = firebase.database().ref("akwaaba/vehicleInfo/");
       
       queryRef.on('value', function(snapshot) {
    snapshot.forEach(function(data){
          
         if(data.key==vehicledata ){
          var val = data.val();
 
   ////alertdata.val().SeatCapacity);

      document.getElementById("carColor").value    = data.val().carColor;
    // document.getElementById("carName").value    = data.val().carManufacturer;

      document.getElementById("carManufacturer").value    = data.val().carManufacturer;
//      document.getElementById("license").value    = data.val().license;
      document.getElementById("carYear").value    = data.val().carYear;
      document.getElementById("carType").value    = data.val().carType;

      document.getElementById("SeatCapacity").value    = data.val().SeatCapacity;
      document.getElementById("vehiclemodel").value    = data.val().vehiclemodel;
      document.getElementById("licensePlate").value    = data.val().licensePlate;

    //  document.getElementById("license").value    = data.val().license;
    
    if(data.val().disabilitychkcar =="yes" || data.val().disabilitychkcar =="Yes")
            {
            document.getElementById("disabilitychkYes").checked = true;
            document.getElementById("disabilitychkNo").checked = true;
            }else
            {
              document.getElementById("disabilitychkYes").checked = true;
              document.getElementById("disabilitychkNo").checked = true;

          //    var vehicledata=data.val().vehicleid;

           //   searchsvehicl3(vehicledata);
          // searchsvehicl3(vehicledata);
            }
 
   // document.getElementById("proveidtypeexpiringdate").value    = data.val().proveidtypeexpiringdate; 
 
      // document.getElementById("dvlaprivatehirepermitexpiringdate").value    = data.val().dvlaprivatehirepermitexpiringdate;

    document.getElementById("roadworthinessexpiringdate").value    = data.val().roadworthinessExpiringDate;
   //document.getElementById("drivinglincenceexpiringdate").value    = data.val().drivinglincenceexpiringdate;
  // document.getElementById("dvlamotexpiringdate").value    = data.val().dvlamotexpiringdate;
 //   document.getElementById("policeclearancecertificateexpiringdate").value    = data.val().policeclearancecertificateexpiringdate;
   // document.getElementById("ownershipexpiringdate").value    = data.val().ownershipexpiringdate;

       document.getElementById("insuranceRenewaldate").value    = data.val().insuranceStickerExpiringDate;
       
  document.getElementById("hidediv").style.display = 'none';
 //  document.getElementById("roadworthinessfront").value    = data.val().roadworthinessfront;
   // document.getElementById("roadworthinessexpiringdate").value    = data.val().roadworthinessexpiringdate;
    document.getElementById("vehicleaddress").value    = data.val().vehicleAddress;

  document.getElementById("vehicleRegistrationNumber").value    = data.val().vehicleRegistrationNumber ;
  document.getElementById("vehicleid").value    = data.val().vehicleid ;
         
  
 
          }
            })
  
          }, function (error) {
         //alert"Error: " + error.code);
         });
     
      
     
   }




const DriverVehicleRegisterUpdate = async () => {

   // document.getElementById("hidediv").style.display = 'inline';


    swal({
        title: "Are you sure of submitting without Vehicle details ?",
        text: "Once Submit, you have to contact the Yiedze office to assign a new vehicle!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete) {


                submitwithoutdrivervehicledatasUpdate();
            }
            else {
                document.getElementById("hidediv").style.display = 'none';
                swal("Cancelled");
            }
        });


};




const VehicleRegisterwithcarUpdate = async () => {

   
    vehicleinfowithdrivernewupdate();


};




export { searchingvehicle , VehicleRegisterwithcarUpdate };

 





// Register new car

function vehicleinfowithdrivernewupdate() {
    // Loading page 
    document.getElementById("hidediv").style.display = 'inline';
  
    // Field
      // const v5cdocumentlogbook = document.getElementById("v5cdocumentlogbook").value;
      //   const v5cdocumentlogbookexpiringdate = document.getElementById("v5cdocumentlogbookexpiringdate").value;
   // var keys =  document.getElementById("driveridupdatecarid").value;
  
     
      const carManufacturer = document.getElementById("carManufacturer").value;
      const carYear = document.getElementById("carYear").value;
      const carType = document.getElementById("carType").value;
      const carTypes = document.getElementById("carType").value;
     // const SeatCapacitys = document.getElementById("SeatCapacity").value;
      const vehiclemodel = document.getElementById("vehiclemodel").value;
      const SeatCapacity = carTypes + " " + "Seater";
      var carColor = document.getElementById("carColor").value;
  
      // const  CallSign=document.getElementById("Callsignv").value; 
  
      const vehicleRegistrationNumber = document.getElementById("vehicleRegistrationNumber").value;
    //  const licensePlateNumber = document.getElementById("licensePlateNumber").value;
      //  const date1 = document.getElementById("date1").value; 
      const status = "pending";
  
      var radiossss = document.getElementsByName("disabilitychkcar");
      var disabilitychkcar = Array.from(radiossss).find(radio => radio.checked);
  
    var insuranceRenewaldate = document.getElementById("insuranceRenewaldate").value;
      // var v5cdocumentlogbook = document.getElementById("v5cdocumentlogbook").value;
      var roadworthinessexpiringdate = document.getElementById("roadworthinessexpiringdate").value;
      //  var dvlaprivatehirepermitfront = document.getElementById("dvlaprivatehirepermitfront").value;
      //  var dvlaprivatehirepermitexpiringdate = document.getElementById("dvlaprivatehirepermitexpiringdate").value;
  
      var vehicleInfos = carManufacturer + " " + vehiclemodel + " - " + vehicleRegistrationNumber;
      
      var vehicleaddress = document.getElementById("vehicleaddress").value;
  
      var createdAt = document.getElementById("datecreated").value;
  
         
    //  const getcarid = firebase.database().ref('akwaaba/vehicleInfo/').push({
        firebase.database().ref('akwaaba/vehicleInfo/' + updatedata).update({
       
        // var abc = firebase.database().ref('akwaaba/vehicleInfo/' ).update({
          //  CallSign:CallSign,
         
    //    licensePlateNumberexpiringdate: licensePlateNumberexpiringdate,
   
          vehicleAddress : vehicleaddress,
          vehicleRegistrationNumber : vehicleRegistrationNumber,
          carFullAddress : vehicleaddress, 
          
          disabilitychkcar : disabilitychkcar.value,
        //  drivinglincenceexpiringdate : drivinglincenceexpiringdate,
          vehicleInfo : vehicleInfos,
  
          carColor: carColor,
          carManufacturer : carManufacturer,
          carMakeType : carManufacturer,
          carYear : carYear,
          carType : carType,
   
        //   SeatCapacity: SeatCapacity,
        //   seatersAvailable: SeatCapacity,
        //   
        vehiclemodel: vehiclemodel,
        
        //  licensePlateNumber: licensePlateNumber, insuranceStickerExpiringDate
          insuranceStickerExpiringDate : insuranceRenewaldate,
          roadworthinessExpiringDate  : roadworthinessexpiringdate,
        //   status: status,
        //  createdAt: createdAt,
        createdAtLast: createdAt,
  
     
      }
//       ,
      
//        function(error) {
//         if (error) {
// console.log("This is error" + error.message)


//         }else{

//         setTimeout(function () { 
//             const key = updatedata;
//           console.log("The Key Driver  = " + key)
        
           
//               vehicleImagefront1(key);
           
//               roadworthinessBackNew(key);
//               roadworthinessFrontNew(key);
          
              
//               insurancepolicyfront1(key);
//               insurancepolicyback2(key);
//             }, 1000);
//         }
      
//     }
      )
    
    
    .then(async success => {

        // setTimeout(function () { 
        //     const key = updatedata;
        //   console.log("The Key Driver New  = " + key)
        
           
        //       vehicleImagefront1(key);
           
        //       roadworthinessBackNew(key);
        //       roadworthinessFrontNew(key);
          
              
        //       insurancepolicyfront1(key);
        //       insurancepolicyback2(key);
        //     }, 1000);
        

        const Toast = Swal.mixin({
            // ld(),
             toast: true,
             position: 'top-right',
             iconColor: 'white',
             customClass: {
                 popup: 'colored-toast'
             },
             showConfirmButton: false,
             timer: 9800,
             timerProgressBar: true
         })
         await Toast.fire({
             icon: 'success',
             title: ' Vehicle registration Upadte',
             text: 'Saving!',
             //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
             
         })

           document.getElementById("hidediv").style.display = 'none';

        setTimeout(function () { window.location = "/vehiclesregistrationview"; }, 900);



      })

      .catch(async function ( error) {
        
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
            popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
          })
          await Toast.fire({
            icon: 'error',
            title: 'Something went wrong!',
           // text: 'Something went wrong!',
            footer: '<a href="#" style="color:white;font-weight:bold">Error “Note2 Driver data already in use by another account!.</a>'
          })


          document.getElementById("hidediv").style.display = 'none';
 
    })
    

   
  
  
  
  
    setTimeout(function () { 
        const key = updatedata;
      console.log("The Key Driver New 3  = " + key)
    
       
          vehicleImagefront1(key);
       
          roadworthinessbacknew3(key);
          roadworthinessfrontnew3(key);
      
          
          insurancepolicyfront1(key);
          insurancepolicyback2(key);
        }, 1000);
  }
  



















// Your web app's Firebase configuration



/// Upload without Driver




function submitwithoutdrivervehicledatasUpdate() {
    //  swal("Submit!", "Done!", "success");

    // var driveraddress = document.getElementById("driveraddress").value;
   // var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;


    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
          || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {


        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="#">Error “Please fill out all required fields.</a>'
        })


    } else {


        // Loading page 
        document.getElementById("hidediv").style.display = 'inline';

        // Field

        var driver_last_name = document.getElementById("driver_last_name").value;
        var firstname = document.getElementById("driver_first_name").value;
        var email = document.getElementById("driver_email").value;
        var emergencyContact = document.getElementById("emergencyContact").value;


        var password = document.getElementById("driver_password").value;
        var driver_phone = document.getElementById("driver_phone").value;
        var driveraddresss = document.getElementById("driveraddress").value;


        var radiosw = document.getElementsByName("proveidtypechkcar");
        var personalidcardtype = Array.from(radiosw).find(radio => radio.checked);
        var proveidtypeexpiringdates = document.getElementById("proveidtypeexpiringdate").value;




        var radioss = document.getElementsByName("doyouownchkcar");
        var do_you_own_a_car = Array.from(radioss).find(radio => radio.checked);

        var datecreated = document.getElementById("datecreated").value;

        var drivinglincenceexpiringdate = document.getElementById("drivinglincenceexpiringdate").value;
        //  var privateHiredriverslincencepaperformexpiringdate  = document.getElementById("privateHiredriverslincencepaperformexpiringdate").value;
        //  var drivinglincencenumber = document.getElementById("drivinglincencenumber").value;
        var status = "pending"
        var Enddate = "NA"



        // firebase.auth().createUserWithEmailAndPassword(email, password)
        //     .then(userCredential => {
                var key = Driverupadteid ;
                //  var keys = userCredential.user.uid;


                firebase.database().ref('akwaaba/drivers/' + key).update({


                    //  drivinglincencenumber :drivinglincencenumber ,

                    //  nationalinsuranceNumber :nationalinsurancenumber ,
                    // driverAddres:driveraddresss,
                    //   driverbadgenumberExpiringdate:driverbadgenumberExpiringdate,
                    Enddate: Enddate,
                    //    callSign :callsign ,
                    // budgeDocumentExpiringDate : budgedocumentexpiringdate,
                    driverAddress: driveraddresss,
                    //   driverBadgeNumber : driverbadgenumberr,

                    do_you_own_a_car: do_you_own_a_car.value,

                    email: email,
                    firstName: firstname,
                    lastName: driver_last_name,
                    //   city: city,
                    phone: driver_phone,
                    emergencyContact: emergencyContact,

                 //   token: key,

                    user_Drivers_key: key,
                    driverid: key,


                    personalidcardtype: personalidcardtype.value,
                    // nhisexpiringdate: nhisexpiringdates,
                    //  ssnitexpiringdate: ssnitexpiringdates,

                    passportExpiryDate: proveidtypeexpiringdates,
                    personalidexpiringdate: proveidtypeexpiringdates,
                    drivingLicenceExpiringDate: drivinglincenceexpiringdate,


                    //    privateHiredriverslincencepaperformexpiringdate:privateHiredriverslincencepaperformexpiringdate,
                    //    DBSPoliceReportexpiringdate : DBSPoliceReportexpiringdates ,


                    createdAt: datecreated,
                //    status: status

                })
                .then(success =>{
                    
                proveidtypeback2(key);
                proveidtypefront1(key);
                drivinglincencefront1(key);
                drivinglincenceback2(key);
                    profilePhototure(key);
 

                
                } ) 
                .finally(async success => {
                    // //console.log("Sucess Message " + success)
                     


                    const Toast = Swal.mixin({
                       // ld(),
                        toast: true,
                        position: 'top-right',
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 5500,
                        timerProgressBar: true
                    })
                    await Toast.fire({
                        icon: 'success',
                        title: ' Driver registration',
                        text: 'Successful!',
                        //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
                        
                    })

                    document.getElementById("hidediv").style.display = 'none';



               setTimeout(function () { window.location = "/driversregistrationview"; }, 900);
               clearandresetform();

                }
                )
               .catch(async function ( error) {
                // Handle Errors here.
                //   $("#modalz").hide();

                // var errorCode = error.code;
                // var errorMessage = error.message;
                // console.log(errorCode);
                // console.log(errorMessage);
                // console.log(error);

                // document.getElementById("hidediv").style.display = 'none';


                // Swal.fire({
                //     icon: 'error',
                //     title: 'Registration Error',
                //     text: 'Something went wrong!',
                //     footer: '<a href="">Driver data already in use by another account!</a>'
                // })


                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-right',
                    iconColor: 'white',
                    customClass: {
                    popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 5500,
                    timerProgressBar: true
                  })
                  await Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                   // text: 'Something went wrong!',
                    footer: '<a href="#" style="color:white;font-weight:bold">Error “Driver data already in use by another account!.</a>'
                  })


                  document.getElementById("hidediv").style.display = 'none';






            })

        //*
        // /

    }
}




function submitwithDriverandVehicleUpdade4() {

     //  console.log(12345)
    //  swal("Submit!", "Done!", "success");
 
    // var driveraddress = document.getElementById("driveraddress").value;
   // var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;


    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
          || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {


        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="#">Error “Please fill out all required fields.</a>'
        })


    } else {


        // Loading page 
        document.getElementById("hidediv").style.display = 'inline';

        // Field

        var driver_last_name = document.getElementById("driver_last_name").value;
        var firstname = document.getElementById("driver_first_name").value;
        var email = document.getElementById("driver_email").value;
        var emergencyContact = document.getElementById("emergencyContact").value;


        var password = document.getElementById("driver_password").value;
        var driver_phone = document.getElementById("driver_phone").value;
        var driveraddresss = document.getElementById("driveraddress").value;


        var radiosw = document.getElementsByName("proveidtypechkcar");
        var personalidcardtype = Array.from(radiosw).find(radio => radio.checked);
        var proveidtypeexpiringdates = document.getElementById("proveidtypeexpiringdate").value;
 
        var radioss = document.getElementsByName("doyouownchkcar");
        var do_you_own_a_car = Array.from(radioss).find(radio => radio.checked);

        var datecreated = document.getElementById("datecreated").value;

        var drivinglincenceexpiringdate = document.getElementById("drivinglincenceexpiringdate").value;
        //  var privateHiredriverslincencepaperformexpiringdate  = document.getElementById("privateHiredriverslincencepaperformexpiringdate").value;
        //  var drivinglincencenumber = document.getElementById("drivinglincencenumber").value;
        var status = "pending"
        var Enddate = "NA"



        // firebase.auth().createUserWithEmailAndPassword(email, password)
        //     .then(userCredential => {
                var key = document.getElementById("driveridupdate").value;
                //  var keys = userCredential.user.uid;


                firebase.database().ref('akwaaba/drivers/' + key).update({


                    //  drivinglincencenumber :drivinglincencenumber ,

                    //  nationalinsuranceNumber :nationalinsurancenumber ,
                    // driverAddres:driveraddresss,
                    //   driverbadgenumberExpiringdate:driverbadgenumberExpiringdate,
                    Enddate: Enddate,
                    //    callSign :callsign ,
                    // budgeDocumentExpiringDate : budgedocumentexpiringdate,
                    driverAddress: driveraddresss,
                    //   driverBadgeNumber : driverbadgenumberr,

                    do_you_own_a_car: do_you_own_a_car.value,

                    email: email,
                    firstName: firstname,
                    lastName: driver_last_name,
                    //   city: city,
                    phone: driver_phone,
                    emergencyContact: emergencyContact,

                    token: key,

                    user_Drivers_key: key,
                    driverid: key,


                    personalidcardtype: personalidcardtype.value,
                    // nhisexpiringdate: nhisexpiringdates,
                    //  ssnitexpiringdate: ssnitexpiringdates,

                    passportExpiryDate: proveidtypeexpiringdates,
                    personalidexpiringdate: proveidtypeexpiringdates,
                    drivingLicenceExpiringDate: drivinglincenceexpiringdate,


                    //    privateHiredriverslincencepaperformexpiringdate:privateHiredriverslincencepaperformexpiringdate,
                    //    DBSPoliceReportexpiringdate : DBSPoliceReportexpiringdates ,


                    createdAt: datecreated,
                  //  status: status

                })
                .then(success =>{
                    
                proveidtypeback2(key);
                proveidtypefront1(key);
                drivinglincencefront1(key);
                drivinglincenceback2(key);
                    profilePhototure(key);
 
                   // var keys = key;
                    assignnewdrivers1(key);
                
                
                } ) 
                .then(async success => {
                 
                    const Toast = Swal.mixin({
                       // ld(),
                        toast: true,
                        position: 'top-right',
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 5500,
                        timerProgressBar: true
                    })
                    await Toast.fire({
                        icon: 'success',
                        title: ' Driver registration',
                        text: 'Successful!',
                        //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
                        
                    })

                    document.getElementById("hidediv").style.display = 'none';
                 setTimeout(function () { window.location = "/driversregistrationview"; }, 900);
              // clearandresetform();

                }
                )
               .catch(async function ( error) {
                // Handle Errors here.
                //   $("#modalz").hide();

                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
                console.log(error);

                // document.getElementById("hidediv").style.display = 'none';


                // Swal.fire({
                //     icon: 'error',
                //     title: 'Registration Error',
                //     text: 'Something went wrong!',
                //     footer: '<a href="">Driver data already in use by another account!</a>'
                // })


                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-right',
                    iconColor: 'white',
                    customClass: {
                    popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 5500,
                    timerProgressBar: true
                  })
                  await Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                   // text: 'Something went wrong!',
                    footer: '<a href="#" style="color:white;font-weight:bold">Error “Driver data already in use by another account!.</a>'
                  })


                  document.getElementById("hidediv").style.display = 'none';






            })
 
    }
}
 

 






 

 















 
 

function updateVehicle(key) {

    firebase
        .database()
        .ref()
        .child('akwaaba/vehicleInfo/' + key)
        .update({
            vehicleid: key,
        });



}






















function Updatedriver() {
    // function Updatedriver(keys, key) {

    var key =  document.getElementById("driveridupdatecarid").value;
    var thedriverid =  document.getElementById("driveridupdate").value;

    var carColor = document.getElementById("carColor").value;

    const carManufacturer = document.getElementById("carManufacturer").value;


    const vehiclemodel = document.getElementById("vehiclemodel").value;


    const licensePlate = document.getElementById("vehicleRegistrationNumber").value;
    var vehicleInfo = carManufacturer + " " + vehiclemodel + " - " + licensePlate;

    firebase
        .database()
        .ref()
        //  .child("/akwaaba/drivers/" + key)
        .child("akwaaba/drivers/" + thedriverid)
        .update({
          //  vehicleid: key,
            vehicleInfo: vehicleInfo,

        });



}





function vehicleImagefront1(key) {
    // function vehicleImagefront1(key, keys) {

     console.log("now file" + key);
    // var key =  document.getElementById("driveridupdatecarid").value;
    // var keys =  document.getElementById("driveridupdate").value;
    // var thedriverid =  document.getElementById("driveridupdatecarid").value;

    const filechk = document.querySelector("#photosimagecar").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#photosimagecar").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            vehicleImage: url,
                        });
           //         updateVehileassignedvehiclephoto(keys, url);
                });

            }
        );

    }

}

function updateVehileassignedvehiclephoto(keys, url) {
    //   var   profilePhotoURL =
    firebase
        .database()
        .ref()
        .child("akwaaba/assignDrivers/" + keys)
        .update({
            vehicleImage: url,

        });


    //  clearform();

}













function proofofplatingfrontget(key) {


    const filechkss = document.querySelector("#proofofplatingfront").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#proofofplatingfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            proofofPlatingFront: url,

                        });

                });

            }
        );


    }
}












function proofofplatingbackget(key) {


    const filechkss = document.querySelector("#proofofplatingback").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#proofofplatingback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            proofofPlatingBack: url,

                        });

                });

            }
        );


    }
}










function hireLeaseAgreementfront3(key) {


    const filechkss = document.querySelector("#hireLeaseAgreement").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#hireLeaseAgreement").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //  hireLeaseAgreementfront: url,
                            hireLeaseAgreement: url,

                        });

                });

            }
        );


    }
}








function hireLeaseAgreementback4(key) {

    const filechkss = document.querySelector("#hireLeaseAgreementback").files[0];

    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#hireLeaseAgreementback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            hireLeaseAgreementback: url,
                        });

                });

            }
        );


    }
}























function v5cdocumentlogbook3(key) {


    const filechkss = document.querySelector("#v5cdocumentlogbook").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cdocumentlogbook").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            V5cHireLeaseAgreement: url,
                        });

                });

            }
        );


    }
}








function v5cdocumentlogbookbackget(key) {


    const filechkss = document.querySelector("#v5cback").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            V5cHireLeaseAgreementback: url,

                        });

                });

            }
        );


    }
}
































function v5cdocumentlogbook3_duplicate(key) {


    const filechkss = document.querySelector("#v5cdocumentlogbook").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cdocumentlogbook").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            V5cHireLeaseAgreement: url,
                        });

                });

            }
        );


    }
}








function v5cdocumentlogbookback(key) {


    const filechkss = document.querySelector("#v5cdocumentlogbookback").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cdocumentlogbookback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            V5cHireLeaseAgreementback: url,

                        });

                });

            }
        );


    }
}



































































function budgenumberdocumentback1(key) {


    const filechk = document.querySelector("#budgenumberdocumentback").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#budgenumberdocumentback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            budgeNumberDocumentBack: url,

                        });

                });

            }
        );

    }

}








function budgenumberdocumentfront1(key) {


    const filechk = document.querySelector("#budgenumberdocumentfront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#budgenumberdocumentfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            budgeNumberDocumentFront: url,

                        });

                });

            }
        );

    }

}









// Insurance 




function nationalinsuranceback1(key) {


    const filechk = document.querySelector("#nationalinsuranceback").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#nationalinsuranceback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            nationalinsuranceBack: url,

                        });

                });

            }
        );

    }

}








function nationalinsurancefront1(key) {


    const filechk = document.querySelector("#nationalinsurancefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#nationalinsurancefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            nationalinsuranceFront: url,

                        });

                });

            }
        );

    }

}


















function DBSPoliceReportfront1(key) {


    const filechk = document.querySelector("#DBSPoliceReportfront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#DBSPoliceReportfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            DBSPoliceReportfront: url,

                        });

                });

            }
        );

    }

}









function DBSPoliceReportback2(key) {

    const filechks = document.querySelector("#DBSPoliceReportback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#DBSPoliceReportback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //    .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            DBSPoliceReportback: url,


                        });

                });

            }
        );

    }
}





function assignnewdriversnew(keys) {


    var carColor = document.getElementById("carColor").value;
    const carName = document.getElementById("carType").value;

    // const carManufacturer = document.getElementById("makeSel").value;

    const carManufacturer = document.getElementById("carManufacturer").value;
    const carYear = document.getElementById("carYear").value;


    const license = document.getElementById("license").value;
    const carType = document.getElementById("carType").value;
    const SeatCapacitys = document.getElementById("SeatCapacity").value;
    const vehiclemodel = document.getElementById("vehiclemodel").value;

    const SeatCapacity = SeatCapacitys + " " + "Seater";

    const CallSign = document.getElementById("Callsignv").value;
    const licensePlate = document.getElementById("licensePlate").value;


    //const licensePlate = document.getElementById("licensePlate").value;
    var status = "approved";

    var vehicleInfo = carManufacturer + " " + vehiclemodel + " - " + licensePlate;
    //   var thekey = firebase.database().ref('akwaaba/assignDrivers/' + key).set({
    firebase.database().ref('akwaaba/assignDrivers/' + keys).set({
        CallSign: CallSign,
        firstName: document.getElementById('driver_first_name').value,
        lastName: document.getElementById('driver_last_name').value,
        email: document.getElementById('driver_email').value,
        phone: document.getElementById('driver_phone').value,
        //nationalIDNumber : document.getElementById('driver_list_nationalIDNumber').value ,
        City: document.getElementById('city').value,
        //  profilePhotoURL:   document.getElementById('driver_list_profilePhotoURL').value ,               
        DriverID: keys,

        vehicle_id: keys,

        carColor: carColor,
        carName: carName,
        carManufacturer: carManufacturer,
        carYear: carYear,
        license: license,

        carType: carType,
        SeatCapacity: SeatCapacity,
        vehiclemodel: vehiclemodel,
        licensePlate: licensePlate,
        status: status,
        vehicleInfo: vehicleInfo,

    }, function (error) {
        if (error) {
            // The write failed...
            console.log(error + "Error in saving");
            /*
            $.toast({
              heading: 'Driver  Assignment',
              text: 'Registration Error'  ,
              position: "top-right",
              icon: "error",
              showHideTransition: "slide",
              textColor: "#fff",
              hideAfter: 3000, 
                  });
                  // */

        } else {


            //console.log("Assigned Data saved successfully!");
            // Data saved successfully!
            /*
            //console.log("Data saved successfully!"  );
          $.toast({
            heading: 'Driver  Assignment',
            text: 'Saving........',
            position: "top-right",
            icon: "success",
            showHideTransition: "slide",
            textColor: "#fff",
            hideAfter: 3000, 
           });
      //     */
            //    document.getElementById("driver_list_firstName").value="";
            //vehicles_list_id2


        }
    });

    //;
    // window.setTimeout(function() {

    //  var keys = thekey.getKey();
    //  console.log("The Keys" + keys)
    //}, 1000);

    window.setTimeout(function () {

        // UpdateVehileassigned(key);
    }, 2000);


    //  var dk = document.getElementById("vehicles_list");
    //  var keys =  document.getElementById("vehicles_list_id2").value;
    //////alert(keys);
    // UpdateVehicleapproved(keys);
    // UpdateVehile(key) ;

    // }
}


function assignnewdrivers1(key) {


    var carColor = document.getElementById("carColor").value;
    // const v5cdocumentlogbook = document.getElementById("v5cdocumentlogbook").value;
   // const v5cdocumentlogbookexpiringdate = document.getElementById("v5cdocumentlogbookexpiringdate").value;

    const carManufacturer = document.getElementById("carManufacturer").value;
    const carYear = document.getElementById("carYear").value;


    // const license = document.getElementById("license").value;
    const carType = document.getElementById("carType").value;
    const SeatCapacitys = document.getElementById("SeatCapacity").value;
    const vehiclemodel = document.getElementById("vehiclemodel").value;
    const SeatCapacity = SeatCapacitys + " " + "Seater";

    //const CallSign = document.getElementById("Callsignv").value;
    const CallSign = "Non";

    const licensePlate = document.getElementById("licensePlate").value;


    //const licensePlate = document.getElementById("licensePlate").value;
    var status = "approved";

    var vehicleInfo = carManufacturer + " " + vehiclemodel + " - " + licensePlate;
    //   var thekey = firebase.database().ref('akwaaba/assignDrivers/' + key).set({
    firebase.database().ref('akwaaba/assignDrivers/' + key).set({
        CallSign: CallSign,
        firstName: document.getElementById('driver_first_name').value,
        lastName: document.getElementById('driver_last_name').value,
        email: document.getElementById('driver_email').value,
        phone: document.getElementById('driver_phone').value,
        //nationalIDNumber : document.getElementById('driver_list_nationalIDNumber').value ,
        //   City: document.getElementById('city').value,
        //  profilePhotoURL:   document.getElementById('driver_list_profilePhotoURL').value ,               
        DriverID: key,

        // vehicle_id: key,

        carColor: carColor,
        //   carName: carName,
        carManufacturer: carManufacturer,
        carYear: carYear,
        //    license: license,

        carType: carType,
        SeatCapacity: SeatCapacity,
        vehiclemodel: vehiclemodel,
        licensePlate: licensePlate,
        status: status,
        vehicleInfo: vehicleInfo,

    }, function (error) {
        if (error) {
            // The write failed...
            console.log(error + "Error in saving");
            /*
            $.toast({
              heading: 'Driver  Assignment',
              text: 'Registration Error'  ,
              position: "top-right",
              icon: "error",
              showHideTransition: "slide",
              textColor: "#fff",
              hideAfter: 3000, 
                  });
                  // */

        } else {


            //console.log("Assigned Data saved successfully!");
            // Data saved successfully!
            /*
            //console.log("Data saved successfully!"  );
          $.toast({
            heading: 'Driver  Assignment',
            text: 'Saving........',
            position: "top-right",
            icon: "success",
            showHideTransition: "slide",
            textColor: "#fff",
            hideAfter: 3000, 
           });
      //     */
            //    document.getElementById("driver_list_firstName").value="";
            //vehicles_list_id2


        }
    });

    //;
    // window.setTimeout(function() {

    //  var keys = thekey.getKey();
    //  console.log("The Keys" + keys)
    //}, 1000);

    window.setTimeout(function () {

        UpdateVehileassigned(key);
    }, 2000);


    //  var dk = document.getElementById("vehicles_list");
    //  var keys =  document.getElementById("vehicles_list_id2").value;
    //////alert(keys);
    // UpdateVehicleapproved(keys);
    // UpdateVehile(key) ;

    // }
}

function UpdateVehileassigned(key) {
    //   var   profilePhotoURL =
    firebase
        .database()
        .ref()
        .child("akwaaba/assignDrivers/" + key)
        .update({
            assignDriver_id: key,
            //      profilePhotoURL:document.getElementById("driver_list_profilePhotoURL").value ,
        });


    //  clearform();

}



function profilePhototure(key) {

    const filechks = document.querySelector("#profile_pics").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#profile_pics").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            profilePhoto: url,
                        });

                    updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}


function privateHiredriverslincencepaperformfrontfront(key) {

    const filechks = document.querySelector("#privateHiredriverslincencepaperformfront").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#privateHiredriverslincencepaperformfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            privateHiredriverslincencepaperformfront: url,
                        });

                    //
                    // updateVehileassignedphoto(key, url);
                    //
                });

            }
        );

    }
}




function privateHiredriverslincencepaperformback_back(key) {

    const filechks = document.querySelector("#privateHiredriverslincencepaperformback").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#privateHiredriverslincencepaperformback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            privateHiredriverslincencepaperformback: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}










function councilPrivatehiredriverbadge_front(key) {

    const filechks = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            councilPrivatehiredriverbadgefront: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}









function councilPrivatehiredriverbadge_back(key) {

    const filechks = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            councilPrivatehiredriverbadgeback: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}



/*
 
  function councilPrivatehiredriverbadge_frontx(key) {
 
      const filechks = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
      if (filechks == null) {
 
          //console.log("now file");
 
      } else {
 
          const file = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
          const metadata = {
              contentType: file.type,
          };
          var Uploadtask;
 
          var uniq = 'id' + (new Date()).getTime();
          //uploading files
          Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);
 
          Uploadtask.on("state_changed", function(snapshot) {
                  var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  document.getElementById("progress").value = percentage;
 
              },
              function error() {
                  ////alert("error uploading file");
              },
 
              function() {
                  Uploadtask.snapshot.ref.getDownloadURL().then(function(url) {
 
                      //  document.getElementById("driver_list_profilePhotoURL").value = "";
                      //   document.getElementById("driver_list_profilePhotoURL").value = url;
 
 
                      firebase
                          .database()
                          .ref()
                          .child("/akwaaba/drivers/" + key)
                          .update({
                              councilPrivatehiredriverbadgefront: url,
                          });
 
                      // updateVehileassignedphoto(key, url);
                  });
 
              }
          );
 
      }
  }
 
 
 
  */





function councilPrivatehiredriverbadge_backs(key) {

    const filechks = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            councilPrivatehiredriverbadgeback: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}














function updateVehileassignedphoto(key, url) {
    //   var   profilePhotoURL =
    firebase
        .database()
        .ref()
        .child("akwaaba/assignDrivers/" + key)
        .update({
            profilePhotoURL: url,

        });


    //  clearform();

}


function proveidtypefront1(key) {


    const filechk = document.querySelector("#proveidtypefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#proveidtypefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            proveidtypefront: url,
                            passport: url

                        });

                });

            }
        );

    }

}



function proveidtypeback2(key) {

    const filechks = document.querySelector("#proveidtypeback").files[0];
    if (filechks == null) {

        //console.log("now file");


    } else {

        const file = document.querySelector("#proveidtypeback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            proveidtypeback: url,
                            passportBack: url



                        });

                });

            }
        );

    }
}







function nhis3(key) {


    const filechkss = document.querySelector("#nhis").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#nhis").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            nhis: url,

                        });

                });

            }
        );


    }
}





function ssnit4(key) {



    const filechkss = document.querySelector("#ssnit").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#ssnit").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            ssnit: url,
                        });

                });

            }
        );


    }
}



















 

function Updatedriverwithvehicleid(key, carManufacturer, vehiclemodel, licensePlate) {

    var vehicleInfo = carManufacturer + " " + vehiclemodel + " - " + licensePlate;


    firebase
        .database()
        .ref()
        .child("/akwaaba/drivers/" + key)
        .update({

            vehicleid: key,
            vehicleInfo: vehicleInfo,
        });



}






function drivinglincencefront1(key) {


    const filechk = document.querySelector("#drivinglincencefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#drivinglincencefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        //    .child('akwaaba/vehicleInfo/' + key)
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            drivinglincencefront: url,
                        });

                });

            }
        );

    }

}




function drivinglincenceback2(key) {

    const filechks = document.querySelector("#drivinglincenceback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#drivinglincenceback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //'akwaaba/vehicleInfo/'  + key
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            drivinglincenceback: url,


                        });

                });

            }
        );

    }
}






function dvlamotfront3(key) {


    const filechkss = document.querySelector("#dvlamotfront").files[0];

    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#dvlamotfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   dvlamotfront: url,
                            motTestCertificate: url,

                        });

                });

            }
        );


    }
}



function dvlamotback4(key) {



    const filechkss = document.querySelector("#dvlamotback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#dvlamotback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            // dvlamotback: url,

                            motTestCertificateBack: url,

                        });

                });

            }
        );


    }
}
















function policeclearancecertificatefront1(key) {


    const filechk = document.querySelector("#policeclearancecertificatefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#policeclearancecertificatefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            policeclearancecertificatefront: url,

                        });

                });

            }
        );

    }

}









function policeclearancecertificateback2(key) {

    const filechks = document.querySelector("#policeclearancecertificateback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#policeclearancecertificateback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            policeclearancecertificateback: url,


                        });

                });

            }
        );

    }
}




function ownershipfront3(key) {


    const filechkss = document.querySelector("#ownershipfront").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#ownershipfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //  ownershipfront: url,
                            hireLeaseAgreement: url,

                        });

                });

            }
        );


    }
}








function ownershipback4(key) {



    const filechkss = document.querySelector("#ownershipback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#ownershipback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            ownershipback: url,
                        });

                });

            }
        );


    }
}













function insurancepolicyfront1(key) {


    console.log("insurance now file" + key);

    const filechk = document.querySelector("#insurancepolicyfront").files[0];

    if (filechk == null) {

        //console.log("now file" + key);


    } else {


        const file = document.querySelector("#insurancepolicyfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   insurancepolicyfront: url,
                            privateHireInsuranceCertificate: url,
                        });

                });

            }
        );

    }

}














function insurancepolicyback2(key) {

    const filechks = document.querySelector("#insurancepolicyback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#insurancepolicyback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            insurancepolicyback: url,


                        });

                });

            }
        );

    }
}







function roadworthinessfront3(key) {


    const filechkss = document.querySelector("#roadworthinessfront").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessfront: url,

                        });

                });

            }
        );


    }
}







function roadworthinessback4(key) {



    const filechkss = document.querySelector("#roadworthinessback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessback: url,
                        });

                });

            }
        );


    }
}







function dvlaprivatehirepermitfront1(key) {


    const filechk = document.querySelector("#dvlaprivatehirepermitfront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#dvlaprivatehirepermitfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            dvlaprivatehirepermitfront: url,

                        });

                });

            }
        );

    }

}





//       dvlaprivatehirepermitback2(key);

function dvlaprivatehirepermitback2(key) {

    const filechks = document.querySelector("#dvlaprivatehirepermitback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#dvlaprivatehirepermitback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            dvlaprivatehirepermitback: url,


                        });

                });

            }
        );

    }
}






function drivinglincencefront1_display(key) {


    const filechk = document.querySelector("#drivinglincencefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#drivinglincencefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        // .child('akwaaba/vehicleInfo/'  + key)
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            drivinglincencefront: url,

                        });

                });

            }
        );

    }

}




function drivinglincenceback2display(key) {

    const filechks = document.querySelector("#drivinglincenceback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#drivinglincenceback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        // .child("/akwaaba/drivers/" + key)
                        //'akwaaba/vehicleInfo/'  + key
                        // .child('akwaaba/vehicleInfo/'  + key)
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            drivinglincenceback: url,


                        });

                });

            }
        );

    }
}





















function clearandresetform() {

    document.getElementById("formpagereset").reset();


    document.getElementById("driver_password").value = "";
    document.getElementById("driver_last_name").value = "";
    document.getElementById("driver_first_name").value = "";

    document.getElementById("driver_email").value = "";
    document.getElementById("driver_phone").value = "";
    document.getElementById("driveraddress").value = "";
    document.getElementById("emergencyContact").value = "";

    // document.getElementById("driver_first_name").value = "";
    // document.getElementById("driver_last_name").value = "";

       document.getElementById("vehiclemodel").value = "";
        document.getElementById("licensePlate").value = "";
       document.getElementById("licensePlateNumber").value = "";
       document.getElementById("licensePlateNumberexpiringdate").value = "";
       document.getElementById("roadworthinessexpiringdate").value = "";
       document.getElementById("insuranceRenewaldate").value = "";
       document.getElementById("vehicleaddress").value = "";
       document.getElementById("SeatCapacity").value = "";



    document.getElementById('previewImg').setAttribute('src', '');
    
    document.getElementById('VehiclepreviewImg').setAttribute('src', '');


    document.getElementById("carManufacturer").selectedIndex = 0;
    document.getElementById("carColor").selectedIndex = 0;
    document.getElementById("carYear").selectedIndex = 0;
    document.getElementById("carType").selectedIndex = 0;
    //document.getElementById("carYear").selectedIndex = 0;
    
  
    //    document.getElementById("driver_first_name").value = "";
    //    document.getElementById("driver_last_name").value = "";
    //    document.getElementById("driver_first_name").value = "";
    //    document.getElementById("driver_last_name").value = "";

    // const inputsimg = document.querySelectorAll('img');
    // const input = document.querySelectorAll('input');
    // const inputselect = document.querySelectorAll('select');
    // setTimeout(function() { 
    //     document.getElementById("formpagereset").reset();
    //   //  document.getElementById("inputsimg").src="";
    //     // document.getElementById("driver_first_name").value="";
    //     // document.getElementById("driver_last_name").value="";
    //       document.getElementById("hidediv").style.display = 'none';
    //       }, 1000);

    document.getElementById('Nodriverwithnocardivbox').style.display = "block";

    document.getElementById('driverwithnocardivbox').style.display = "none";
  //  document.getElementById('Submits').disabled = true ;



}







function roadworthinessfrontnew3(key) {

        console.log("Road now file" + key);

    const filechkss = document.querySelector("#roadworthinessfront").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", async function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;
            

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessfront: url,
                            roadworthinessFront : url,

                        });

                });
                document.querySelector('#roadworthinessfront').value='';

            }
        );


    }
}







function roadworthinessbacknew3(key) {



    const filechkss = document.querySelector("#roadworthinessback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessback: url,
                            roadworthinessBack : url,
                        });

                });
                document.querySelector('#roadworthinessback').value='';

            }
        );


    }
}


