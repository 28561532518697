import React, { useState, useEffect } from 'react';

  const MainContentAnalysis = () => {


  return (
    <div>
    
<div class="row">
                                  
                                  <main>
                                 
                       
                                  <div class="insights">
                                      <div class="sales" href="home">
                                   <a href="/driversregistrationview"> 
                                          <span class="material-icons-sharp">people</span>
                                          <div class="middle">
                                              <div class="left">
                                                  <h3>Total Drivers</h3>
                                                  <h2><b id="themainonlineactivevehiclesapprovred"></b></h2>
                                              </div>
                                              <div class="progress">
                                                  <svg>
                                                      <circle cx="38" cy="38" r="36"></circle>
                                                  </svg>
                                                  <div class="number">
                                                      {/* <p>81%</p> */}
                                                  </div>
                                              </div>
                                          </div>
                                          <small class="text-muted">Last 24 Hours</small>
                                          </a>
                                      </div>
                                    
                                      <div class="expenses">
                                      <a href="main_vehicle_registered_link">
                                          <span class="material-icons-sharp">time_to_leave</span>
                                          <div class="middle">
                                              <div class="left">
                                                  <h3>Total Vehicles</h3>
                                                  <h2><b id="themainonlineallvehicleInfo"></b></h2>
                                              </div>
                                              <div class="progress">
                                                  <svg>
                                                      <circle cx="38" cy="38" r="36"></circle>
                                                  </svg>
                                                  <div class="number">
                                                     
                                                  </div>
                                              </div>
                                          </div>
                                          <small class="text-muted">Last 24 Hours</small>
                                          </a>
                                      </div>
                      
                                      <div class="sale">
                                      <a href="main_customers_allregistered_link">
                                          <span class="material-icons-sharp">groups</span>
                                          <div class="middle">
                                              <div class="left">
                                                  <h3>Total Customers</h3>
                                                  <h2><b id="theonlineallcustomers"></b></h2>
                                              </div>
                                              <div class="progress">
                                                  <svg>
                                                      <circle cx="38" cy="37" r="36"></circle>
                                                  </svg>
                                                  <div class="number">
                                                   
                                                  </div>
                                              </div>
                                          </div>
                                          <small class="text-muted">Last 24 Hours</small>
                                          </a>
                                      </div>
                                    
                                      
                              
                                      <div class="income">
                                          <span class="material-icons-sharp">stacked_line_chart</span>
                                          <div class="middle">
                                              <div class="left">
                                                  <h3>Fleet owners</h3>
                                                  <h2>100</h2>
                                              </div>
                                              <div class="progress">
                                                  <svg>
                                                      <circle cx="38" cy="38" r="36"></circle>
                                                  </svg>
                                                  <div class="number">
                                                   
                                                  </div>
                                              </div>
                                          </div>
                                          <small class="text-muted">Last 24 Hours</small>
                                      </div>
                                     
                                  </div>
                             
                              </main>
                       
                                          </div>
                                          
                                          
                      
    </div>
  )
}


export default MainContentAnalysis;