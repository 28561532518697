import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'

import { LoginWithEmailAndPassword } from "./LoginAuth";
 
//import './StyleLogins.css';

import './Style.css';

import yiedzelogos from "../Assert/ydws.png";

function Login() {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showText, setShowText] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //  const { email, password } = e.target.elements;
    //  console.log(email.value + " Email" + password.value);
    //  alert(email.value + " Email" + password.value);

    setShowText(false);
    setLoading(true);

    if (!email || !password) {
      //  alert("Please enter name / password")
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: '<a href="#">Login input  error?</a>'
      })
      //    setLoading(true);
      //  setShowText(true);
    }
    else {


      try {

        LoginWithEmailAndPassword(email, password);
 
      }
      catch (error) {
        setShowText(true);
        setLoading(false);
      }


    }
  };

  return (
    <>

      <div class="box">

        <div id="loaderid" >
          <span class="loaders"></span>
        </div>

        <div class="row">
          <div id="errorpassword" class="errorpassword  alert alert-danger" >
            <strong>Warning! : </strong> <>Invalid Email or Password.</>
            <br />
          </div>
        </div>
<span>
  <img src={yiedzelogos}   width="100%" height="75px" />
</span>
        <h2>Sign in</h2>
        <p>Use your valid credentials Account</p>
        <form onSubmit={handleSubmit}>
          <div class="inputBox">
            <input type="email" name="email"
                value={email} onChange={(e) => { setEmail(e.target.value) }}
              pattern="[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"
              style={{ textTransform: "lowercase" }}
              required
              placeholder="."
            />

            <label>Email</label>
          </div>

          <div class="inputBox">
            <input type="text" name="password"
              value={password} onChange={(e) => { setPassword(e.target.value) }}
              // pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$" 
              title="Passwords must contain at least six characters, including uppercase, lowercase letters and numbers"
              required
              placeholder=" ."
            />
            <label>Passward</label>
          </div>

          <input type="submit" name="sign-in" value="Sign In"
        
            disabled={!email || !password  }
          />

        </form>


      </div>

    </>
  );
}


export default Login;
