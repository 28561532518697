import  React, { useState , useEffect } from 'react';

import {initialize  } from './maindash_maps.js';
import './Asserts/css/bootstrap.min.css';
import './Asserts/css/custom.css';
import './file.css';
import MenuTop from "./MenuTop";
import MenuSideBar from "./MenuSideBar";
import Footer  from './Footer';
import MainContent1 from './MainContent1';
import MainContent from './MainContent';
import MainContentAnalysis from './MainContentAnalysis';

import firebasess from '../Auth/userauth';

 
 
export const AdminDash = () => {

  useEffect(() => {

   // checktheuserLogins();

   
  })

const checktheuserLogins = async () => {
 
 
  firebasess.auth().onAuthStateChanged(function(user) {
    if (user) {
     ////console.log(user.email);
     //alert(13)
    } else {
      // No user is signed in.
      //alert(343)
   window.location.replace("/");
    }
  });
  
  
  
  }

   // var [date,setDate] = useState(new Date());
   const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  
  useEffect(() => {
    document.getElementById("hidediv").style.display = 'inline';


  window.setTimeout(function () {   initialize(); }, 11000) ;
  
  window.setTimeout(function () {  
    // initialize(); 
   console.log("Load 3#0 minute")
  }, 4000) ;

  
  });
    
    useEffect(() => {
       
    
         });

         const onLoginFormSubmit = (e) => {
          e.preventDefault();
      
          document.getElementById("myModal").style.display = 'none';
      
          handleClose();
        };

    return(
        <>
          <div >
              <div class="hidediv" id="hidediv">
                <div class="loading"  ></div>
                </div>
            </div>

<div class="wrapper">


{/* <div class="body-overlay"></div> */}

{/*   <!-- Sidebar  --> */}
        <MenuSideBar/>
   { /* <!-- Sidebar  --> */}
		

         {/* <!-- Page Content  --> */}
          <div id="content">
		
	       {/* Top Bar Manu */}
          <MenuTop />
         {/* Top Bar Manu End */}
			
			
			<div class="main-content">

 
			 {/* Content Analysis  */}
              <MainContentAnalysis />
            {/* Content Analysis End  */}


					 
	         {/* Content Main */}
             
              <div class="row">
              <MainContent1 />  {/* Live Map */}
              <MainContent />
              </div>
         {/* Content Main End  */}
					
                
                    {/* Footer  */}
                     <Footer />
                     {/* Footer End */}
				 
					 
					</div>
                    {/* main content End */}
					 
        </div>{/* content end */}

    </div>   {/* wrapper end */}


    </> 
    )
}

export default AdminDash;