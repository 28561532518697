
//import firebaseb from './Drivers/Pages/Auth/userauth';
import firebase from "../DbConn"
import Swal from 'sweetalert2'
import swal from 'sweetalert';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";

// import { Field } from "formik";
//import moment from 'moment';
//const firebase=9;
 


const DriverVehicleRegister = async () => {

    // document.getElementById("hidediv").style.display = 'inline';


    swal({
        title: "Are you sure of submitting without your Vehicles details ?",
        text: "Once Submit, you have to contact the Yiedze office to assign a new vehicle!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete) {


                submitwithoutdrivervehicledatas();
            }
            else {
                document.getElementById("hidediv").style.display = 'none';
                swal("Cancelled");
            }
        });


};




const DriverVehicleRegisterwithcar = async () => {


    // var keys = "Wa01awffA122300912220A";

    // vehicleinfowithdrivernew(keys)

    document.getElementById("hidediv").style.display = 'inline';
  // vehicleinfowithdrivernew() ;

// /*
    const profile_pics = document.querySelector("#profile_pics").files[0];

    const photosimagecar = document.querySelector("#photosimagecar").files[0];
    if (profile_pics == null || photosimagecar == null) {

        //console.log("now file" + key);
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: 'Something went wrong! ',
            // text: 'Something went wrong!',
            footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
        })
        
        document.getElementById("hidediv").style.display = 'none';

    } else {

        document.getElementById("hidediv").style.display = 'none';
         swal({
            title: "Are you sure of registering the driver ?",
            text: "once submitted, you can never go back!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                   // submitdriverwithacar();
                   submitwithdriver_vehicledata();

                }
                else {

                    document.getElementById("hidediv").style.display = 'none';
                    swal("Cancelled");
                }
            });

 

    }



 //   */



};





export { DriverVehicleRegister, DriverVehicleRegisterwithcar };




















// Your web app's Firebase configuration



/// Upload without Driver




function submitwithoutdrivervehicledatas() {

    //  swal("Submit!", "Done!", "success");
    // var driveraddress = document.getElementById("driveraddress").value;

    var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;
 
    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
        || password == '' || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {


        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="#">Error “Please fill out all required fields.</a>'
        })


    } else {


        // Loading page 
        document.getElementById("hidediv").style.display = 'inline';

        // Field

        var driver_last_name = document.getElementById("driver_last_name").value;
        var firstname = document.getElementById("driver_first_name").value;
        var email = document.getElementById("driver_email").value;
        var emergencyContact = document.getElementById("emergencyContact").value;


        var password = document.getElementById("driver_password").value;
        var driver_phone = document.getElementById("driver_phone").value;
        var driveraddresss = document.getElementById("driveraddress").value;


        var radiosw = document.getElementsByName("proveidtypechkcar");
        var personalidcardtype = Array.from(radiosw).find(radio => radio.checked);
        var proveidtypeexpiringdates = document.getElementById("proveidtypeexpiringdate").value;

 
        var radioss = document.getElementsByName("doyouownchkcar");
        var do_you_own_a_car = Array.from(radioss).find(radio => radio.checked);

        var datecreated = document.getElementById("datecreated").value;

        var drivinglincenceexpiringdate = document.getElementById("drivinglincenceexpiringdate").value;
        //  var privateHiredriverslincencepaperformexpiringdate  = document.getElementById("privateHiredriverslincencepaperformexpiringdate").value;
        //  var drivinglincencenumber = document.getElementById("drivinglincencenumber").value;
        var status = "pending"
        var Enddate = "NA"


        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(userCredential => {
                var key = userCredential.user.uid;
                //  var keys = userCredential.user.uid;


                firebase.database().ref('akwaaba/drivers/' + key).set({


                    //  drivinglincencenumber :drivinglincencenumber ,

                    //  nationalinsuranceNumber :nationalinsurancenumber ,
                    // driverAddres:driveraddresss,
                    //   driverbadgenumberExpiringdate:driverbadgenumberExpiringdate,
                    Enddate: Enddate,
                    //    callSign :callsign ,
                    // budgeDocumentExpiringDate : budgedocumentexpiringdate,
                    driverAddress: driveraddresss,
                    //   driverBadgeNumber : driverbadgenumberr,

                    do_you_own_a_car: do_you_own_a_car.value,

                    email: email,
                    firstName: firstname,
                    lastName: driver_last_name,
                    //   city: city,
                    phone: driver_phone,
                    emergencyContact: emergencyContact,

                    token: key,

                    user_Drivers_key: key,
                    driverid: key,


                    personalidcardtype: personalidcardtype.value,
                    proveOfYourIdType : personalidcardtype.value,
                    proveOfYourIdTypeExpiringDate:proveidtypeexpiringdates,
                    drivingLicenceExpiringDate: drivinglincenceexpiringdate,

                    // passportExpiryDate: proveidtypeexpiringdates,
                    // personalidexpiringdate: proveidtypeexpiringdates,

                    // nhisexpiringdate: nhisexpiringdates,
                    // //  ssnitexpiringdate: ssnitexpiringdates,

                    // passportExpiryDate: proveidtypeexpiringdates,
                    // proveOfYourIdType: proveidtypeexpiringdates,
                    // drivingLicenceExpiringDate: drivinglincenceexpiringdate,


                    //    privateHiredriverslincencepaperformexpiringdate:privateHiredriverslincencepaperformexpiringdate,
                    //    DBSPoliceReportexpiringdate : DBSPoliceReportexpiringdates ,


                    createdAt: datecreated,
                    status: status

                }).then(async success => {
                    // //console.log("Sucess Message " + success)
                    //    swal("Submit!", "Done!", "success");
                    //    $.toast({
                    //        heading: 'Driver  Registration ',
                    //        text: 'Saving ......',
                    //        position: "top-right",
                    //        icon: "success",
                    //        showHideTransition: "slide",
                    //        textColor: "#fff",
                    //        hideAfter: 31000,
                    //    });
                    //    $("#modalz").hide();

                    // Loading page  end
                    // document.getElementById("hidediv").style.display = 'none';


                    // Swal.fire({
                    //     position: 'top-end',
                    //     icon: 'success',
                    //     title: 'Driver  Registration success',
                    //     showConfirmButton: false,
                    //     timer: 1500
                    // })




                    const Toast = Swal.mixin({
                       // ld(),
                        toast: true,
                        position: 'top-right',
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 5500,
                        timerProgressBar: true
                    })
                    await Toast.fire({
                        icon: 'success',
                        title: ' Driver registration',
                        text: 'Successful!',
                        //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
                        
                    })

                    document.getElementById("hidediv").style.display = 'none';



               setTimeout(function () { window.location = "/driversregistration"; }, 900);


                });


                //        setTimeout(function() { window.location = "driversregistration"; }, 5000);

                //vehicleinfo();
                //  /driversregistration

                //  setTimeout(function() { window.location = "https://review.visionminicabs.com/"; }, 7000);


                //     setTimeout(function() { window.location = "driversregistration"; }, 5000);

                //  setTimeout(function() { window.location = "/basevisionminicabscom/driversregistration"; }, 11000);

                profilePhototureWithnovehichle(key);

                proveidtypeback2(key);
                proveidtypefront1(key);

                //    // nhis3(key);
                //    // ssnit4(key);



                drivinglincencefront1(key);
                drivinglincenceback2(key);

               // profilePhototure(key);


                // privateHiredriverslincencepaperformback_back(key);

                // privateHiredriverslincencepaperformfrontfront(key);




                // DBSPoliceReportback2(key);
                // DBSPoliceReportfront1(key);



                //    budgenumberdocumentback1(key);
                //    budgenumberdocumentfront1(key);







                //    nationalinsuranceback1(key);
                //    nationalinsurancefront1(key);




                //*/



                // window.setTimeout(function() {
                //     clearform();
                // }, 5000);
                //     assignnewdrivers1(key);

                clearandresetform();
            })
            // /*
            .catch(async function ( error) {
                // Handle Errors here.
                //   $("#modalz").hide();

                // var errorCode = error.code;
                // var errorMessage = error.message;
                // console.log(errorCode);
                // console.log(errorMessage);
                // console.log(error);

                // document.getElementById("hidediv").style.display = 'none';


                // Swal.fire({
                //     icon: 'error',
                //     title: 'Registration Error',
                //     text: 'Something went wrong!',
                //     footer: '<a href="">Driver data already in use by another account!</a>'
                // })


                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-right',
                    iconColor: 'white',
                    customClass: {
                    popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 5500,
                    timerProgressBar: true
                  })
                  await Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                   // text: 'Something went wrong!',
                    footer: '<a href="#" style="color:white;font-weight:bold">Error “Driver data already in use by another account!.</a>'
                  })


                  document.getElementById("hidediv").style.display = 'none';






            })

        //*
        // /

    }
}




 

const submitwithdriver_vehicledata =  async () => {
   // Loading page 
   document.getElementById("hidediv").style.display = 'inline';

   // Field
    var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;



    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
        || password == '' || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {


            const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 5500,
                timerProgressBar: true
            })
            await Toast.fire({
                icon: 'error',
                title: 'Something went wrong! ',
                // text: 'Something went wrong!',
                footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
            })
            document.getElementById("hidediv").style.display = 'none';


    } else {


        // Loading page 
        document.getElementById("hidediv").style.display = 'inline';

        // Field

        var driver_last_name = document.getElementById("driver_last_name").value;
        var firstname = document.getElementById("driver_first_name").value;
        var email = document.getElementById("driver_email").value;
        var emergencyContact = document.getElementById("emergencyContact").value;


        var password = document.getElementById("driver_password").value;
        var driver_phone = document.getElementById("driver_phone").value;
        var driveraddresss = document.getElementById("driveraddress").value;


        var radiosw = document.getElementsByName("proveidtypechkcar");
        var personalidcardtype = Array.from(radiosw).find(radio => radio.checked);
        var proveidtypeexpiringdates = document.getElementById("proveidtypeexpiringdate").value;




        var radioss = document.getElementsByName("doyouownchkcar");
        var do_you_own_a_car = Array.from(radioss).find(radio => radio.checked);

        var datecreated = document.getElementById("datecreated").value;

        var drivinglincenceexpiringdate = document.getElementById("drivinglincenceexpiringdate").value;
        //  var privateHiredriverslincencepaperformexpiringdate  = document.getElementById("privateHiredriverslincencepaperformexpiringdate").value;
        //  var drivinglincencenumber = document.getElementById("drivinglincencenumber").value;
        var status = "pending"
        var Enddate = "NA"



        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(userCredential => {
                var key = userCredential.user.uid;
                //  var keys = userCredential.user.uid;


                firebase.database().ref('akwaaba/drivers/' + key).set({


                    //  drivinglincencenumber :drivinglincencenumber ,

                    //  nationalinsuranceNumber :nationalinsurancenumber ,
                    // driverAddres:driveraddresss,
                    //   driverbadgenumberExpiringdate:driverbadgenumberExpiringdate,
                    Enddate: Enddate,
                    //    callSign :callsign ,
                    // budgeDocumentExpiringDate : budgedocumentexpiringdate,
                    driverAddress: driveraddresss,
                    //   driverBadgeNumber : driverbadgenumberr,

                    do_you_own_a_car: do_you_own_a_car.value,

                    email: email,
                    firstName: firstname,
                    lastName: driver_last_name,
                    //   city: city,
                    phone: driver_phone,
                    emergencyContact: emergencyContact,

                    token: key,

                    user_Drivers_key: key,
                    driverid: key,


                    proveOfYourIdType : personalidcardtype.value,
                    proveOfYourIdTypeExpiringDate:proveidtypeexpiringdates,
                    
                    // passportExpiryDate: proveidtypeexpiringdates,
                    // personalidexpiringdate: proveidtypeexpiringdates,

                    drivingLicenceExpiringDate: drivinglincenceexpiringdate,


                    //    privateHiredriverslincencepaperformexpiringdate:privateHiredriverslincencepaperformexpiringdate,
                    //    DBSPoliceReportexpiringdate : DBSPoliceReportexpiringdates ,


                    createdAt: datecreated,
                    status: status

                }).then(async success => {
                    
                    // Loading page  end
                 //   document.getElementById("hidediv").style.display = 'none';

                    const Toast = Swal.mixin({
                        // ld(),
                         toast: true,
                         position: 'top-right',
                         iconColor: 'white',
                         customClass: {
                             popup: 'colored-toast'
                         },
                         showConfirmButton: false,
                         timer: 7900,
                         timerProgressBar: true
                     })
                     await Toast.fire({
                         icon: 'success',
                         title: ' Driver registration',
                         text: 'Saving...',
                         //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
                         
                     })
 
                     document.getElementById("hidediv").style.display = 'none';
                     setTimeout(function () { window.location = "/driversregistration"; }, 900);
 

 


                });

 

                proveidtypeback2(key);
                proveidtypefront1(key);
 

                drivinglincencefront1(key);
                drivinglincenceback2(key);

                profilePhototure(key);


                // for car

                var keys = key;
                   assignnewdrivers1(key);
                   vehicleinfowithdrivernew(keys);
                 //      vehicleinfowithdrivernew() ;
                  // roadworthinessfront3(key)
  
               //    setTimeout(function() {  clearandresetform(); }, 8000);
               // clearandresetform();

               
            //    document.getElementById("hidediv").style.display = 'none';
 
            //    setTimeout(function () { window.location = "/driversregistration"; }, 900);
            })
            // /*
            .catch(async function ( error) {
         
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-right',
                    iconColor: 'white',
                    customClass: {
                    popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 5500,
                    timerProgressBar: true
                  })
                  await Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                   // text: 'Something went wrong!',
                    footer: '<a href="#" style="color:white;font-weight:bold">Error “Driver data already in use by another account!.</a>'
                  })

                  
                  document.getElementById("hidediv").style.display = 'none';

 
            })

         

    }
}














$("#registerdriverwithacar").on("click", function (e) {
    e.preventDefault();


    swal({
        title: "Are you sure of registering the driver ?",
        text: "once submitted, you can never go back!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete) {

             //   submitdriverwithacar();

            }
            else {
                swal("Cancelled");
            }
        });



});








const submitdriverwithacar = async () => {
    //  e.preventDefault();

    //  swal("Submit!", "Done!", "success");

    var password = document.getElementById("driver_password").value;
    var driver_last_name = document.getElementById("driver_last_name").value;
    var driver_first_name = document.getElementById("driver_first_name").value;

    var driver_email = document.getElementById("driver_email").value;
    var driver_phone = document.getElementById("driver_phone").value;
    var driveraddress = document.getElementById("driveraddress").value;
    var emergencyContact = document.getElementById("emergencyContact").value;



    //Check input Fields Should not be blanks.
    //Error “Please fill out all required fields.
    if (driveraddress == '' || driveraddress == '' || emergencyContact == ''
        || password == '' || driver_last_name == '' || driver_first_name == ''
        || driver_email == '' || driver_phone == '') {


        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
        })
        await Toast.fire({
            icon: 'error',
            title: 'Something went wrong! ',
            // text: 'Something went wrong!',
            footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
        })
        document.getElementById("hidediv").style.display = 'none';

    } else {




        // Loading page 
        document.getElementById("hidediv").style.display = 'inline';

        // Field

        var driver_last_name = document.getElementById("driver_last_name").value;
        var firstname = document.getElementById("driver_first_name").value;
        var email = document.getElementById("driver_email").value;
        var emergencyContact = document.getElementById("emergencyContact").value;


        var password = document.getElementById("driver_password").value;
        var driver_phone = document.getElementById("driver_phone").value;
        var driveraddresss = document.getElementById("driveraddress").value;


        var radiosw = document.getElementsByName("proveidtypechkcar");
        var personalidcardtype = Array.from(radiosw).find(radio => radio.checked);
        var proveidtypeexpiringdates = document.getElementById("proveidtypeexpiringdate").value;




        var radioss = document.getElementsByName("doyouownchkcar");
        var do_you_own_a_car = Array.from(radioss).find(radio => radio.checked);

        var datecreated = document.getElementById("datecreated").value;

        var drivinglincenceexpiringdate = document.getElementById("drivinglincenceexpiringdate").value;





        //    var drivinglincenceexpiringdate = document.getElementById("drivinglincenceexpiringdate").value;
            var privateHiredriverslincencepaperformexpiringdate  = document.getElementById("privateHiredriverslincencepaperformexpiringdate").value;


                var DBSPoliceReportexpiringdates = document.getElementById("DBSPoliceReportexpiringdate").value;


                 //  var nhisexpiringdates = document.getElementById("nhisexpiringdate").value;

                //   var ssnitexpiringdates = document.getElementById("ssnitexpiringdate").value;
                      var proveidtypeexpiringdates = document.getElementById("proveidtypeexpiringdate").value;
               //    var proveidtypeexpiringdates = "2099-06-29";

                   var email = document.getElementById("driver_email").value;
                   var city = document.getElementById("city").value;
                   var driver_last_name = document.getElementById("driver_last_name").value;
                   var firstname = document.getElementById("driver_first_name").value;

                   var password = document.getElementById("driver_password").value;
                   var driver_phone = document.getElementById("driver_phone").value;



                   var emergencyContact = document.getElementById("emergencyContact").value;
                   var datecreated = document.getElementById("datecreated").value;
                   var status = "pending"


                   var radioss = document.getElementsByName("vmcviews");
                   var do_you_own_a_car = Array.from(radioss).find(radio => radio.checked);

                   // var carconfirm=do_you_own_a_car.value;
                   // var carconfirms=do_you_own_a_car.value;


               var driveraddres = document.getElementById("driveraddress").value;
               var driverbadgenumberr = document.getElementById("driverbadgenumber").value;
               var driverbadgenumberExpiringdates = document.getElementById("driverbadgenumberExpiringdate").value;

               var budgedocumentexpiringdate = document.getElementById("budgedocumentexpiringdate").value;
               var nationalinsurancenumber = document.getElementById("nationalinsurancenumber").value;


               var drivinglincencenumber = document.getElementById("drivinglincencenumber").value;

           //drivinglincencenumber
                    //var callsign ="Call Sign";nationalinsurance

                   var callsign = document.getElementById("callsign").value;
           var Enddate="NA";
                   var radiosw = document.getElementsByName("proveidtypechkcar");
                   var personalidcardtype = Array.from(radiosw).find(radio => radio.checked);

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(userCredential => {
                var key = userCredential.user.uid;
                var keys = userCredential.user.uid;


                firebase.database().ref('akwaaba/drivers/' + key).set({

                    Enddate: Enddate,
                    CallSign: callsign,
                    drivinglincencenumber: drivinglincencenumber,

                    nationalinsuranceNumber: nationalinsurancenumber,

                    budgeDocumentExpiringDate: budgedocumentexpiringdate,
                    driverAddress: driveraddres,
                    driverbadgenumber: driverbadgenumberr,
                    driverbadgenumberExpiringdate: driverbadgenumberExpiringdates,

                    do_you_own_a_car: do_you_own_a_car.value,

                    email: email,
                    firstName: firstname,
                    lastName: driver_last_name,
                    city: city,
                    phone: driver_phone,
                    emergencyContact: emergencyContact,

                    token: key,

                    user_Drivers_key: key,
                    driverid: key,


                    personalidcardtype: personalidcardtype.value,
                    // nhisexpiringdate: nhisexpiringdates,
                    //  ssnitexpiringdate: ssnitexpiringdates,

                    passportExpiryDate: proveidtypeexpiringdates,
                    personalidexpiringdate: proveidtypeexpiringdates,
                    drivingLicenceExpiringDate: drivinglincenceexpiringdate,


                    privateHiredriverslincencepaperformexpiringdate: privateHiredriverslincencepaperformexpiringdate,
                    DBSPoliceReportexpiringdate: DBSPoliceReportexpiringdates,


                    createdAt: datecreated,
                    status: status

                }).then(async success => {
                    // //console.log("Sucess Message " + success)
                    //   swal("Submit!", "Done!", "success");
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-right',
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 5900,
                        timerProgressBar: true
                    })
                    await Toast.fire({
                        icon: 'success',
                        title: ' Driver registration',
                        text: 'Successful!',
                        //footer: '<a href="#" style="color:white;font-weight:bold">Error “Please fill out all required fields.</a>'
                    })

                    document.getElementById("hidediv").style.display = 'none';


                    setTimeout(function () { window.location = "/driversregistration"; }, 900);









                });




                setTimeout(function () { window.location = "driversregistration"; }, 33000);









                assignnewdrivers1(key);
                //   assignnewdriversnew(keys);
                //vehicleinfo();
                //  /driversregistration

                //     setTimeout(function() { window.location = "https://review.visionminicabs.com/"; }, 7000);



                //  setTimeout(function() { window.location = "/basevisionminicabscom/driversregistration"; }, 9000);
                //   setTimeout(function(){ window.location = "permissionsdriverlink"; },3000);


                proveidtypeback2(key);
                proveidtypefront1(key);

                // nhis3(key);
                // ssnit4(key);



                drivinglincencefront1(key);
                drivinglincenceback2(key);

                profilePhototure(key);



                privateHiredriverslincencepaperformback_back(key);

                privateHiredriverslincencepaperformfrontfront(key);




                DBSPoliceReportback2(key);
                DBSPoliceReportfront1(key);



                var keys = key;
             //   assignnewdrivers1(key);
                vehicleinfowithdrivernew(keys);
                //vehicleinfo(key);


                //     setTimeout(function() { window.location = "driversregistration"; }, 8000);


                //  assignnewdriversnew(key);


                nationalinsuranceback1(key);
                nationalinsurancefront1(key);

                budgenumberdocumentback1(key);
                budgenumberdocumentfront1(key);
                //nationalinsurance


            })
        // 
        /*
        .catch(function(error) {
            // Handle Errors here.
            $("#modalz").hide();
 
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            $.toast({
                heading: 'Registration Error',
                text: 'Driver data already in use by another account.',
                position: "top-right",
                icon: "error",
                showHideTransition: "slide",
                textColor: "#fff",
                hideAfter: 3000,
            });
        }) 
        
        //*
        // */



        // assignnewdrivers1(key);

    }
}







// function vehicleinfowithdrivernew() {
// var keys ="11111111111112233333333";
  //
    function vehicleinfowithdrivernew(keys) {

    // const v5cdocumentlogbook = document.getElementById("v5cdocumentlogbook").value;
    //   const v5cdocumentlogbookexpiringdate = document.getElementById("v5cdocumentlogbookexpiringdate").value;

    const carManufacturer = document.getElementById("carManufacturer").value;
    const carYear = document.getElementById("carYear").value;
    const carType = document.getElementById("carType").value;
    const carTypes = document.getElementById("carType").value;
   // const SeatCapacitys = document.getElementById("SeatCapacity").value;
    const vehiclemodel = document.getElementById("vehiclemodel").value;
    const SeatCapacity = carTypes + " " + "Seater";
    var carColor = document.getElementById("carColor").value;

    // const  CallSign=document.getElementById("Callsignv").value; 

    const vehicleRegistrationNumber = document.getElementById("vehicleRegistrationNumber").value;
  //  const licensePlateNumber = document.getElementById("licensePlateNumber").value;
    //  const date1 = document.getElementById("date1").value; 
    const status = "pending";

    var radiossss = document.getElementsByName("disabilitychkcar");
    var disabilitychkcar = Array.from(radiossss).find(radio => radio.checked);


    var drivinglincenceexpiringdate = document.getElementById("drivinglincenceexpiringdate").value;
    //    var dvlamotexpiringdate = document.getElementById("dvlamotexpiringdate").value;
    //  var policeclearancecertificateexpiringdate = document.getElementById("policeclearancecertificateexpiringdate").value;

    //    var ownershipexpiringdate = document.getElementById("hireLeaseAgreementexpiringdate").value;
   var insuranceRenewaldate = document.getElementById("insuranceRenewaldate").value;
    // var v5cdocumentlogbook = document.getElementById("v5cdocumentlogbook").value;
    var roadworthinessexpiringdate = document.getElementById("roadworthinessexpiringdate").value;
    //  var dvlaprivatehirepermitfront = document.getElementById("dvlaprivatehirepermitfront").value;
    //  var dvlaprivatehirepermitexpiringdate = document.getElementById("dvlaprivatehirepermitexpiringdate").value;

    var vehicleInfos = carManufacturer + " " + vehiclemodel + " - " + vehicleRegistrationNumber;
    var vehicleaddress = document.getElementById("vehicleaddress").value;

    var createdAt = document.getElementById("datecreated").value;

    //   const popdate = document.getElementById("popdate").value;

    // var proofofplatingexpiringdates = document.getElementById("popdate").value;

  
    // var ownerFullname = document.getElementById("ownerFullname").value;

    // var owner_email = document.getElementById("owner_email").value;

    //var ownerPhoneNumber = document.getElementById("ownerPhoneNumber").value;
    
    //    var ownerAddress = document.getElementById("owneraddresses").value;
    var licensePlateNumberexpiringdate = document.getElementById("licensePlateNumberexpiringdate").value;



    var abc = firebase.database().ref('akwaaba/vehicleInfo/').push({
        //  CallSign:CallSign,
       
  //    licensePlateNumberexpiringdate: licensePlateNumberexpiringdate,
 
        vehicleAddress : vehicleaddress,
        vehicleRegistrationNumber : vehicleRegistrationNumber,
        carFullAddress : vehicleaddress, 
        
        disabilitychkcar : disabilitychkcar.value,
        drivinglincenceexpiringdate : drivinglincenceexpiringdate,
        vehicleInfo : vehicleInfos,

        carColor: carColor,
        carManufacturer : carManufacturer,
        carMakeType : carManufacturer,
        carYear : carYear,
        carType : carType,
 
        SeatCapacity: SeatCapacity,
        seatersAvailable: SeatCapacity,
        vehiclemodel: vehiclemodel,
      
      //  licensePlateNumber: licensePlateNumber, insuranceStickerExpiringDate
        insuranceStickerExpiringDate : insuranceRenewaldate,
        roadworthinessExpiringDate  : roadworthinessexpiringdate,
       // privateHireInsuranceCertificateExpiryDate: insuranceRenewaldate,
    

        //       ownerAddress:ownerAddress,
        //       ownerFullname: ownerFullname,
        //       ownerEmail:owner_email,
        //       ownerPhoneNumber :ownerPhoneNumber,

        //       proofofplatingexpiringdate:proofofplatingexpiringdates,
        //       firebase.database().ref('akwaaba/vehicleInfo/' + key).set({
        //       do_you_own_a_car:do_you_own_a_car.value,
        //       dvlamotexpiringdate: dvlamotexpiringdate,
        //       motTestCertificateBackExpiryDate : dvlamotexpiringdate,
        //       policeclearancecertificateexpiringdate: policeclearancecertificateexpiringdate,

        //       ownershipexpiringdate: ownershipexpiringdate,
        //        hireLeaseAgreementExpiryDate:ownershipexpiringdate,
        //       insuranceRenewaldate: insuranceRenewaldate,

      
        // v5cdocumentlogbook: v5cdocumentlogbook,
        // roadworthinessexpiringdate: roadworthinessexpiringdate,
       

        //license: license, privateHireInsuranceCertificate

        //   dvlaprivatehirepermitfront: dvlaprivatehirepermitfront,
        //    dvlaprivatehirepermitexpiringdate: dvlaprivatehirepermitexpiringdate,
        //        */ 
      
        //  v5cDocumentLogbook: v5cdocumentlogbook,
        //  V5cHireLeaseAgreementdate: v5cdocumentlogbookexpiringdate,
   
        status: status,
        createdAt: createdAt,

    }, function (error) {
        if (error) {
            // The write failed...
            console.log(error + "Error in saving");
        } else {
            //    $("#modalz").hide();
            // Data saved successfully!
            console.log("Data saved successfully! okay");
            // setTimeout(function() { window.location = "driversregistration"; }, 15000);
            // $.toast({
            //     heading: ' Registration Successfully',
            //     text: 'Successful.',
            //     position: "top-right",
            //     icon: "success",
            //     showHideTransition: "slide",
            //     textColor: "#fff",
            //     hideAfter: 3000,
            // });
            
            clearandresetform();

        }
    });

    var key = abc.getKey();

    updateVehicle(key);
    Updatedriver(keys, key)


    //vehicleImage
    vehicleImagefront1(key, keys);


    roadworthinessBackNew(key);
    roadworthinessFrontNew(key);

    
    insurancepolicyfront1(key);
    insurancepolicyback2(key);

    // proofofplatingfrontget(key);
    //  proofofplatingbackget(key); 


    // hireLeaseAgreementfront3(key);
    //hireLeaseAgreementback4(key);

    //console.log(keys + " == then " + 0);

    //  dvlamotfront3(key);
    //  dvlamotback4(key);

    //  v5cdocumentlogbook3(key);
    // v5cdocumentlogbookback(key);
    //   v5cdocumentlogbookbackget(key);
    // hireLeaseAgreementfront3(key);
    // hireLeaseAgreementback4(key);


    // v5cdocumentlogbook3(key);

    //  v5cdocumentlogbookbackget(key);


    // Updatedriver(keys,key)

    // vehicleImagefront1(key,keys);





}

 

function updateVehicle(key) {

    firebase
        .database()
        .ref()
        .child('akwaaba/vehicleInfo/' + key)
        .update({
            vehicleid: key,
        });



}









 
function assignnewdrivers1(key) {


//     var carColor = document.getElementById("carColor").value;
//     // const v5cdocumentlogbook = document.getElementById("v5cdocumentlogbook").value;
//    // const v5cdocumentlogbookexpiringdate = document.getElementById("v5cdocumentlogbookexpiringdate").value;

//     const carManufacturer = document.getElementById("carManufacturer").value;
//     const carYear = document.getElementById("carYear").value;


//     // const license = document.getElementById("license").value;
//     const carType = document.getElementById("carType").value;
//     const SeatCapacitys = document.getElementById("SeatCapacity").value;
//     const vehiclemodel = document.getElementById("vehiclemodel").value;
//     const SeatCapacity = SeatCapacitys + " " + "Seater";

const carManufacturer = document.getElementById("carManufacturer").value;
const carYear = document.getElementById("carYear").value;
const carType = document.getElementById("carType").value;
const carTypes = document.getElementById("carType").value;
// const SeatCapacitys = document.getElementById("SeatCapacity").value;
const vehiclemodel = document.getElementById("vehiclemodel").value;
const SeatCapacity = carTypes + " " + "Seater";
var carColor = document.getElementById("carColor").value;

// const  CallSign=document.getElementById("Callsignv").value; 

const vehicleRegistrationNumber = document.getElementById("vehicleRegistrationNumber").value;

    //const CallSign = document.getElementById("Callsignv").value;
    const CallSign = "Non";

  //  const licensePlate = document.getElementById("licensePlate").value;


    //const licensePlate = document.getElementById("licensePlate").value;
    var status = "approved";

    var vehicleInfo = carManufacturer + " " + vehiclemodel + " - " + vehicleRegistrationNumber;
    //   var thekey = firebase.database().ref('akwaaba/assignDrivers/' + key).set({
    firebase.database().ref('akwaaba/assignDrivers/' + key).set({
        CallSign: CallSign,
        firstName: document.getElementById('driver_first_name').value,
        lastName: document.getElementById('driver_last_name').value,
        email: document.getElementById('driver_email').value,
        phone: document.getElementById('driver_phone').value,
        //nationalIDNumber : document.getElementById('driver_list_nationalIDNumber').value ,
        //   City: document.getElementById('city').value,
        //  profilePhotoURL:   document.getElementById('driver_list_profilePhotoURL').value ,               
        DriverID: key,

        // vehicle_id: key,

        carColor: carColor,
        //   carName: carName,
        carManufacturer: carManufacturer,
        carYear: carYear,
        //    license: license,

        carType: carType,
        SeatCapacity: SeatCapacity,
        vehiclemodel: vehiclemodel,
        vehicleRegistrationNumber: vehicleRegistrationNumber,
        status: status,
        vehicleInfo: vehicleInfo,

    }, function (error) {
        if (error) {
            // The write failed...
            console.log(error + "Error in saving");
            /*
            $.toast({
              heading: 'Driver  Assignment',
              text: 'Registration Error'  ,
              position: "top-right",
              icon: "error",
              showHideTransition: "slide",
              textColor: "#fff",
              hideAfter: 3000, 
                  });
                  // */

        } else {


            //console.log("Assigned Data saved successfully!");
            // Data saved successfully!
            /*
            //console.log("Data saved successfully!"  );
          $.toast({
            heading: 'Driver  Assignment',
            text: 'Saving........',
            position: "top-right",
            icon: "success",
            showHideTransition: "slide",
            textColor: "#fff",
            hideAfter: 3000, 
           });
      //     */
            //    document.getElementById("driver_list_firstName").value="";
            //vehicles_list_id2


        }
    });

    //;
    // window.setTimeout(function() {

    //  var keys = thekey.getKey();
    //  console.log("The Keys" + keys)
    //}, 1000);

    window.setTimeout(function () {

        UpdateVehileassigned(key);
    }, 2000);


    //  var dk = document.getElementById("vehicles_list");
    //  var keys =  document.getElementById("vehicles_list_id2").value;
    //////alert(keys);
    // UpdateVehicleapproved(keys);
    // UpdateVehile(key) ;

    // }
}

function UpdateVehileassigned(key) {
    //   var   profilePhotoURL =
    firebase
        .database()
        .ref()
        .child("akwaaba/assignDrivers/" + key)
        .update({
            assignDriver_id: key,
            //      profilePhotoURL:document.getElementById("driver_list_profilePhotoURL").value ,
        });


    //  clearform();

}



function profilePhototure(key) {

    const filechks = document.querySelector("#profile_pics").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#profile_pics").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed",async function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            profilePhoto: url,
                        });

                    updateVehileassignedphoto(key, url);
                });
                // document.getElementById("previewImg").src=null; 
                // document.getElementById("profile_pics").value=null; 

               // const file =
			//	document.querySelector('#profile_pics').value='';
			//	document.querySelector('#profile_pics').value='';
			//file.value = '';


            }
        );

    }
}










function profilePhototureWithnovehichle(key) {

    const filechks = document.querySelector("#profile_pics").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#profile_pics").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed",async function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            profilePhoto: url,
                        });

                   // updateVehileassignedphoto(key, url);
                });
                // document.getElementById("previewImg").src=null; 
                // document.getElementById("profile_pics").value=null; 

               // const file =
			//	document.querySelector('#profile_pics').value='';
			//	document.querySelector('#profile_pics').value='';
			//file.value = '';


            }
        );

    }
}







function Updatedriver(keys, key) {


    var carColor = document.getElementById("carColor").value;

    const carManufacturer = document.getElementById("carManufacturer").value;


    const vehiclemodel = document.getElementById("vehiclemodel").value;


    const vehicleRegistrationNumber = document.getElementById("vehicleRegistrationNumber").value;
    var vehicleInfo = carManufacturer + " " + vehiclemodel + " - " + vehicleRegistrationNumber;

    firebase
        .database()
        .ref()
        //  .child("/akwaaba/drivers/" + key)
        .child("akwaaba/drivers/" + keys)
        .update({
            vehicleid: key,
            vehicleInfo: vehicleInfo,

        });



}





function vehicleImagefront1(key, keys) {


    const filechk = document.querySelector("#photosimagecar").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#photosimagecar").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            vehicleImage: url,
                            carImage : url,
                        });
                    updateVehileassignedvehiclephoto(keys, url);
                });

            }
        );

    }

}

function updateVehileassignedvehiclephoto(keys, url) {
    //   var   profilePhotoURL =
    firebase
        .database()
        .ref()
        .child("akwaaba/assignDrivers/" + keys)
        .update({
            vehicleImage: url,

        });


    //  clearform();

}













function proofofplatingfrontget(key) {


    const filechkss = document.querySelector("#proofofplatingfront").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#proofofplatingfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            proofofPlatingFront: url,

                        });

                });

            }
        );


    }
}












function proofofplatingbackget(key) {


    const filechkss = document.querySelector("#proofofplatingback").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#proofofplatingback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            proofofPlatingBack: url,

                        });

                });

            }
        );


    }
}










function hireLeaseAgreementfront3(key) {


    const filechkss = document.querySelector("#hireLeaseAgreement").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#hireLeaseAgreement").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //  hireLeaseAgreementfront: url,
                            hireLeaseAgreement: url,

                        });

                });

            }
        );


    }
}








function hireLeaseAgreementback4(key) {

    const filechkss = document.querySelector("#hireLeaseAgreementback").files[0];

    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#hireLeaseAgreementback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            hireLeaseAgreementback: url,
                        });

                });

            }
        );


    }
}























function v5cdocumentlogbook3(key) {


    const filechkss = document.querySelector("#v5cdocumentlogbook").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cdocumentlogbook").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            V5cHireLeaseAgreement: url,
                        });

                });

            }
        );


    }
}








function v5cdocumentlogbookbackget(key) {


    const filechkss = document.querySelector("#v5cback").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            V5cHireLeaseAgreementback: url,

                        });

                });

            }
        );


    }
}
































function v5cdocumentlogbook3_duplicate(key) {


    const filechkss = document.querySelector("#v5cdocumentlogbook").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cdocumentlogbook").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   v5cdocumentlogbook: url,
                            V5cHireLeaseAgreement: url,
                        });

                });

            }
        );


    }
}








function v5cdocumentlogbookback(key) {


    const filechkss = document.querySelector("#v5cdocumentlogbookback").files[0];
    if (filechkss == null) {

        ////console.log("now file" + key);
    } else {


        const file = document.querySelector("#v5cdocumentlogbookback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            V5cHireLeaseAgreementback: url,

                        });

                });

            }
        );


    }
}



































































function budgenumberdocumentback1(key) {


    const filechk = document.querySelector("#budgenumberdocumentback").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#budgenumberdocumentback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            budgeNumberDocumentBack: url,

                        });

                });

            }
        );

    }

}








function budgenumberdocumentfront1(key) {


    const filechk = document.querySelector("#budgenumberdocumentfront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#budgenumberdocumentfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            budgeNumberDocumentFront: url,

                        });

                });

            }
        );

    }

}









// Insurance 




function nationalinsuranceback1(key) {


    const filechk = document.querySelector("#nationalinsuranceback").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#nationalinsuranceback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            nationalinsuranceBack: url,

                        });

                });

            }
        );

    }

}








function nationalinsurancefront1(key) {


    const filechk = document.querySelector("#nationalinsurancefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#nationalinsurancefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            nationalinsuranceFront: url,

                        });

                });

            }
        );

    }

}


















function DBSPoliceReportfront1(key) {


    const filechk = document.querySelector("#DBSPoliceReportfront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#DBSPoliceReportfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            DBSPoliceReportfront: url,

                        });

                });

            }
        );

    }

}









function DBSPoliceReportback2(key) {

    const filechks = document.querySelector("#DBSPoliceReportback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#DBSPoliceReportback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //    .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            DBSPoliceReportback: url,


                        });

                });

            }
        );

    }
}





function privateHiredriverslincencepaperformfrontfront(key) {

    const filechks = document.querySelector("#privateHiredriverslincencepaperformfront").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#privateHiredriverslincencepaperformfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            privateHiredriverslincencepaperformfront: url,
                        });

                    //
                    // updateVehileassignedphoto(key, url);
                    //
                });

            }
        );

    }
}




function privateHiredriverslincencepaperformback_back(key) {

    const filechks = document.querySelector("#privateHiredriverslincencepaperformback").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#privateHiredriverslincencepaperformback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            privateHiredriverslincencepaperformback: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}










function councilPrivatehiredriverbadge_front(key) {

    const filechks = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            councilPrivatehiredriverbadgefront: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}









function councilPrivatehiredriverbadge_back(key) {

    const filechks = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            councilPrivatehiredriverbadgeback: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}



/*
 
  function councilPrivatehiredriverbadge_frontx(key) {
 
      const filechks = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
      if (filechks == null) {
 
          //console.log("now file");
 
      } else {
 
          const file = document.querySelector("#councilPrivatehiredriverbadgefront").files[0];
          const metadata = {
              contentType: file.type,
          };
          var Uploadtask;
 
          var uniq = 'id' + (new Date()).getTime();
          //uploading files
          Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);
 
          Uploadtask.on("state_changed", function(snapshot) {
                  var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  document.getElementById("progress").value = percentage;
 
              },
              function error() {
                  ////alert("error uploading file");
              },
 
              function() {
                  Uploadtask.snapshot.ref.getDownloadURL().then(function(url) {
 
                      //  document.getElementById("driver_list_profilePhotoURL").value = "";
                      //   document.getElementById("driver_list_profilePhotoURL").value = url;
 
 
                      firebase
                          .database()
                          .ref()
                          .child("/akwaaba/drivers/" + key)
                          .update({
                              councilPrivatehiredriverbadgefront: url,
                          });
 
                      // updateVehileassignedphoto(key, url);
                  });
 
              }
          );
 
      }
  }
 
 
 
  */





function councilPrivatehiredriverbadge_backs(key) {

    const filechks = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
    if (filechks == null) {

        //console.log("now file");

    } else {

        const file = document.querySelector("#councilPrivatehiredriverbadgeback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    //  document.getElementById("driver_list_profilePhotoURL").value = "";
                    //   document.getElementById("driver_list_profilePhotoURL").value = url;


                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            councilPrivatehiredriverbadgeback: url,
                        });

                    // updateVehileassignedphoto(key, url);
                });

            }
        );

    }
}














function updateVehileassignedphoto(key, url) {
    //   var   profilePhotoURL =
    firebase
        .database()
        .ref()
        .child("akwaaba/assignDrivers/" + key)
        .update({
            profilePhotoURL: url,

        });


    //  clearform();

}


function proveidtypefront1(key) {


    const filechk = document.querySelector("#proveidtypefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#proveidtypefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            proveOfYourIdTypeFront: url,
                            passport: url

                        });

                });
                document.querySelector('#proveidtypefront').value='';
            }
        );

    }

}



function proveidtypeback2(key) {

    const filechks = document.querySelector("#proveidtypeback").files[0];
    if (filechks == null) {

        //console.log("now file");


    } else {

        const file = document.querySelector("#proveidtypeback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            proveOfYourIdTypeBack: url,
                            passportBack: url
                        });

                });

                document.querySelector('#proveidtypeback').value='';
            }
        );

    }
}







function nhis3(key) {


    const filechkss = document.querySelector("#nhis").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#nhis").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            nhis: url,

                        });

                });

            }
        );


    }
}





function ssnit4(key) {



    const filechkss = document.querySelector("#ssnit").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#ssnit").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            ssnit: url,
                        });

                });

            }
        );


    }
}
























/*
 
function submitdriverToken(key) {
 
  // ////alert("helooo");
//   const fn = document.getElementById("fn");
 // const phone = document.getElementById("phone");
 // const id = document.getElementById("id");
 // const ln = document.getElementById("ln");
 
      firebase.database().ref('akwaaba/drivers/token/' + key).set({
        token: key,
    //  phonenumber:phone.value,
   //  lastname : ln.value
       
   }, function(error) {
       if (error) {
         // The write failed...
         //console.log("Error in saving");
       } else {
         // Data saved successfully!
         //console.log("Data updated successfully!");
       }
     });
   }
*/

 







 




function drivinglincencefront1(key) {


    const filechk = document.querySelector("#drivinglincencefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#drivinglincencefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        //    .child('akwaaba/vehicleInfo/' + key)
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            drivingLicenceFront: url,
                        });

                });

                document.querySelector('#drivinglincencefront').value='';

            }
        );

    }

}




function drivinglincenceback2(key) {

    const filechks = document.querySelector("#drivinglincenceback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#drivinglincenceback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child("/akwaaba/drivers/" + key)
                        //'akwaaba/vehicleInfo/'  + key
                        //  .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            drivingLicenceBack : url,


                        });

                });
                document.querySelector('#drivinglincenceback').value='';

            }
        );

    }
}






function dvlamotfront3(key) {


    const filechkss = document.querySelector("#dvlamotfront").files[0];

    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#dvlamotfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   dvlamotfront: url,
                            motTestCertificate: url,

                        });

                });

            }
        );


    }
}



function dvlamotback4(key) {



    const filechkss = document.querySelector("#dvlamotback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#dvlamotback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            // dvlamotback: url,

                            motTestCertificateBack: url,

                        });

                });

            }
        );


    }
}
















function policeclearancecertificatefront1(key) {


    const filechk = document.querySelector("#policeclearancecertificatefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#policeclearancecertificatefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            policeclearancecertificatefront: url,

                        });

                });

            }
        );

    }

}









function policeclearancecertificateback2(key) {

    const filechks = document.querySelector("#policeclearancecertificateback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#policeclearancecertificateback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            policeclearancecertificateback: url,


                        });

                });

            }
        );

    }
}




function ownershipfront3(key) {


    const filechkss = document.querySelector("#ownershipfront").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#ownershipfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //  ownershipfront: url,
                            hireLeaseAgreement: url,

                        });

                });

            }
        );


    }
}








function ownershipback4(key) {



    const filechkss = document.querySelector("#ownershipback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#ownershipback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            ownershipback: url,
                        });

                });

            }
        );


    }
}












function insurancepolicyfront1(key) {


    const filechk = document.querySelector("#insurancepolicyfront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#insurancepolicyfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            //   insurancepolicyfront: url,
                            insuranceStickerFront : url,
                            privateHireInsuranceCertificate: url,
                        });

                });
                document.querySelector('#insurancepolicyfront').value='';

            }
        );

    }

}














function insurancepolicyback2(key) {

    const filechks = document.querySelector("#insurancepolicyback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#insurancepolicyback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({

                            insurancepolicyback: url,
                            insuranceStickerBack:url,

                        });

                });
                document.querySelector('#insurancepolicyback').value='';

            }
        );

    }
}











function roadworthinessfront3(key) {


    const filechkss = document.querySelector("#roadworthinessfront").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessfront: url,
                            roadworthinessFront : url,

                        });

                });

            }
        );


    }
}







function roadworthinessback4(key) {



    const filechkss = document.querySelector("#roadworthinessback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessback: url,
                            roadworthinessBack : url,
                        });

                });

            }
        );


    }
}




//roadworthinessBackNew(key)
//roadworthinessFrontNew(key)
function roadworthinessFrontNew(key) {


    const filechkss = document.querySelector("#roadworthinessfront").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", async function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;
            

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessfront: url,
                            roadworthinessFront : url,

                        });

                });
                document.querySelector('#roadworthinessfront').value='';

            }
        );


    }
}







function roadworthinessBackNew(key) {



    const filechkss = document.querySelector("#roadworthinessback").files[0];
    if (filechkss == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#roadworthinessback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            roadworthinessback: url,
                            roadworthinessBack : url,
                        });

                });
                document.querySelector('#roadworthinessback').value='';

            }
        );


    }
}




function dvlaprivatehirepermitfront1(key) {


    const filechk = document.querySelector("#dvlaprivatehirepermitfront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#dvlaprivatehirepermitfront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            dvlaprivatehirepermitfront: url,

                        });

                });

            }
        );

    }

}





//       dvlaprivatehirepermitback2(key);

function dvlaprivatehirepermitback2(key) {

    const filechks = document.querySelector("#dvlaprivatehirepermitback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#dvlaprivatehirepermitback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        .child('akwaaba/vehicleInfo/' + key)
                        .update({
                            dvlaprivatehirepermitback: url,


                        });

                });

            }
        );

    }
}






function drivinglincencefront1_display(key) {


    const filechk = document.querySelector("#drivinglincencefront").files[0];
    if (filechk == null) {

        //console.log("now file" + key);
    } else {


        const file = document.querySelector("#drivinglincencefront").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        // .child('akwaaba/vehicleInfo/'  + key)
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            drivinglincencefront: url,

                        });

                });

            }
        );

    }

}




function drivinglincenceback2display(key) {

    const filechks = document.querySelector("#drivinglincenceback").files[0];
    if (filechks == null) {

        // //console.log("now file" + key);


    } else {

        const file = document.querySelector("#drivinglincenceback").files[0];
        const metadata = {
            contentType: file.type,
        };
        var Uploadtask;

        var uniq = 'id' + (new Date()).getTime();
        //uploading files
        Uploadtask = firebase.storage().ref('akwaaba/drivers/' + uniq).put(file, metadata);

        Uploadtask.on("state_changed", function (snapshot) {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            document.getElementById("progress").value = percentage;

        },
            function error() {
                ////alert("error uploading file");
            },

            function () {
                Uploadtask.snapshot.ref.getDownloadURL().then(function (url) {

                    firebase
                        .database()
                        .ref()
                        // .child("/akwaaba/drivers/" + key)
                        //'akwaaba/vehicleInfo/'  + key
                        // .child('akwaaba/vehicleInfo/'  + key)
                        .child("/akwaaba/drivers/" + key)
                        .update({
                            drivinglincenceback: url,


                        });

                });

            }
        );

    }
}





















function clearandresetform() {

    document.getElementById("formpagereset").reset();


    document.getElementById("driver_password").value = "";
    document.getElementById("driver_last_name").value = "";
    document.getElementById("driver_first_name").value = "";

    document.getElementById("driver_email").value = "";
    document.getElementById("driver_phone").value = "";
    document.getElementById("driveraddress").value = "";
    document.getElementById("emergencyContact").value = "";

    // document.getElementById("driver_first_name").value = "";
    // document.getElementById("driver_last_name").value = "";

       document.getElementById("vehiclemodel").value = "";
        document.getElementById("licensePlate").value = "";
       document.getElementById("licensePlateNumber").value = "";
       document.getElementById("licensePlateNumberexpiringdate").value = "";
       document.getElementById("roadworthinessexpiringdate").value = "";
       document.getElementById("insuranceRenewaldate").value = "";
       document.getElementById("vehicleaddress").value = "";
       document.getElementById("SeatCapacity").value = "";



    document.getElementById('previewImg').setAttribute('src', '');
    
    document.getElementById('VehiclepreviewImg').setAttribute('src', '');
 

    document.getElementById("carManufacturer").selectedIndex = 0;
    document.getElementById("carColor").selectedIndex = 0;
    document.getElementById("carYear").selectedIndex = 0;
    document.getElementById("carType").selectedIndex = 0;
    //document.getElementById("carYear").selectedIndex = 0;
    
  
    //    document.getElementById("driver_first_name").value = "";
    //    document.getElementById("driver_last_name").value = "";
    //    document.getElementById("driver_first_name").value = "";
    //    document.getElementById("driver_last_name").value = "";

    // const inputsimg = document.querySelectorAll('img');
    // const input = document.querySelectorAll('input');
    // const inputselect = document.querySelectorAll('select');
    // setTimeout(function() { 
    //     document.getElementById("formpagereset").reset();
    //   //  document.getElementById("inputsimg").src="";
    //     // document.getElementById("driver_first_name").value="";
    //     // document.getElementById("driver_last_name").value="";
    //       document.getElementById("hidediv").style.display = 'none';
    //       }, 1000);

    document.getElementById('Nodriverwithnocardivbox').style.display = "block";

    document.getElementById('driverwithnocardivbox').style.display = "none";
  //  document.getElementById('Submits').disabled = true ;


  /// document.querySelector('#profile_pics').value='';
//   document.querySelector('#previewImg').value='';
//   document.querySelector('#VehiclepreviewImg').value='';
//document.querySelector('#profile_pics').files ='';

}