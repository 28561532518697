
import React, { useState, useEffect } from 'react';
//import './driverstyle.css';
//import './driverstyle.css';
import { RequestFormdbs, RequestFormdbswithcar, } from "./RequestFormdb";

import Swal from 'sweetalert2';
import dlogo from '../ab.png';
import dscar from '../ds.png';
// import './Tablestyle.css';

import { initialize, initializeAutocomplete, initializeCustomers, initializeDrivers, getthemaps } from './booking_maps.js';


import { initiatlizemaps, initiatlizemapsChange } from './Getroutingmap';

import { MakeRequest } from './SendRequestdb';


//import { Bookingsviewaforllnew } from './Bookingsviewallnew';


import $ from "jquery";





const RequestForm = () => {

    
    useEffect(() => {
      //  window.setTimeout(function () { Bookingsviewaforllnew(); }, 1000);
    }, [0])



    useEffect(() => {
        window.setTimeout(function () { initiatlizemaps(); }, 1000);
    }, [0])

    useEffect(() => {
        window.setTimeout(function () { initiatlizemapsChange(); }, 1000);
    })

    useEffect(() => {
        window.setTimeout(function () { initializeAutocomplete(); }, 1000);
    })

    useEffect(() => {
        window.setTimeout(function () { initializeCustomers(); }, 1000);
        window.setTimeout(function () { initializeDrivers(); }, 1000);

    })

    useEffect(() => {
        RequestFormdbs();
        //   $("select").select2();
    }, [0])

    const handleSubmitmap = async (e) => {
        e.preventDefault();
        //   getthemaps();
        initiatlizemaps();
    }


    const Getroutemap = async (e) => {
        e.preventDefault();
        //   getthemaps();
        initiatlizemapsChange();
    }

    //
    // useEffect(() => {
    //     window.setTimeout(function () {   initiatlizemap(); }, 1000) ;
    //      },[0])


    const SendRequestqw = async (e) => {
        e.preventDefault();
        //   getthemaps();
       // initiatlizemapsChange();
     //  
    MakeRequest();
    }

    return (
        <>



            <div class="hidediv" id="hidediv"><div class="loading"></div></div>
            <div id="loaderid" > <span class="loaderspage"></span>  </div>


            <div class="container-fluid">
                {/* 
                <div class="row">
                    <div class="col-md-12">
                        <div class="row" id="proBanner">
                            <div class="col-md-12 grid-margin">
                                <div class="card bg-gradient-success border-0" id="mapsh"  >
                                    <div class="card-body py-3 px-4 d-flex align-items-center justify-content-between flex-wrap">
                                        <p class="mb-0 text-white font-weight-medium">

                                            <div class="col-md-4">
                                                <label>  <div style={{ fontSize: "30px; " }} id="dvDistance">  </div></label>

                                                <label> <div id="dvDistance2">  </div></label>

                                            </div>

                                            <div class="col-md-6">
                                                
                                            </div>



                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div class="alert alert-primary" role="alert">

                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <label>  <div style={{ fontSize: "30px; " }} id="dvDistance">  </div></label>

                    <label> <div id="dvDistance2" >&nbsp; &nbsp;  </div></label>
 
                    <label> <div id="estimateamount" >  </div></label>

                    <input type="text" style={{  display: "none" ,borderColor: "#60D16D", backgroundColor: "#60D16D", fontSize: "30px", width: "100%" }}
                        class="form-control" id="myInput" disabled />


                </div>
 



                <div class="row ">

                    <div class="col-md-6">

                        <div class="row">


                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">


                                        <form class="row   m-20 needs-validation was-validated"  >
                                            <div class="col-md-6 ">
                                                <label class="form-label">Pickup Location</label>
                                                <input type="text"
                                                    class="form-control shadow-sm" id="txtSource"
                                                    autocomplete="on" onchange={Getroutemap}
                                                    placeholder="Pickup Location." required />
                                                <br />
                                            </div>
                                            <div class="col-md-6 position-relative">
                                                <label for="validationTooltip02" class="form-label">Dropoff Location</label>
                                                <input type="text" class="form-control shadow-sm"
                                                    id="txtDestination" onchange={Getroutemap}
                                                    autocomplete="on" placeholder=" Destination" required />
                                                <br />
                                            </div>

                                            <div class="col-md-6 position-relative">
                                                <label for="validationServer04" class="form-label">Customer</label>
                                                <select class="form-select is-invalid"
                                                    aria-describedby="validationServer04Feedback"
                                                    id="customers_lists"
                                                    onchange={Getroutemap}
                                                    //  onchange="customer_mainte_event()"
                                                    required>
                                                    <option value="" selected disabled hidden>
                                                        <div class="spinner-border text-info"> Select Customer</div>
                                                    </option>

                                                </select>
                                                <br />
                                            </div>

                                            <div class="col-md-6 position-relative">
                                                <label for="validationServer04" class="form-label">Driver</label>
                                                <select class="form-select is-invalid"
                                                    //  aria-describedby="validationServer04Feedback"
                                                 //   onchange={Getroutemap}
                                                    id="driver_list" disabled="disabled" >
                                                    <option value="none" selected disabled hidden>

                                                        <div class="spinner-border text-info"> Select Driver</div>
                                                    </option>

                                                </select>
                                                <br />
                                            </div>


                                            <div class="col-md-12 position-relative">
                                                <div style={{ color: "white" }}>.

                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Dispatch Mode</label>
                                                    <div class="col-md-12">


                                                        <label for="chkYes" class="dismode">
                                                            &nbsp;&nbsp; &nbsp;&nbsp;
                                                            <input type="radio" value="auto" id="dispatchmodechkYes"
                                                                name="dispatchmodechkcar" class="form-check-input"
                                                                checked />
                                                            <span>Auto</span>   &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;

                                                        </label>

                                                        <label for="chkNo" class="dismode">
                                                            <input type="radio" value="manual"
                                                                id="dispatchmodechkNo"
                                                                name="dispatchmodechkcar"
                                                                class="form-check-input"
                                                            />
                                                            <span>Manual</span>
                                                        </label>



                                                    </div>

                                                </div>
                                                <br />
                                            </div>


                                            <div class="col-md-6 position-relative">
                                                <label for="validationTooltip03" class="form-label">Vehicle Type / Seat Capacity</label>
                                                <select class="form-select is-invalid"
                                                    aria-describedby="validationServer04Feedback" onchange={Getroutemap}
                                                    required aria-label="select Vehicle Type" id="carType">
                                                    <option value="none" selected disabled hidden>
                                                        Select vehicle Type
                                                    </option>
                                                    <option>Select vehicle Type</option>
                                                    <option value="Regular">Regular</option>
                                                    {/* <option value="Estate">Estate</option> */}
                                                    <option value="Minibus">Minibus</option>
                                                    <option value="Comfort">Executive</option>
                                                </select>
                                                <br />
                                            </div>


                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="  col-form-label">Payment Type</label>
                                                    <div class="col-md-12">



                                                        <label for="chkNo">
                                                            &nbsp;&nbsp; &nbsp;&nbsp;
                                                            <input type="radio" value="cash" id="paymenttypechkcarss" name="paymenttypechkcars" class="form-check-input" checked />
                                                            Cash   &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                                        </label>


                                                        <label for="chkYes">
                                                            <input type="radio" value="card" id="paymenttypechkcarsss" name="paymenttypechkcars" class="form-check-input" />
                                                            Card
                                                        </label>


                                                    </div>

                                                </div>
                                                <br />
                                            </div>


                                            <div class="col-md-6">
                                                <label for="validationServer04" class="form-label">Scheduled trip</label>
                                                <select class="form-select is-invalid" aria-describedby="validationServer04Feedback"
                                                    id="scheduledTrip" onchange="ShowHideDiv()" >

                                                    <option value="none" selected disabled hidden>

                                                        <div class="spinner-border text-info"> Select Vehicle</div>
                                                    </option>


                                                    <option value="false">No </option>
                                                    <option value="true"> Yes </option>


                                                </select>
                                            </div>






                                            <div class="col-md-12">


                                                <div id="dvPassport" style={{ display: "none" }}>
                                                    <div class="row" >


                                                        <div class="col-md-6" >
                                                            <div class="form-group row">
                                                                <label class="  col-form-label">Date</label>
                                                                <div class="col-sm-10">


                                                                    <input type="date" class="form-control shadow-sm" id="scheduledTripDate" />

                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div class="col-md-6">
                                                            <div class="form-group row">
                                                                <label class="  col-form-label">Time</label>
                                                                <div class="col-sm-10">

                                                                    <input type="Time" class="form-control shadow-sm" id="scheduledTripTime" />

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            {/* <hr /> */}


                                            <div class="row" id="myhide">


                                                <div class="col-md-12" >
                                                    <div class="form-group row">
                                                        <label class="  col-form-label">Message to the Driver</label>
                                                        <textarea id="DriverMessage"
                                                            placeholder="Message to the Driver" class="form-control shadow-sm" rows="3" cols="50">
                                                            Current Booking
                                                        </textarea>


                                                    </div>
                                                </div>

                                            </div>


                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="form-group row">
                                                         
                                                            <button type="button"
                                                              onClick={handleSubmitmap}
                                                              id="convert"
                                                              class="btn btn-primary shadow button"
                                                              value="Get Map Details">Get Map Details
                                                            </button>

                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <br />
                                                    <div id="abbc"  ></div>
                                                    <br />
                                                    <input id="abbccc" type="hidden" />
                                                    <br />
                                                    <div id="abbcc"  ></div>
                                                    <br />

                                                    <div id="stepInfo"    ></div>

                                                </div>

                                                <div class="col-md-5">
                                                    <div class="form-group row">



                                        <button onClick={SendRequestqw} type="button" id="booknow" class="btn btn-success shadow button"
                                                            value="Submit"  >Submit</button>


                                                    </div>
                                                </div>
                                            </div>




                                        </form>

                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>

                    <div class="col-md-6">

                        <div class="card" style={{ minHeight: "517px" }}>
                            <div class="card-header card-header-text"></div>
                            <div class="card-content table-responsive" >
                                <div id="booking_mapview" style={{ width: "100%", height: "514px" }}></div>

                                <div id="icon" style={{ display: "none" }}></div>
                                {/* <div id="dvPanel" style={{ width: "80px", height: "90px" }}>   </div> */}
                            </div>
                        </div>
                    </div>


                </div>

               {/* Bookings Tabs */}
                <div class="row hideall">

                <div class="container-fluid">
  <div class="row">
 
  
  <div class="card">
    <div class="card-body">
 
            {/* <!-- <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body dashboard-tabs p-0"> --> */}
                  <ul class="nav nav-tabs px-4  " role="tablist" id="horizontal-style">
                    <li class="nav-item">
                      <a class="nav-link active" id="overview-tab" data-toggle="tab" 
                      href="#overview" role="tab" aria-controls="overview" aria-selected="true">Overview</a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" id="dispatch-tab" data-toggle="tab" href="#dispatch"
             role="tab" aria-controls="dispatch" aria-selected="false">Dispatch (<b class="mr-2 mb-0"> 
                <span id="overviewbookedspob"></span></b>) </a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" id="prebooked-tab" data-toggle="tab" href="#prebooked"
                       role="tab" aria-controls="prebooked" aria-selected="false">Pre-booked 
                       (<b class="mr-2 mb-0"> 
                <span id="overviewbookedsprebooked"></span></b>)
                      
                      </a>
                    </li>


                    <li class="nav-item">
                      <a class="nav-link" id="completed-tab" data-toggle="tab" href="#completed"
                       role="tab" aria-controls="completed" aria-selected="false">Completed
                       (<b class="mr-2 mb-0"> <span id="overviewbookedscompleted"></span></b>)
                      </a>
                    </li>


 

                    <li class="nav-item">
                      <a class="nav-link" id="pendings-tab" data-toggle="tab" href="#pendings"
                       role="tab" aria-controls="pendings" aria-selected="false">Pending
                       
                       (<b class="mr-2 mb-0"> <span id="overviewbookedspending"></span></b>)
                      
                      </a>
                    </li>


                    <li class="nav-item">
                      <a class="nav-link" id="overviewbookedscancelleds-tab" data-toggle="tab" href="#overviewbookedscancelleds"
                       role="tab" aria-controls="overviewbookedscancelleds" aria-selected="false">Cancelled
                       (<b class="mr-2 mb-0"> <span id="overviewbookedscancelled"></span></b>)
                      </a>
                    </li>


                    <li class="nav-item">
                      <a class="nav-link" id="onlinesookingstatusonlines-tab" data-toggle="tab" href="#onlinesookingstatusonlines"
                       role="tab" aria-controls="onlinesookingstatusonlines" aria-selected="false"> Manual 
                      
                       (<b class="mr-2 mb-0"> <span id="onlineBookingStatusonline_officebooking_only"></span></b>)

                      </a>
                    </li>



                    {/* <!-- <li class="nav-item">
                      <a class="nav-link" id="drivers-tab" data-toggle="tab" href="#drivers"
                       role="tab" aria-controls="drivers" aria-selected="false">No Show </a>
                    </li> --> */}


                    

                        
                  </ul>
                  <div class="tab-content py-0 px-0">

                  
{/* <!-- 
       First Tab start 
--> */}

                    <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                      <div class="table-responsive">
                   <div style={{overflowX:"auto"}}>

                      <a href="#">
                      <table id="userlist-tables" class="table table-striped table-bordered userlist-tables" style={{width:"100%"}}>
                               <thead>
                                <tr id="tr">
                                <th style={{ display: "none" }}>ID</th>
                                <th style={{ display: "none" }}>ID</th>
                               
                                <th>Driver(s)</th>
                                 <th>Customer(s)</th> 
                                
                                

                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>  Distance</th>
                                <th>  Duration</th>
                                <th> Est. Fare(£)</th>
                               
                                <th> Actual Fare(£)</th>
                                <th> Comments</th>
                                <th> Dispatch Datetime</th>
                                <th> Acceptance Datetime</th>
                                 <th> Arrival  Datetime</th>
                                 <th> PickUp /POB  Datetime</th>
                                 <th> Dropoff  Datetime</th>
                               </tr>  
                            </thead>
                            <tbody></tbody> 
                        </table> 
                 
                        </a>
                        </div>
                    </div>

                      </div>
                    </div>

                     
                     {/* second Tab start --> */}


                    <div class="tab-pane fade" id="dispatch" role="tabpanel" aria-labelledby="dispatch-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
 
                      <div class="table-responsive">
                   <div style={{overflowX:"auto"}}>

                      
                      <table id="userlist-tablesdispatch" 
                      class="table table-striped table-bordered
                       userlist-tablesdispatch"
                       style={{width:"100%"}}>
                               <thead>
                                <tr id="tr">
                                <th style={{ display: "none" }}>ID</th>
                                <th style={{ display: "none" }}>ID</th>
                               
                               <th>Drivers Name</th>
                                <th>Driver Phone </th>
                                 <th>Customer Name</th>
                                <th>Customer Phone</th> 
 
                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>  Distance</th>
                                <th>  Duration</th>
                                <th> Est. Fare(£)</th>
                                 <th> Trip Status</th>
                                {/* <!-- <th> Actual Fare(£)</th> */}
 
                                <th> Booking  Date</th>
                                 <th> Arrival  Date</th>
                                 <th> PickUp  Date</th>
                                 {/* <th> Dropoff  Date</th> -->
                                 
<!--                           <th  class="text-center">Action</th> --> */}

                               </tr>  
                            </thead>
                            <tbody></tbody> 
                        </table> 
                 
 
                        </div>
                    </div>

                    
                        
                      </div>
                    </div>
                    


{/*                     
<!--     Third Tab start --> */}
<div class="tab-pane fade" id="prebooked" role="tabpanel" aria-labelledby="prebooked-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                      <div class="table-responsive">
                   <div style={{overflowX:"auto"}}>
{/* 
                      <!-- <a href="main_vehicle_registered_link"> --> */}
                      <table id="userlist-tablesprebooked" class="table table-striped table-bordered userlist-tablesprebooked" style={{width:"100%"}}>
                               <thead>
                                <tr id="tr">
                                <th style={{ display: "none" }}>ID</th>
                                <th style={{ display: "none" }}>ID</th>
     
                               <th style={{ display: "none" }}>Drivers Name</th>
                                <th style={{ display: "none" }}>Driver Phone </th>
                                 <th>Customer Name</th>
                                <th>Customer Phone</th> 
                                 
                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>  Distance</th>
                                <th>  Duration</th>
                                <th> Est. Fare(£)</th>
       
                                <th> Booking  Date</th>
                                 <th> Scheduled  Date</th>
                                 <th> Scheduled Time</th>
                                 <th style={{ display: "none" }}>Status</th>
     
                                 </tr>  
                            </thead>
                            <tbody></tbody> 
                        </table> 
                      {/* <!-- </a> -->
  */}
                         
                      </div>
                    </div>      
                        </div>
                    </div>   



{/*                     
<!--       Fouth Tab start --> */}

                    <div class="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="completed-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                      <div class="table-responsive">
                   <div style={{overflowX:"auto"}}>

                 
<table id="userlist-tablescompleted" class="table table-striped table-bordered userlist-tablescompleted" style={{width:"100%"}}>
                               <thead>
                                <tr id="tr">
                                <th style={{ display: "none" }}>ID</th>
                                <th style={{ display: "none" }}>ID</th>
 
                               
                               <th>Drivers Name</th>
                                <th>Driver Phone </th>
                                 <th>Customer Name</th>
                                <th>Customer Phone</th> 
 
                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>  Distance</th>
                                <th>  Duration</th>
                                <th> Est. Fare(£)</th>
                                 <th> Trip Status</th>
                                <th> Actual Fare(£)</th>
 
                                <th> Booking  Date</th>
                                 <th> Arrival  Date</th>
                                 <th> PickUp  Date</th>
                                 <th> Dropoff  Date</th>
                                 
{/* <!--                                  
                                <th  class="text-center">Action</th> --> */}
                               </tr>  
                            </thead>
                            <tbody></tbody> 
                        </table> 
 
                         
                        </div>
                    </div>   

      
                    </div>
                    </div>

                    
{/* <!-- pending
                    fith Tab start --> */}
                    
                    <div class="tab-pane fade" id="pendings" role="tabpanel" aria-labelledby="pendings-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        
                      <div class="table-responsive">
                   <div style={{overflowX:"auto"}}>
 

<table id="userlist-tablespending" class="table table-striped table-bordered userlist-tablespending" style={{width:"100%"}}>
 
                               <thead>
                                <tr id="tr">
                                <th style={{ display: "none" }}>ID</th>
                                <th style={{ display: "none" }}>ID</th>
{/* <!--
                               
                               
                                 <th> Booking Status</th>


-->
                                */}
                               {/* <!-- <th>Drivers Name</th>
                                <th>Driver Phone </th> --> */}
                                 <th>Customer Name</th>
                                <th>Customer Phone</th> 
                                
                                

                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>  Distance</th>
                                <th>  Duration</th>
                                <th> Est. Fare(£)</th>
                                 <th> Trip Status</th>
                                {/* <!-- <th> Actual Fare(£)</th> */}
 
                                <th> Booking  Date</th>
                                 <th> Arrival  Date</th>
                                 <th> PickUp  Date</th>
                                 <th> Dropoff  Date</th>
                               
                                 
                                  {/* -->
<!--                                  
                                <th  class="text-center">Action</th> --> */}
                               </tr>  
                            </thead>
                            <tbody></tbody> 
                        </table> 
                
                     

                   </div>
                    </div>
                         
                        </div>
                    </div>   


 

                     
{/* <!-- 
     cancelleds
     sixth Tab start
 --> */}
                    
                    <div class="tab-pane fade" id="overviewbookedscancelleds"
                     role="tabpanel" aria-labelledby="overviewbookedscancelleds-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        
                      <div class="table-responsive">
                   <div style={{overflowX:"auto"}}>

                   <table id="userlist-tablescancelled"
 class="table table-striped table-bordered userlist-tablescancelled" 
 style={{width:"100%"}}>
                               <thead>
                                <tr id="tr">
                                <th style={{ display: "none" }}>ID</th>
                                
                                <th style={{ display: "none" }}>ID</th>
{/* <!--
                                <th>Customer Name</th>
                                <th>Customer Phone</th> 
                                <th>Drivers Name</th>
                                <th>Driver Phone </th>
                                 <th> Booking Status</th>
--> */}
                         
                               <th>Drivers Name</th>
                                <th>Driver Phone </th>
                                <th>Customer Name</th>
                                <th>Customer Phone</th> 
                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>Cancelled By</th>
                                <th> Cancel Reason</th>
                                <th>   Date</th>
{/* <!--  
                                <th  class="text-center">Action</th> --> */}
                               </tr>  
                            </thead>
                            <tbody></tbody> 
                        </table> 
                     
                     

                   </div>
                    </div>
                         
                        </div>
                    </div>   


 

                                        
{/* <!-- 
  Manual
   Seventh 
   Tab start --> */}
                    
                    <div class="tab-pane fade" id="onlinesookingstatusonlines"
                     role="tabpanel" aria-labelledby="onlinesookingstatusonlines-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        
                      <div class="table-responsive">
                   <div style={{overflowX:"auto"}}>

                  
                   <table id="userlist-tablesmanual" class="table table-striped table-bordered userlist-tablesmanual"
                    style={{width:"100%"}}>
                               <thead>
                                <tr id="tr">
                                <th style={{ display: "none" }}>ID</th>
                                <th style={{ display: "none" }}>ID</th>
 
                               <th>Drivers Name</th>
                                <th>Driver Phone </th>
                                 <th>Customer Name</th>
                                <th>Customer Phone</th> 
                            
                                <th>Starting Point(From)</th>
                                <th>Destination(To)</th>
                                <th>  Distance</th>
                                <th>  Duration</th>
                                <th> Est. Fare(£)</th>
                                 <th> Trip Status</th>
                                <th> Actual Fare(£)</th>
 
                                <th> Booking  Date</th>
                                 <th> Arrival  Date</th>
                                 <th> PickUp  Date</th>
                                 <th> Dropoff  Date</th>

                                 <th style={{ display: "none" }}> Status</th>
                                 
{/* <!--                                  
                                <th  class="text-center">Action</th> --> */}
                               </tr>  
                            </thead>
                            <tbody></tbody> 
                        </table> 
                     
                     

                   </div>
                    </div>
                         
                        </div>
                    </div>   


 
{/*                                       
<!-- 
                    8th Tab start --> */}
                    
                    <div class="tab-pane fade" id="pendings2" role="tabpanel" aria-labelledby="pendings2-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                        
                      <div class="table-responsive">
                       <div style={{overflowX:"auto"}}>

                 
                     
                     

                      </div>
                    </div>
                         
                        </div>
                    </div>   


 


 
                    </div>
                  </div>
                </div>
                </div>
              </div>
        
        
                </div>




            



                {/* Customer */}
                <input class="form-control" type="hidden" id="fname" />
                <input class="form-control" type="hidden" id="lname2" />
                <input class="form-control" type="hidden" id="email" />
                <input class="form-control" type="hidden" id="phone" />
                <input class="form-control" type="hidden" id="userID" />
                <input class="form-control" type="hidden" id="profilePhoto" />

                {/* Driver */}
                <input class="form-control" style={{ display: "none" }} type="text" id="driver_list_id" />
                <input class="form-control" style={{ display: "none" }} type="text" id="manualtoken" />
                <input class="form-control" style={{ display: "none" }} type="text" id="profilePhotoDriver" />
                <input class="form-control" style={{ display: "none" }} type="text" id="phone4" />



                {/*   coordinates */}
                <input class="form-control" style={{ display: "none" }} type="text" id="lat2" />
                <input class="form-control" style={{ display: "none" }} type="text" id="lat" />
                <input class="form-control" style={{ display: "none" }} type="text" id="long" />
                <input class="form-control" style={{ display: "none" }} type="text" id="long2" />


               {/* calculations */}
               <input type="text" id="dvDistances" style={{ display: "none" }} disabled />
                <input type="text" id="dvdurations" style={{ display: "none" }} disabled />

                <input class="form-control" style={{ display: "none" }} type="text" id="accountdistancing" />
                <input class="form-control" style={{ display: "none" }} type="number" value="1.5" id="defaultdistance'" />
                <input class="form-control" style={{ display: "none" }} type="text" id="accountdistance" />
                <input class="form-control" style={{ display: "none" }} type="text" id="defaultamount" />
                <input class="form-control" style={{ display: "none" }} type="text" id="amountafters" />
                <input class="form-control" style={{ display: "none" }} type="text" id="AccountSetup_id_old" />





{/* 
online booking */}

<input class="form-control" type="hidden" id="BookingStatusonline"  value="Yes"/>

                <input type="text" id="usertoken" style={{ display: "none", backgroundColor: "cornflowerblue" }} />
                <input type="text" id="usertoken2" style={{ display: "none", backgroundColor: "cornflowerblue" }} />
                <input style={{ display: "none" }} value="VMC Booking" type="text" class="form-control" id="title" />
                <input style={{ display: "none" }} type="text" value="New Job Coming " class="form-control" id="body" />
                <input class="form-control" style={{ display: "none" }} value="0" type="text" id="arraynumberdisplay" />

                <input class="form-control" style={{ display: "none" }} type="text" id="arraybookedkey" />
                <input class="form-control" style={{ display: "none" }} type="text" id="arraybookedkey_arraytotal" />
                <input class="form-control" style={{ display: "none" }} type="text" id="manualtoken" />
                <input class="form-control" style={{ display: "none" }} value="false" type="text" id="isdelivery" />

                <input id="datecreated" style={{ display: "none" }} />
                <input id="datecreatedd" style={{ display: "none" }} />
                <input class="form-control" style={{ display: "none", backgroundColor: "#92a8d1" }}
                    type="text" id="vehicledetails" />
                <input id="autotoken" style={{ display: "none" }} />
                <input id="manualdriverIds" style={{ display: "none" }} />
                <input id="carColor" style={{ display: "none" }} />
                <input id="tripstatus" style={{ display: "none" }} />
                <input class="form-control" style={{ display: "none" }}
                    type="text" id="getnewdriverIdwithjoboldid" />
                <input class="form-control" style={{ display: "none" }}
                    type="text" id="getnewdriverIdwithjobauto" />
                <input class="form-control" value="10" style={{ display: "none" }}
                    type="text" id="checktheautotoken" />
                <input class="form-control" value="10" style={{ display: "none" }}
                    type="text" id="checkautotoken" />
                <input class="form-control" style={{ display: "none" }}
                    type="text" id="thearray" />
                <input class="form-control" style={{ display: "none" }}
                    type="text" id="olddriverkey" />
                <input class="form-control" value="none" style={{ display: "none" }}
                    type="text" id="getnewdriverfirstolddata" />
                <input class="form-control" value="0" style={{ display: "none" }}
                    type="text" id="sending" />
                <input class="form-control" value="0" style={{ display: "none" }}
                    type="text" id="oldnewkey1" />

                <input class="form-control" value="0" style={{ display: "none" }}
                    type="text" id="oldnewkey2" />
                <input class="form-control" value="10" style={{ display: "none" }}
                    type="text" id="oldnewkey3" />

                <input class="form-control" value="0" style={{ display: "none" }}
                    type="text" id="arraynumberdisplay2" />

                <input class="form-control" value="0" style={{ display: "none" }}
                    type="text" id="Bookingduration" />

                <input class="form-control" value="0" style={{ display: "none" }}
                    type="text" id="BookingDistance" />
                <input class="form-control" value="0" style={{ display: "none" }}
                    type="text" id="BookingCost" />

            </div>
        </>
    )
}

export default RequestForm