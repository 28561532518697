

 
import firebase from '../Auth/userauth';
// import bootstrap from 'bootstrap/dist/css/bootstrap.min.css';
// import $ from "jquery";
 import moment from 'moment';
const google = window.google;
 
// function initializestatistics() {
    const initializestatistics = async () => {

    //    document.getElementById("hidediv").style.display = 'inline';
        

firebase.database().ref("akwaaba/vehicleInfo/" ).on('value',(snap)=>{
    var totalRecord =  snap.numChildren(); 
    document.getElementById("onlineallvehicleInfo").innerHTML = totalRecord;
    document.getElementById("themainonlineallvehicleInfo").innerHTML = totalRecord;
    document.getElementById("hidediv").style.display = 'none';       
});

//onlinependingapproval
//firebase.database().ref("akwaaba/vehicleInfo/" ).on('value',(snap)=>{
//approved
firebase.database().ref("akwaaba/vehicleInfo/" ).orderByChild('status').equalTo('approved').on('value',(snap)=>{

//if(snap.val().status=="approved"){
  var totalRecord =  snap.numChildren(); 
  document.getElementById("onlineallvehicleInfoapproved").innerHTML = totalRecord;

//}
});


firebase.database().ref("akwaaba/vehicleInfo/" ).orderByChild('status').equalTo('pending').on('value',(snap)=>{

//if(snap.val().status=="approved"){
  var totalRecord =  snap.numChildren(); 
  document.getElementById("onlineallvehicleInfoapprovedpending").innerHTML = totalRecord;

//}
});






// Drivers

// All Drivers 
// All online vehicles
firebase.database().ref("akwaaba/drivers/").on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinealldrivers").innerHTML = totalRecord;
    document.getElementById("theonlineallcustomers").innerHTML = totalRecord;
});

//onlinependingapproval
firebase.database().ref("akwaaba/drivers/").orderByChild('status').equalTo('approved').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineactivevehiclesapprovred").innerHTML = totalRecord;
    
    document.getElementById("themainonlineactivevehiclesapprovred").innerHTML = totalRecord;
    document.getElementById("theonlineactivevehiclesapprovred").innerHTML = totalRecord;
});


//onlinealldriverspending
firebase.database().ref("akwaaba/drivers/").orderByChild('status').equalTo('pending').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinependingpending").innerHTML = totalRecord;
    document.getElementById("themainonlinependingpending").innerHTML = totalRecord;
});


//onlinesuspendeddrivers
firebase.database().ref("akwaaba/drivers/").orderByChild('status').equalTo('suspended').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinesuspendeddrivers").innerHTML = totalRecord;
   // document.getElementById("themainonlinesuspendeddrivers").innerHTML = totalRecord;
});






// Customers

// All Customers
// All online vehicles
firebase.database().ref("akwaaba/customers/").on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineallcustomers").innerHTML = totalRecord;
    document.getElementById("theonlineallcustomers").innerHTML = totalRecord;
});

//onlinependingapproval
firebase.database().ref("akwaaba/customers/").orderByChild('status').equalTo('pending').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinependingcustomers").innerHTML = totalRecord;
});



//onlinesuspendeddrivers
firebase.database().ref("akwaaba/customers/").orderByChild('status').equalTo('suspended').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinesuspendedcustomers").innerHTML = totalRecord;
});


///Active and Aproved Drivers


//overview
// Overview
firebase.database().ref("akwaaba/driversCurrentLocation/").on("value", (snapz) => {

    var onlinevehicle = snapz.numChildren();


    document.getElementById("onlinevehicleswithdrivers").innerHTML = onlinevehicle;

});


firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('ontrip').on('value', (snap) => {
    var totalRecords = snap.numChildren();
    //document.getElementById("onlineBookingStatusbookedspobontrip").innerHTML = totalRecord;
    document.getElementById("overviewbookedspob").innerHTML = totalRecords;
})


// pending
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('pending').on('value', (snap) => {
    var totalRecords = snap.numChildren();
    //document.getElementById("onlineBookingStatusbookedspobontrip").innerHTML = totalRecord;
    document.getElementById("overviewbookedspending").innerHTML = totalRecords;
})


// completed
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('completed').on('value', (snap) => {
    var totalRecords = snap.numChildren();
    //document.getElementById("onlineBookingStatusbookedspobontrip").innerHTML = totalRecord;
    document.getElementById("overviewbookedscompleted").innerHTML = totalRecords;
})


//Cancelled 
firebase.database().ref("akwaaba/bookings/").orderByChild('cancelledBy').equalTo('Driver').on('value', (snap) => {
    var totalRecords = snap.numChildren();
    //document.getElementById("onlineBookingStatusbookedspobontrip").innerHTML = totalRecord;
    document.getElementById("overviewbookedscancelled").innerHTML = totalRecords;
})








// All Vehicles 
// All online vehicles


//online Active

//online InActive Vehicles


//online Vehicles
firebase.database().ref("akwaaba/vehicleInfo/").orderByChild('status').equalTo('suspended').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("theonlinesuspendedvehicles").innerHTML = totalRecord;
});



// All Bookings
// All online Bookings
firebase.database().ref("akwaaba/bookings/").on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineallbooking_all").innerHTML = totalRecord;
});



// POB
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('ontrip').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineBookingStatusbookedspobontrip").innerHTML = totalRecord;
    document.getElementById("onlineallactivetrip_pob").innerHTML = totalRecord;
});




// Pending
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('pending').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineBookingStatuspending").innerHTML = totalRecord;
});


// All online Completed
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('completed').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineBookingStatuscomplete").innerHTML = totalRecord;
});




// cancelledBy Driver
firebase.database().ref("akwaaba/bookings/").orderByChild('cancelledBy').equalTo("Driver").on('value', (snap) => {


    var totalRecord = snap.numChildren();
    document.getElementById("onlinebookingstatus_cancelledsbydriver").innerHTML = totalRecord;
});





// cancelledBy customer
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo("cancelled").on('value', (snap) => {


    var totalRecord = snap.numChildren();
    document.getElementById("onlinebookingstatus_cancelledsbycustomer").innerHTML = totalRecord;
});














// All online Booking only
firebase.database().ref("akwaaba/bookings/").orderByChild('bookingStatusonline').equalTo('Yes').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineBookingStatusonline_officebooking_only").innerHTML = totalRecord;
});



// All online Booking only
//   firebase.database().ref("akwaaba/bookings/" ).orderByChild('bookingStatusonline').equalTo(NULL).on('value',(snap)=>{
/* 
      firebase.database().ref("akwaaba/bookings/" ).on('value',(snap)=>{
           //
           if(snap.val().bookingStatusonline !="Yes"){

        snap.forEach(function(data)
        {
            var  onlinevehicle = data.numChildren();
     
            document.getElementById("onlinebookingsby_customerapp").innerHTML =  onlinevehicle ; 
        // }

          })

        }
          });
          */
firebase.database().ref("akwaaba/bookings/").orderByChild('bookingStatusonline').equalTo(null).on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinebookingsby_customerapp").innerHTML = totalRecord;
});










// All Trips
// All online Trips

// POB
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('ontrip').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    //    document.getElementById("onlineBookingStatusbookedspobontrip").innerHTML = totalRecord;
    document.getElementById("onlineallactivetrip_pob").innerHTML = totalRecord;
});



// All online  
firebase.database().ref("akwaaba/bookings/").orderByChild('scheduledTrip').equalTo('true').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlineonlineScheduledTrips2").innerHTML = totalRecord;
});



// Pending
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('pending').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinetripspendingStatus1").innerHTML = totalRecord;
});


// All online Completed
firebase.database().ref("akwaaba/bookings/").orderByChild('status').equalTo('completed').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinetripscompleteStatus2").innerHTML = totalRecord;
});

/*
        // All online Cancelled
        firebase.database().ref("akwaaba/bookings/" ).orderByChild('BookingStatus').equalTo('cancelled').on('value',(snap)=>{
          var totalRecord =  snap.numChildren(); 
          document.getElementById("onlinetripsCancelled100").innerHTML = totalRecord;
        });

*/






/*
          
           // All online Booking only
           firebase.database().ref("akwaaba/bookings/" ).orderByChild('BookingStatusonline').equalTo('Yes').on('value',(snap)=>{
            var totalRecord =  snap.numChildren(); 
            document.getElementById("onlineBookingStatusonline100").innerHTML = totalRecord;
          });
          */









// All Delivery

firebase.database().ref("akwaaba/delivery/").on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinealldelivery1").innerHTML = totalRecord;
});




// Pending
firebase.database().ref("akwaaba/delivery/").orderByChild('deliveryStatus').equalTo('pending').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinedeliveryStatuspending1").innerHTML = totalRecord;
});


// All online Completed
firebase.database().ref("akwaaba/delivery/").orderByChild('status').equalTo('complete').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinedeliveryStatuscomplte").innerHTML = totalRecord;
});


// All online Cancelled
firebase.database().ref("akwaaba/delivery/").orderByChild('deliveryStatus').equalTo('cancelled').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinedeliveryStatus100").innerHTML = totalRecord;
});



// All online Booking only
firebase.database().ref("akwaaba/delivery/").orderByChild('deliveryStatusonline').equalTo('Yes').on('value', (snap) => {
    var totalRecord = snap.numChildren();
    document.getElementById("onlinemanualbookingdeliverybooked").innerHTML = totalRecord;
});






// int sum=0;
// Payment Account
var day_date = moment();
// A day
firebase.database().ref("akwaaba/bookings/").orderByChild('BookingDate')
    .startAt(day_date.format("YYYY-MM-DD")).on('value', (snap) => {
        // var totalRecord =  snap.numChildren(); 
        var totalRecord = 0;
        snap.forEach(function(childSnapshot) {
            totalRecord += (childSnapshot.val().tripFare);

        });
        document.getElementById("onlinCosttotals100day").innerHTML = totalRecord.toFixed(2);
    });

// A Week
var week_date = moment().date(-6);
firebase.database().ref("akwaaba/bookings/").orderByChild('BookingDate')
.startAt(week_date.format("YYYY-MM-DD"))
.endAt(day_date.format("YYYY-MM-DD")).on('value', (snap) => {
    var total = 0;
    snap.forEach(function(childSnapshot) {
        total += (childSnapshot.val().tripFare);

    });
    //  ////console.log('NUM '+ total);
    document.getElementById("onlinCosttotaldateweek").innerHTML = total.toFixed(2);

});


var month_date = moment().date(-30); // 2018-02-04 (last month)

// A Month
firebase.database().ref("akwaaba/bookings/").orderByChild('BookingDate').startAt(month_date.format("YYYY-MM-DD")).endAt(day_date.format("YYYY-MM-DD")).on('value', (snap) => {
    var total = 0;
    snap.forEach(function(childSnapshot) {
        total += (childSnapshot.val().tripFare);

    });
    //  ////console.log('NUM '+ total);
    document.getElementById("onlinCosttotaldatemonth").innerHTML = total.toFixed(2);

});


// A Year

var year_date = moment().date(-364); // 2018-02-04 (last Year)
firebase.database().ref("akwaaba/bookings/").orderByChild('BookingDate').startAt(year_date.format("YYYY-MM-DD")).endAt(day_date.format("YYYY-MM-DD")).on('value', (snap) => {
    var total = 0;
    snap.forEach(function(childSnapshot) {
        total += (childSnapshot.val().tripFare);

    });
    //  ////console.log('NUM '+ total);
    document.getElementById("onlinCosttotaldateyear").innerHTML = total.toFixed(2);

});


}




export { initializestatistics };







//;
///*
 

 


