import React, { useState, useEffect,Redirect } from "react";
// import { useForm } from "react-hook-form";
// import { Button, Card, CardBody, CardFooter, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';  

// import axios from "axios";
 

function Footer() {
    const [CurrentTime ,setCurrentTime]=useState("");

    useEffect(() => {
   //   console.log("Helooo");
    //    Get data and didplay in text box
        // if (sessionStorage.getItem('accessToken') == null || sessionStorage.getItem('accessToken') == "") {
        //  //   window.location.href = "/";
        // }
        setCurrentTime( new Date().getFullYear());


       }, [])
      


   
   return (
       
<div class="container-fluid">
 
			
						
					<footer class="footer">
                <div class="container-fluid">
				  <div class="row">
				  <div class="col-md-6">
                    <nav class="d-flex">
                        <ul class="m-0 p-0">
                            <li>
                                <a href="/driversregistrationview">
                                    Drivers
                                </a>
                            </li>
                            <li>
                                <a href="/customersregistrationview">
                                    Customers
                                </a>
                            </li>
                            <li>
                                <a href="/request">
                                    Boookings
                                </a>
                            </li>
                            <li>
                                <a href="/panic">
                                    SOS
                                </a>
                            </li>
                        </ul>
                    </nav>
                   
                </div>
				<div class="col-md-6">
				 <p class="copyright d-flex justify-content-end"> &copy; {CurrentTime} 
                        <a href="https://yiedze.com/"> &nbsp;Yiedze,  </a> &nbsp;Inc
                    </p>
				</div>
				  </div>
				    </div>
            </footer>

    

</div>

 
     
      
    );
   }
 

   export default Footer; 