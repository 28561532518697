import React, { useState, useEffect } from 'react';
//import './driverstyle.css';
//import './driverstyle.css';


import { CustomersRegFormdbs,CustomersRegFormdbswithcar } from "./CustomersRegFormdb";

import Swal from 'sweetalert2';
 


import dlogo from '../ab.png';
import dscar from '../ds.png';


const CustomersRegForm = () => {

    // const [showText, setShowText] = useState(false);
    // const [loading, setLoading] = useState(false);
    // const [email, setEmail] = useState("");
    //  const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = useState("");

    const [image, setImage] = useState(null);
    const [imagecar, setImagecar] = useState(null);
    const [PhoneNumber, setPhoneNumber] = useState("");

    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Address, setAddress] = useState("");
    const [Gender, setGender] = useState("");

    const [Password, setPPassword] = useState("");
    const [emergencyContact, setemergencyContact] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");




    // const [Licence, setLicenceRegNumber] = useState("");
    // const [LicencePlateNumberexpiringdate, setLicencePlateNumberexpiringdate] = useState("");
    // const [SeatCapacity, setSeatCapacity] = useState("");
    // const [vehicleaddress, setVehicleaddress] = useState("");
    // const [roadworthinessexpiringdate, setRoadworthinessexpiringdate] = useState("");
    // const [insuranceRenewaldate, setInsuranceRenewaldate] = useState("");

    // const [roadworthinessfront, setRoadworthinessfront] = useState(null);
    // const [insurancepolicyfront, setInsurancepolicyfront] = useState(null);
    // const [drivinglincencefront, setDrivinglincencefront] = useState(null);
    // const [proveidtypefront, setProveidtypefront] = useState(null);
    // const [imagecarfile, setImagecarfile] = useState(null);
    // const [imageperson, setImageperson] = useState(null);
    




    useEffect(() => {
        const date = new Date();

        // 
    document.getElementById('datecreated').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
            
     document.getElementById('datecreatedd').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });

              })




    const uploadImage =  (e) => {
        //  const files = e.target.files
        
        try {

            //   var file = document.querySelector('input[type=file]')['files'][0];

            if (e.target.files && e.target.files[0]) {
                setImage(URL.createObjectURL(e.target.files[0]));
            } else {
                setImage(dlogo);
            }

        } catch {
            setImage(dlogo);
        }


        }

     
    

    const uploadImagecar = async e => {
        //  const files = e.target.files
        try {
           //  setFile(e.target.files[0]);
            //   var file = document.querySelector('input[type=file]')['files'][0];
 
            if (e.target.files && e.target.files[0]) {
                setImagecar(URL.createObjectURL(e.target.files[0]));
            } else {
                setImagecar(dlogo);
            }

        } catch {
            setImagecar(dlogo);
        }


    }




    const handleSubmitcustomer = (e) => {
        e.preventDefault();

  
       
            if (!LastName || !FirstName  || !Address || !Address || !emergencyContact || !Password || !PhoneNumber) {
          //  alert("Please enter name / password")
         
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="#">Error “Please fill out all required fields.</a>'
          })
          //    setLoading(true);
          //  setShowText(true);
        }
        else {
          
          
          try {
            CustomersRegFormdbs();
         
     
          }
          catch (error) {
         //   setShowText(true);
          //  setLoading(false);
          }
    
    
        }
      };
    




 





   
 


    return (
        <>



                <div class="hidediv" id="hidediv">
                <div class="loading"></div>
                </div>
                   

                <div id="loaderid" >
                    <span class="loaderspage"></span>
                 </div>
                   


            <div class="content-wrapper">
                <div class="col-12 grid-margin">
                    <div class="card">
                        <div class="card-body">


                            <h4 class="card-title">Customers Registration</h4>

                            <p class="card-description">
                                Personal info
                            </p>

 





                            <form onSubmit={e => { e.preventDefault(); }}  id="formpagereset">



                                    <div class="panel-body">







                                        <div class="row">

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class=" col-form-label">Upload Image</label>

                                                    <img width="150" height="150" id="previewImg" src={image || dlogo}
                                                        class="img-responsive rounded-circle center-block d-block mx-auto"
                                                        onError={(e) => { e.target.onerror = null; e.target.src = dlogo }} />
                                                    <input type="file" name="profile_pics" id="profile_pics"
                                                         onChange={uploadImage} 
                                                         // value ={""}
                                                        required 
                                                        //accept="image/*"
                                                      //  value={imageperson}
                                             //   onChange={ (e) => {  setImageperson(e.target.value) } } 
                                                    />

                                                    {/* <input type="hidden" name="memberimg" id="memberimg" value={memberimg}
                                                onChange={(e) => { setMemberimg(e.target.value) }} /> */}
                                                </div>

                                            </div>

                                        </div>




                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">First Name</label>
                                                    <div class="col-sm-9">
                                                        {/* <input type="text" placeholder="First Name" class="form-control shadow-sm"
                                                            id="driver_first_name" required /> */}

                                                        <input class="form-control"  name="driver_first_name" placeholder="First Name" required
                                                            type="text" pattern="[A-Za-z0-9-. _]+" id="driver_first_name"
                                         title="letters and numbers only, no punctuation or special characters or Only letters (either case),
                               numbers, hyphens, underscores, and periods"
                                                            value={FirstName}
                                                            onChange={(e) => { setFirstName(e.target.value) }}
                                                        />



                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" placeholder="Last Name" class="form-control shadow-sm"
                                                            id="driver_last_name"
                                                            required="required"
                                                            title="letters and numbers only, no punctuation or special characters or Only letters (either case),
                                                            numbers, hyphens, underscores, and periods"

                                                            value={LastName}
                                                            onChange={(e) => { setLastName(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Email</label>
                                                    <div class="col-sm-9">
                                                        <input type="email"  required
                                                        placeholder="Email"  class="form-control shadow-md"
                                                            id="driver_email" name="driver_email"



                                                            value={EmailAddress}
                                                            onChange={(e) => { setEmailAddress(e.target.value) }}
                                                        />
                                                        <b id='result'></b>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Phone Number</label>
                                                    <div class="col-md-9">
                                                        <input type="number" pattern="-?[0-9]*(\.[0-9]+)?"
                                                            placeholder="Phone Number" class="form-control shadow-sm"
                                                            id="driver_phone"
                                                            minlength="10"   maxlength="25" 
                                                            title="Enter  at least 10 letters long"
                                                            required="required"
                                                            value={PhoneNumber}
                                                            onChange={(e) => { setPhoneNumber(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3  ">Address</label>
                                                    <div class="col-sm-9">

                                                      <input type="text" class="form-control"  id="driveraddress" 
                                                            placeholder="Address"
                                                            value={Address}
                                                            required="required"
                                                            onChange={(e) => { setAddress(e.target.value) }}
                                                        />
 
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">Password</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" class="form-control shadow-sm" id="driver_password"
                                                            placeholder="Password"
                                                            minlength="8"   maxlength="15" 
                                                             title="Enter  at least 8 letters long"
                                                            required="required"
                                                            value={Password}
                                                            onChange={(e) => { setPPassword(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="row">


                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label">  Emergency Contact</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" pattern="-?[0-9]*(\.[0-9]+)?"
                                                            class="form-control shadow-sm" id="emergencyContact"
                                                            placeholder="Emergency Contact"
                                                            minlength="10"   maxlength="25" 
                                                            title="Enter  at least 10 letters long"
                                                            required="required"
                                                            value={emergencyContact}
                                                            onChange={(e) => { setemergencyContact(e.target.value) }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>





                                        {/*   disabled={!FirstName || !LastName   || !PhoneNumber }  */}

 


                                    </div>
                                

                                         <input class="btn btn-success pull-right float-md-right"
                                            //  onClick={Insertmember}  
                                            disabled={
                                           !LastName || !FirstName 
                                           // || !Address 
                                           || 
                                           !Address || !emergencyContact 
                                           || !Password || !PhoneNumber  
                                          // !imagecarfile
                                            }
                                            onClick={handleSubmitcustomer} 
                                            id="Submits" type="submit" value={"Register Now!"}
                                        />

 


 


                         <input  id="datecreated"   type="hidden"  style={{display: "none"}}  /><br/>
                         <input  id="datecreatedd"  type="hidden"  style={{display: "none"}}  />
                         <input  id="licensePlate" value="100001"  type="hidden"  style={{display: "none"}}  />
                         
                            <progress value="0" max="100"   id="progress"     style={{display: "none"}} ></progress>

                            </form>




                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomersRegForm