 
import  React, { useState , useEffect } from 'react';
import MenuTop from '../MenuTop';
 
 
import MenuSideBar from "../MenuSideBar";
import Footer  from '../Footer';


 
import Pending_DriverDataViewAllForm from './Pending_DriverDataViewAllForm';

const Pending_DriverDataViewAll = () => {
  
    return (
      <>
    
  <div class="wrapper">
  
  
  {/* <div class="body-overlay"></div> */}
  
  {/*   <!-- Sidebar  --> */}
          <MenuSideBar/>
     { /* <!-- Sidebar  --> */}
      
  
           {/* <!-- Page Content  --> */}
            <div id="content">
      
           {/* Top Bar Manu */}
            <MenuTop />
           {/* Top Bar Manu End */}
        
        
        <div class="main-content">
  
   
         {/* Content Analysis  */}
                {/* <MainContentAnalysis /> */}
                <Pending_DriverDataViewAllForm />
              {/* Content Analysis End  */}
  
  
             
             {/* Content Main */}
               
                <div class="row">
                {/* <MainContent1 />  */}
                 {/* Live Map */}
                {/* <MainContent /> */}
  
  
  
                </div>
           {/* Content Main End  */}
            
                  
                      {/* Footer  */}
                       <Footer />
                       {/* Footer End */}
           
             
            </div>
                      {/* main content End */}
             
          </div>{/* content end */}
  
      </div>   {/* wrapper end */}
  
  
      
      </>
    )
}

export default Pending_DriverDataViewAll;