import React, { useState, useEffect } from 'react';

import dlogo from '../ab.png';
import firebase from "../DbConn";

import { AssignedDriverViews,AssignedDriverViewspdf } from './AssignedVehicleViewFormdb';

import './Tablestyle.css';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");



const  AssignedVehicleViewForm = () => {

  useEffect(() => {
    const date = new Date();

    // 👇️
document.getElementById('datecreated').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
        
 document.getElementById('datecreatedd').value =  date.toLocaleString('en-GB', {timeZone: 'Europe/London', });
     

})
  
  useEffect(() => {

    
  AssignedDriverViews()
 
  },[])

  useEffect(() => {
   // Loadpdfonly();
    window.setTimeout(function () {
    //  getcolorloadsd();
       ///  window.location.reload(1);
   //    Loadpdfonly();
   AssignedDriverViewspdf();
    }, 10000);

 
  })
 

  return (
    <>

<div class="hidediv" id="hidediv">
        <div class="loading"></div>
      </div>


           <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                <h4 class="card-title">List of Vehicles Assigned to  Drivers</h4>
                   <div class="table-responsive">
                   <div style={{overflowX:"auto;"}}>
 
                  
                   <table id="driveruserlist"
                    class="display "
                    style={{ width: "100%" }}>

                    <thead id="thead">
                      <tr id="tra">

                        <th style={{ display: "none" }} >ID</th>
                        <th style={{ display: "none" }} >ID</th>

                        <th title="list of All registered Members">Driver Details</th>
                        <th>PhoneNumber</th>

                        <th>Email</th>
 
                        <th>Emergency Contact</th>

                        <th>Driving Licence Expiring Date</th>
                         
                        <th>Vehicle info</th>
                      
                        <th>Status / Comments</th>

                         <th>Start Date</th>

                         <th>End Date</th>

                      </tr>
                    </thead>
               <tbody data-toggle="modal"  title="List of All registered drivers, Click the cell to view Drivers "></tbody>
                  </table>

                
                     </div>
                  </div>
                </div>
              </div>
            </div>

 
 
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content modal-dialog modal-sm">      
 
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Assigned Drivers info</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      
                         <div class="row">
                                     <div class="col-md-12">
                                     <img width="40" height="40"   
                                      src="images/xyz1.png"
                                        class="img-responsive rounded-circle center-block d-block mx-auto"
                                         id="myimg" alt="No Image avalable"
                                        
                                            />
                                     </div>
                                   
                                 
                                     <div class="col-md-12"  >
                                     <label>Name :</label>
                                         <label for="" id="driverlive_statussx">  <span id="mymodelfname"> </span></label>
                                       </div>

                                       <div class="col-md-12"  >
                                     <label>Email :</label>
                                         <label for="" id="driverlive_statussx">  <span id="mymodelemail"> </span></label>
                                         &nbsp;&nbsp;&nbsp;&nbsp;
                                         </div>

                                         <div class="col-md-12"  >
                                     <label>Phone Number :</label>
                                         <label for="" id="driverlive_statussx">  <span id="mymodelphone"> </span></label>
                                       </div>


                                       
                             <hr />

                         </div>
      

 




                               
                                   <div class="row">


                                   
                                   <div class="col-md-12"  >
                                     <label>vehicle Name :</label>
                                         <label for="" id="driverlive_statussx">  <span  id="carManufacturer" > </span></label>
                                       </div>

                                       <div class="col-md-12"  >
                                     <label>Vehicle Type :</label>
                                         <label for="" id="driverlive_statussx">  <span  id="cartypes" > </span></label>
                                         &nbsp;&nbsp;&nbsp;&nbsp;
                                         </div>

                                         <div class="col-md-12"  >
                                     <label> Manufacturing Year :</label>
                                         <label for="" id="driverlive_statussx">  <span id="caryears" > </span></label>
                                       </div>
                            

                                     <div class="col-md-12" style={{display: "none"}}  >
                                       <div class="form-group row">
                                       <div class="col-sm-12">
                                           <label>vehicle Name</label>
                                           <input type="text" class="form-control shadow-sm" id="carManufacturer" 
                                           placeholder="Vechicle Name "   />
                                           </div>
                                    
                                       </div>
                                     </div>
                                     
                                     <div class="col-md-12" style={{display: "none"}}  >
                                       <div class="form-group row">
                                       <div class="col-sm-12">
                                           <label>vehicle Number</label>
                                           <input type="text" class="form-control shadow-sm" id="licensePlate" 
                                           placeholder="No   Number"   />
                                           </div>
                                    
                                       </div>
                                     </div>
                                     <div class="col-md-12" style={{display: "none"}}  >
                                       <div class="form-group row">
                                       <div class="col-sm-12">
                                           <label>Vehicle Type</label>
                                           <input type="text" class="form-control shadow-sm" id="carType" 
                                           placeholder="Year "   />
                                           </div>
                                    
                                       </div>
                                     </div>
                                     <div class="col-md-12" style={{display: "none"}}  >
                                       <div class="form-group row">
                                       <div class="col-sm-12">
                                           <label> Vehicle Year</label>
                                           <input type="text" class="form-control shadow-sm" 
                                           placeholder="Car Type"   />
                                           </div>
                                    
                                       </div>
                                     </div>
                                     </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        {/* <button type="button" class="btn btn-info" style={{display: "none"}}    onclick="location.href='vehicleassigntodrivervieweditlinks' "  >Get details </button>
       */}
      </div>
    </div>
  </div>
</div>

                         <input  id="datecreated"   type="hidden"  style={{display: "none"}}  /><br/>
                         <input  id="datecreatedd"  type="hidden"  style={{display: "none"}}  />
 
    </>
  )
}

export default AssignedVehicleViewForm;